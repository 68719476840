import { Timestamp, addDoc, collection } from 'firebase/firestore/lite'
import React, { useEffect, useState } from 'react'

export default function ImportCSV({language, database}) {
    const [importType, setImportType] = useState('Talabat')
    const [data, setData] = useState(null)

    function parseCSV(csv_data) {
        let csv_arr = csv_data.split('\n')
        let temp_data = []
        let branch = window.localStorage.getItem("branch");

        if(!csv_data || csv_data == ''){
            return
        }

        csv_arr.map((col, index) => {
            if(index !== 0 && col !== '' && col !== null){
                try {
                    let my_split = col.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
                    let items = my_split[23].replaceAll('"', '').split(',')
                    temp_data[index] = {
                        orderId: my_split[1],
                        payment: my_split[5] === 'online'?'online':'cash',
                        delivered: my_split[6] === 'Delivered'?true:false,
                        total: parseFloat(my_split[18]),
                        branch: branch,
                        canceled: my_split[15] === ''?false:true,
                        type: 'Talabat',
                        items: [],
                        timestamp: Timestamp.fromDate(new Date(my_split[7]))
                    }
                    items.map(sitem => {
                        let item_data = sitem.trimStart().split(' ')
                        let qty = item_data[0]
                        item_data.shift()
                        let item_name = item_data.join(' ')
                        temp_data[index].items.push({
                            title: item_name,
                            title_ar: item_name,
                            qty: parseInt(qty),
                            amount: 0
                        })
                    })
                } catch (error) {
                    //console.log(error);
                }
            }
        })
    
        setData(temp_data)
    }

    const handleImport = e => {
        if(!e.target.files[0]){
            return
        }

        let reader = new FileReader();

        reader.onload = function(e) {
            let contents = e.target.result;
            parseCSV(contents)
          };

        reader.readAsText(e.target.files[0]);
    }

    const handleSubmit = async () => {
        if(!data){
            return
        }

        data.map(async (item, index) => {
            await addDoc(collection(database, "orders"), item)
            .then((res) => {
                if(index === (data.length - 1)){
                    window.location.href = "/orders/list";
                }
            })
            .catch((err) => {
                //console.log(err)
            });
        })
    }

    return (<div className="row">
    <div className="col-12 stretch-card">
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{language["import-csv"]}</h4>
                <hr />
                <div className="input-group m-3">
                    <select name="importType" className="form-control" onChange={val => setImportType(val.target.value)}>
                        <option key={'talabat'} value="Talabat">Talabat</option>
                        {/* <option key={'system'} value="system">System Data</option> */}
                    </select>
                </div>
                <div className="input-group">
                    <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleImport} className="form-control" placeholder="Upload Picture" />
                </div>
                

                <hr />
                {data && <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>{language["orderid"]}</th>
                        <th>{language["date"]}</th>
                        <th>{language["total"]}</th>
                        <th>{language["items"]}</th>
                        <th>{language["status"]}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data && data.map((item) => (
                        <tr key={item.orderId}>
                            <td className="col">{item.orderId}</td>
                            <td className="col-4">
                            {item.timestamp.toDate().toLocaleDateString() + " - " + item.timestamp.toDate().toLocaleTimeString()}
                            </td>
                            <td className="col">{item.total}</td>
                            <td className="col">{item.items.length}</td>
                            <td className="col">
                            {!item.canceled && 
                                <label
                                className={
                                    item.delivered
                                    ? "badge badge-success"
                                    : "badge badge-danger"
                                }
                                >
                                {item.delivered ? "Delivered" : "Undelivered"}
                                </label>
                            }
                            {item.canceled === true && (
                                <label className="badge badge-danger">
                                {language["cancel"]}
                                </label>
                            )}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>}
                {data && <button className="btn m-3 btn-success" onClick={handleSubmit}>{language["add"]}</button>}

            </div>
        </div>
    </div>
</div>)
}
