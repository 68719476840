import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";

const Login = ({ auth, setUser, language }) => {
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    setMsg("");
    if (email !== "" && password !== "") {
      signInWithEmailAndPassword(auth, email, password).then(
        (userCredential) => {
          // Signed in
          const user = userCredential.user;
          //navigate('/'+next)
          //console.log(userCredential.user)
        }
      );
      onAuthStateChanged(auth, (userData) => {
        // Check for user status
        setUser(userData);
      }).catch(() => {
        setMsg("Wrong email or password, try again...");
      });
    } else {
      setMsg("Please fill all the required fields...");
    }
  };

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo text-center">
                  <img src="../../assets/images/logo-mini.png" />
                </div>
                <h4>
                  {language["app-title"]} {language["dashboard"]}
                </h4>
                <h6 className="font-weight-light">{language["login"]}</h6>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    id="InputEmail1"
                    placeholder={language["email"]}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    id="InputPassword1"
                    placeholder={language["password"]}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="text-danger p-2">{msg}</div>
                <div className="mt-3">
                  <button
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={handleLogin}
                  >
                    {language["login"]}
                  </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  {/* <a href="#" className="auth-link text-black">Forgot password?</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
