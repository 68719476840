import { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
} from "firebase/firestore/lite";
import { Link } from "react-router-dom";

export default function Merchants({
  database,
  setLoading,
  language,
  usersData,
}) {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);

  useEffect(() => {
    setLoading(true);
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "merchents"), orderBy('timestamp', 'desc')))
      .then((querySnapshot) => {
        setLoading(false);
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            name: doc.data().name,
            enable: doc.data().enable,
            mobile: doc.data().mobile,
            email: doc.data().email,
            area: doc.data().area,
            //lat: doc.data().lat,
            //lon: doc.data().lon,
            timestamp: doc.data().timestamp,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [update]);

  const handleDelete = (item) => {
    setLoading(true);
    let r = window.confirm("Are you sure?");
    if (r) {
      deleteDoc(doc(database, "merchents", item)).then(() => {
        setLoading(false);
        setUpdate(Date.now());
      });
    }
  };

  const handleAvailable = (item, val) => {
    updateDoc(doc(database, "merchents", item), {
      enable: !val,
    })
      .then((res) => {
        setUpdate(Date.now());
      })
      .catch((res) => console.log(res));
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["merchants"]}</h4>
          <Link
            to="/merchants/new"
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="mdi mdi-plus"></i> {language["new"]}
          </Link>
          <Link
            to="/merchants/summary"
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="mdi mdi-file"></i> {language["summary"]}
          </Link>

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                {/* <th>{language["full-name"]}</th>
                <th>{language["email"]}</th>
                <th>{language["mobile"]}</th>
                <th>{language["area"]}</th> */}
                <th>{language["date"]}</th>
                {/* <th>{language["available"]}</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">
                      <Link
                        className="btn"
                        to={"/merchants/" + item.id}
                      >
                        {item.title}
                      </Link>
                    </td>
                    <td className="col">
                      {item.timestamp.toDate().toLocaleDateString()}
                    </td>
                    <td className="col">
                      <button
                        className="btn btn-sm btn-success m-2"
                        onClick={() =>
                          window.open(`https://wa.me/968${item.mobile}`)
                        }
                      >
                        <i className="fa fab fa-whatsapp fs-5"></i>
                      </button>
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-sm btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
