import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  deleteDoc,
  startAfter,
} from "firebase/firestore/lite"
import { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"

const Orders = ({ database, setLoading, user, language, usersData }) => {
  const [data, setData] = useState(null)
  const [update, setUpdate] = useState(null)
  const [pages, setPAges] = useState(0)
  const [colors, setColor] = useState(null)
  const [shadowData, setShadowData] = useState(null)
  const [orderTypes, setOrderTypeList] = useState(null)
  const [csv, setCsv] = useState("");

  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'omr',
  })

  const { list } = useParams()
  let lastVisible = null

  useEffect(() => {
    let temp_obj = {}
    let temp_arr = []
    getDocs(query(collection(database, "orderTypes"), where("enable", "==", true), orderBy("sort", "asc")))
    .then(res => {
      res.forEach(doc => {
        temp_obj = {...temp_obj, ...{
          [doc.data().name]: doc.data().color
        }}

        temp_arr.push({
          id: doc.id,
          name: doc.data().name,
          color: doc.data().color,
          enable: doc.data().enable
        })
      })
      setColor(temp_obj)
      setOrderTypeList(temp_arr)
    })
    .catch(err => console.log(err))
  }, [])

  const getOrders = () => {
    let temp_arr = []
    let temp_csv = ''
    let q = null
    let branch = window.localStorage.getItem("branch")
    setLoading(true)

    if (list === "list") {
      //q = getDocs(query(collection(database, "orders"), where("branch", "==", branch),  orderBy("timestamp", "desc"), limit(10)))
      q = getDocs(
        query(
          collection(database, "orders"),
          where("branch", "==", branch),
          orderBy("timestamp", "desc"),
          limit(10)
        )
      )
    } else if(list === 'merchants'){
      q = getDocs(
        query(
          collection(database, "orders"),
          where("type", "==", 'Merchant'),
          where("branch", "==", branch),
          limit(10)
        )
      )
    } else {
      q = getDocs(
        query(
          collection(database, "orders"),
          where("canceled", "==", true),
          where("branch", "==", branch),
          limit(10)
        )
      )
    }
    q.then((querySnapshot) => {
      lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

      querySnapshot.forEach((doc) => {
        temp_arr.push({
          id: doc.id,
          timestamp: doc.data().timestamp,
          type: doc.data().type,
          delivered: doc.data().delivered,
          //total: doc.data().total,
          total:
            doc.data().type === "delivery"
              ? (
                  parseFloat(doc.data().total) +
                  parseFloat(doc.data().details.delivery.delivery_fees)
                ).toFixed(3)
              : doc.data().total,
          items: doc.data().items.length,
          orderId: doc.data().orderId,
          canceled: doc.data().canceled,
        })

        temp_csv +=
          doc.data().orderId +
          "," +
          doc.data().timestamp.toDate().toLocaleDateString() +
          "," +
          doc.data().type +
          "," +
          (doc.data().type === "delivery"
              ? (
                  parseFloat(doc.data().total) +
                  parseFloat(doc.data().details.delivery.delivery_fees)
                ).toFixed(3)
              : doc.data().total) +
          "," +
          doc.data().items.length +
          "\n";

      })
      //let sorted_arr = temp_arr.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      setCsv(temp_csv)
      setData(temp_arr)
      setShadowData(temp_arr)
      setLoading(false)
    }).catch((err) => setLoading(false))
  }

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"])
    if (r) {
      deleteDoc(doc(database, "orders", item)).then(() => {
        setUpdate(Date.now())
      })
    }
  }

  useEffect(() => {
    let temp_csv = ""
    getOrders()
    window.onscroll = function (ev) {
      var pageHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
      if (window.innerHeight + window.scrollY >= pageHeight) {
        let temp_arr = []
        let next = null
        let branch = window.localStorage.getItem("branch")
        if (list === "list" && lastVisible) {
          next = getDocs(
            query(
              collection(database, "orders"),
              where("branch", "==", branch),
              orderBy("timestamp", "desc"),
              startAfter(lastVisible),
              limit(10)
            )
          )
        } else if(list === 'merchants' && lastVisible) {
          next = getDocs(
            query(
              collection(database, "orders"),
              where("type", "==", 'Merchant'),
              where("branch", "==", branch),
              startAfter(lastVisible),
              limit(10)
            )
          )
        } else {
          if (lastVisible) {
            next = getDocs(
              query(
                collection(database, "orders"),
                where("canceled", "==", true),
                where("branch", "==", branch),
                startAfter(lastVisible),
                limit(10)
              )
            )
          }
        }

        if (lastVisible) {
          setLoading(true)
          next
            .then((querySnapshot) => {
              lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
              if (querySnapshot.docs) {
                setLoading(false)
                querySnapshot.forEach((doc) => {
                  //temp_arr.push({
                  setData((ol) => [
                    ...ol,
                    {
                      id: doc.id,
                      timestamp: doc.data().timestamp,
                      type: doc.data().type,
                      delivered: doc.data().delivered,
                      //total: doc.data().total,
                      total:
                        doc.data().type === "delivery"
                          ? (
                              parseFloat(doc.data().total) +
                              parseFloat(
                                doc.data().details.delivery.delivery_fees
                              )
                            ).toFixed(3)
                          : doc.data().total,
                      items: doc.data().items.length,
                      orderId: doc.data().orderId,
                      canceled: doc.data().canceled,
                    },
                  ])

                  setShadowData((ol) => [
                    ...ol,
                    {
                      id: doc.id,
                      timestamp: doc.data().timestamp,
                      type: doc.data().type,
                      delivered: doc.data().delivered,
                      //total: doc.data().total,
                      total:
                        doc.data().type === "delivery"
                          ? (
                              parseFloat(doc.data().total) +
                              parseFloat(
                                doc.data().details.delivery.delivery_fees
                              )
                            ).toFixed(3)
                          : doc.data().total,
                      items: doc.data().items.length,
                      orderId: doc.data().orderId,
                      canceled: doc.data().canceled,
                    },
                  ])

                  temp_csv +=
                  doc.data().orderId +
                  "," +
                  doc.data().timestamp.toDate().toLocaleDateString() +
                  "," +
                  doc.data().type +
                  "," +
                  (doc.data().type === "delivery"
                      ? (
                          parseFloat(doc.data().total) +
                          parseFloat(doc.data().details.delivery.delivery_fees)
                        ).toFixed(3)
                      : doc.data().total) +
                  "," +
                  doc.data().items.length +
                  "\n";
                })
                setCsv(temp_csv)
              }
            })
            .catch((err) => setLoading(false))
        }
      }
    }
  }, [update])

  useEffect(() => {
    if (data) {
      let mywindow = document.getElementById("ipd").contentWindow.document;

      mywindow.open();

      mywindow.write(
        '<html dir="' +
          language["dir"] +
          '"><head><title>' +
          language["products"] +
          '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 15px">'
      );

      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );

      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"><h1>' +
          language["orders-list"] +
          "</h1></div>"
      );

      mywindow.write(
        `<br/><table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>${language['date']}</td><td>Order Id</td><td>Items</td><td>${language["total"]}</td><td>${language["type"]}</td></tr>`
      );


      let total = 0
      let total_canceled = 0
      
      data.map((item) => {
        let temp_total = 0
        if(item.details){
          if(item.canceled){
            total_canceled += item.type === "delivery"
            ? (
                parseFloat(item.total) +
                parseFloat(item.details.delivery.delivery_fees)
              ).toFixed(3)
            : parseFloat(item.total)
          } else {
            total += item.type === "delivery"
            ? (
                parseFloat(item.total) +
                parseFloat(item.details.delivery.delivery_fees)
              ).toFixed(3)
            : parseFloat(item.total)
          }
          temp_total = item.type === "delivery"
            ? (
                parseFloat(item.total) +
                parseFloat(item.details.delivery.delivery_fees)
              ).toFixed(3)
            : item.total;
        } else {
          if(item.canceled){
            total_canceled = item.total;
          } else {
            temp_total = item.total;
          }
          total += parseFloat(item.total)
        }
        mywindow.write(
          `<tr><td>${item.timestamp.toDate().toLocaleDateString()}</td>`+
          `<td>${item.orderId}</td><td>${item.items}</td>`+
          //`<td>${item.type === 'delivery' && item.delivered === true ? "Delivered" : "Undelivered"}</td>`+
          `<td>${temp_total}</td>`+
          `<td>${item.type}</td></tr>`
        )
      });

      mywindow.write(`<tr>
                <th></th>
                <th></th>
                <th><b>${data && language["orders"] + ": " + data.length}</b></th>
                <th><b>${language["total"]}:</b> OMR ${total.toFixed(3)}</th>
                <th><b>${language['cancel']}:</b> ${language["total"]}: ${total_canceled}</th>
            </tr>`);
      mywindow.write("</table>");
      //mywindow.write(document.getElementById('pid').innerHTML);

      mywindow.close();
    }
  }, [data]);

  const handlePrint = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  const handleDownload = () => {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", language["orders-list"] + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

  }

  const handleSort = itemType => {
    let temp_arr = []
    if(itemType.length > 0){
      temp_arr = shadowData.filter(item => item.type === itemType)
      setData(temp_arr)
    } else {
      setData(shadowData)
    }
  }

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["orders"]}</h4>
          <Link
            to="/newrevenue"
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="mdi mdi-plus"></i> {language["new"]}
          </Link>

          {usersData && usersData.userType === "admin" && <Link
            to="/neworder"
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="mdi mdi-plus"></i> {language["new-online"]}
          </Link>}
          <button
            onClick={handlePrint}
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="fa fa-print"></i> {language["print"]}
          </button>
          {usersData && usersData.userType === "admin" && (<>
            <button
              onClick={handleDownload}
              type="button"
              className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
            >
              <i className="mdi mdi-file-excel"></i> {language["download"]}
            </button>
            <Link
              to='/orders/import'
              rol="button"
              className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
            >
              <i className="mdi mdi-file-upload"></i> {language["import"]}
            </Link>
          </>)}

          {orderTypes && orderTypes.length > 0 && (
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 d-inline-block">
              <select
                name="cancel-reason"
                id="cancel-reason"
                onChange={(val) => handleSort(val.target.value)}
                className="form-control col-12"
              >
                <option key={"25234gdfgd564d"} value="">{language['all']}</option>
                {orderTypes.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
                <option key={"25234gdfg64d"} value="delivery">{language['delivery']}</option>
                <option key={"25234gd564d"} value="pickup">{language['pickup']}</option>

              </select>
            </div>
          )}

          <p className="card-description"> {language["orders-list"]}</p>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["orderid"]}</th>
                <th>{language["date"]}</th>
                <th>{language["total"]}</th>
                <th>{language["type"]}</th>
                <th>{language["items"]}</th>
                <th>{language["status"]}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">{item.orderId}</td>
                    <td className="col-4">
                      {item.timestamp.toDate().toLocaleDateString() + " - " + item.timestamp.toDate().toLocaleTimeString()}
                    </td>
                    <td className="col">{formatter.format(item.total)}</td>
                    <td className="col p-3">
                    {colors && <div style={{color: "#ffffff", background: item.type === 'delivery' || item.type === 'pickup'?"#3cc904":colors[item.type], padding: 5, borderRadius: 15, textAlign: 'center'}}>
                      {item.type}
                        <i
                          className={
                            item.type === "pickup" || item.type === "shop"
                              ? "mdi mdi-arrow-down"
                              : "mdi mdi-arrow-up"
                          }
                        ></i>
                      </div>}
                    </td>
                    <td className="col">{item.items}</td>
                    <td className="col">
                      {!item.canceled && (item.type === 'delivery' || item.type === 'pickup') && (
                        <label
                          className={
                            item.delivered
                              ? "badge badge-success"
                              : "badge badge-danger"
                          }
                        >
                          {item.delivered ? "Delivered" : "Undelivered"}
                        </label>
                      )}
                      {item.canceled === true && (
                        <label className="badge badge-danger">
                          {language["cancel"]}
                        </label>
                      )}
                    </td>
                    <td className="col">
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-sm btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>{" "}
                          {language["delete"]}
                        </button>
                      )}
                    </td>
                    <td className="col">
                      <Link
                        className="btn btn-sm btn-success"
                        to={"/order/" + item.id}
                      >
                        <i className="mdi mdi-eye"></i> {language["view"]}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Orders
