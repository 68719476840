import {
  collection,
  query,
  getDocs,
  where,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
} from "firebase/firestore/lite";
import { useState, useEffect, forwardRef } from "react";

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
  closestCorners,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove,
  rectSwappingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Link } from "react-router-dom";

const Categories = ({ database, setLoading, user, language }) => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const getOrders = () => {
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "categories"), orderBy("sort")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            key: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"]);
    deleteDoc(doc(database, "categories", item)).then(() => {
      setUpdate(Date.now());
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragStart = (event) => {
    const { active } = event;

    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      let temp_arr = [];
      const oldIndex = data.indexOf(active.id);
      const newIndex = data.indexOf(over.id);

      temp_arr = arrayMove(data, oldIndex, newIndex);

      setData(temp_arr);

      let num = 0;

      temp_arr.forEach((doc) => {
        handleSort(doc.key, num);
        num++;
      });
    }

    setActiveId(null);
  };

  const handleSort = (dockey, index) => {
    console.log(dockey);

    updateDoc(doc(database, "categories", dockey), {
      sort: index,
    })
      .then((res) => console.log("Done update...", res))
      .catch((res) => console.log("Update error: ", res));
  };

  const Widget = forwardRef(({ id, ...props }, ref) => {
    const { title, title_ar, key } = id;
    return (
      <tr {...props} ref={ref} key={key}>
        <td className="col-6">
          {language["language"] === "arabic" ? title_ar : title}
        </td>
        <td className="col-6">
          <Link className="btn btn-success m-2" to={"/editcategory/" + key}>
            <i className="mdi mdi-pen"></i> {language["edit"]}
          </Link>
          <button
            className="btn btn-danger m-2"
            onClick={() => handleDelete(key)}
          >
            <i className="mdi mdi-delete"></i> {language["delete"]}
          </button>
        </td>
      </tr>
    );
  });

  const SortableWidget = (props) => {
    const { id, currencypairs, tradercurrencypair } = props;
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <Widget
        ref={setNodeRef}
        id={id}
        style={style}
        currencypairs={currencypairs}
        tradercurrencypair={tradercurrencypair}
        {...attributes}
        {...listeners}
      />
    );
  };

  useEffect(() => {
    getOrders();
  }, [update]);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["categories"]}</h4>
          <Link
            to="/newcategory"
            type="button"
            className="btn btn-success btn-rounded btn-fw"
          >
            <i className="mdi mdi-plus"></i> {language["new"]}
          </Link>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <DndContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragCancel={() => setActiveId(null)}
              >
                {data && (
                  <SortableContext items={data} strategy={rectSwappingStrategy}>
                    {data.map((value, index) => (
                      <SortableWidget
                        key={"product-" + index}
                        id={value}
                        index={index}
                      />
                    ))}
                  </SortableContext>
                )}
                <DragOverlay>
                  {activeId ? <Widget id={activeId} /> : null}
                </DragOverlay>
              </DndContext>
              {/* {data && data.map(item => <tr key={item.id}>
                    <td className="col-6">{item.title}</td>
                    <td className="col-6"><Link className="btn btn-success m-2" to={"/editcategory/"+item.id}><i className="mdi mdi-pen"></i> Edit</Link>
                    <button className="btn btn-danger m-2" onClick={() => handleDelete(item.id)}><i className="mdi mdi-delete"></i> Delete</button></td>
                </tr>)} */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Categories;
