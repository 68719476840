const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-inner-wraper">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
            Copyright © Buthoor 2022 - {new Date().getFullYear()}
          </span>
          {/* <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Developed By <a href="https://www.twitter.com/humaidanhamad" target="_blank">Humaidan Hamad</a></span> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
