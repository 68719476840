import { createRef, useEffect, useState } from "react";
import {
  query,
  addDoc,
  collection,
  getDocs,
  doc,
  serverTimestamp,
  where,
  orderBy,
  runTransaction
} from "firebase/firestore/lite"

import { Br, Cut, Line, Printer, Text, Row, render, Cashdraw, Barcode, QRCode } from 'react-thermal-printer'

const Newrevenue = ({ language, database }) => {
  const [total, setTotal] = useState(0);
  const [dMsg, setDMsg] = useState(null);
  const [list, setList] = useState([]);
  const [optionsList, setOptionsList] = useState(null);
  const [qty, setQty] = useState(1);
  const [remain, setRemained] = useState(0);
  const [productsList, setProductsList] = useState(null);
  const [payment, setPayment] = useState("cash");
  const [orderTypeList, setOrderTypeList] = useState(null);
  const [orderType, setOrderType] = useState("shop");
  const formData = createRef();
  let UPC = "";

  
  useEffect(() => {
    let temp_arr = []
    getDocs(query(collection(database, "orderTypes"), where("enable", "==", true), orderBy("sort", "asc")))
    .then(res => {
      res.forEach(doc => {
        temp_arr.push({
          id: doc.id,
          name: doc.data().name,
          color: doc.data().color,
          enable: doc.data().enable
        })
      })
      setOrderTypeList(temp_arr)
    })
    .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    let temp_arr = [];
    let branch = window.localStorage.getItem("branch");
    getDocs(query(collection(database, "products")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //if (doc.data().availability[branch].enable === true) {
            temp_arr.push({
              id: doc.id,
              title: doc.data().title,
              title_ar: doc.data().title_ar,
              price: doc.data().price,
              talabat: doc.data().talabat?doc.data().talabat:0,
              barcode: doc.data().barcode?doc.data().barcode:'',
              qty: doc.data().qty?parseInt(doc.data().qty):1,
            });
          //}
        });

        setProductsList(temp_arr);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    calucalateTotal();
  }, [list, orderType]);

  const handleAddProduct = (e) => {
    e.preventDefault();
    let sumTotal = 0;

    let temp_items = [...list];

    let item_details = productsList.filter(
      (val) =>
        val.title.toLowerCase() == formData.current[0].value.toLowerCase()
    );
    item_details[0].qty = qty;

    temp_items.push(item_details[0]);

    sumTotal = temp_items.reduce(
      (accumulator, currentValue) =>
        accumulator +
        parseFloat(orderType === 'Talabat'?currentValue.talabat !== 0?currentValue.talabat:currentValue.price:currentValue.price) * parseInt(currentValue.qty),
      0
    );

    setList(temp_items);
    setQty(1);
    formData.current[0].value = "";
  };

  const handleRemoveItem = (item) => {
    let temp_arr = null;
    let r = window.confirm(language["confirm"]);

    if (r) {
      temp_arr = list.filter((val) => val.id !== item);
      setList(temp_arr);
    }
  };

  const calucalateTotal = () => {
    let temp_total = 0;
    const initialValue = 0;      
    temp_total = list.reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) +
        (parseFloat(orderType === 'Talabat'?currentValue.talabat !== 0?currentValue.talabat:currentValue.price:currentValue.price) * parseInt(currentValue.qty)),
      initialValue
    );
    //temp_total = list.reduce(((accumulator, currentValue) => accumulator + currentValue.price, 0))
    setTotal(temp_total.toFixed(3));
  };

  const handlePaid = val => {
    let temp_remain = total - parseFloat(val.target.value)
    setRemained(Math.abs(temp_remain.toFixed(3)))
  }

  const handleSearchProduct = (e) => {
    let term = e.target.value;
    let temp_options = [];

    if (term.length > 3) {
      productsList.forEach((item) => {
        try {
          if (
            item.title.toLowerCase().includes(term) ||
            item.title_ar.includes(term)
          ) {
            temp_options.push(item);
          }
        } catch {
          //console.log(doc.data());
        }
      });

      setOptionsList(temp_options);
    }
  };

  const handleOrder = async (op) => {
    console.log(list, total);
    let branch = window.localStorage.getItem("branch")
    let enable = false
    const orderId = Date.now()

    if (list && list.length > 0) {
      enable = true;
    } else {
      setDMsg(language["required-error-msg"]);
    }

    if (enable === true) {
      
      addDoc(collection(database, "orders"), {
        orderId: orderId,
        type: orderType,
        userId: 8965656,
        items: list,
        total: parseFloat(total),
        canceled: false,
        payment: payment,
        branch: branch,
        timestamp: serverTimestamp(),
      })
      .then(async res => {
        list.map(async (item) => {
          try {
            await runTransaction(database, async (transaction) => {
              const sfDoc = await transaction.get(doc(database, "products", item.id));
              if (!sfDoc.exists()) {
                throw "Document does not exist!";
              }
          
              const newPopulation = sfDoc.data().qty;
              transaction.update(doc(database, "products", item.id), { qty: Math.abs(newPopulation - parseInt(item.qty)) })

              if(orderType === 'shop'){
                const receipt = (
                  <Printer type="epson" width={42}>
                    {/* <Text size={{ width: 2, height: 2 }}>9,500원</Text> */}
                    <Text size={{ width: 2, height: 2 }} bold={true}>BUTHOOR</Text>
                    <Br />
                    <Text bold={true}>TAX INVOICE</Text>
                    <Br />
                    <Text bold={true}>{String(orderId)}</Text>
                    <Br />
                    <Line />
                    {list && list.map(item => <Row left={item.title} right={'x' + item.qty + ' ' +(orderType === 'Talabat'?item.talabat !== 0?item.talabat:item.price:item.price)} />)}
                    <Br />
                    <Line />
                    <Row left="Subtotal" right={String(total)} />
                    <Line />
                    <Br />
                    <Barcode align="center" type="UPC-A" content={String(orderId)} />
                    <Br />
                    <Text align="center" bold={true}>The True Wealth Is Health</Text>
                    <Br />
                    <Text align="center" bold={true}>GSM: 94438228</Text>
                    <Br />
                    <QRCode align="center" content="https://buthoormart.com/" />
                    <Br />
                    <Text bold={true}>Website: www.buthoormart.com</Text>
                    <Br />
                    <Cut />
                    <Cashdraw pin="2pin" />
                    <Cashdraw pin="5pin" />
                  </Printer>
                )

                const receipt_data = await render(receipt);
                const port = await window.navigator.serial.requestPort();
                await port.open({ baudRate: 9600 });
                const writer = port.writable?.getWriter();

                if (writer != null) {
                  await writer.write(receipt_data);
                  writer.releaseLock();
                }
              }
              
              if (op === "new") {
                window.location.reload();
              } else {
                window.location.href = "/orders/list";
              }

            });
          } catch (e) {
            setDMsg(language['error-msg'])

            if (op === "new") {
              window.location.reload();
            } else {
              window.location.href = "/orders/list";
            }
          }
          
        })
        
      })
      .catch(err => {
        if (op === "new") {
          window.location.reload();
        } else {
          window.location.href = "/orders/list";
        }
      });
    }
  };

  const handleItemChange = (item, type, val) => {
    let temp_arr = list;
    temp_arr.map((list_item) => {
      if (list_item.id == item) {
        if (type === "qty") {
          list_item.qty = parseInt(val);
        } else {
          if(orderType != 'shop'){
            if(list_item.talabat !== 0){
              list_item.talabat = parseFloat(val)
            } else {
              list_item.price = parseFloat(val)
            }
          } else {
            list_item.price = parseFloat(val);
          }
        }
      }
    });
    setList(temp_arr)
    calucalateTotal();
  };

  const handleBarcodeScan = (e) => {
    let textInput = e.key || String.fromCharCode(e.keyCode);
    let targetName = e.target.localName;
    let temp_items = [...list];
    let exsit = false;

    if (textInput && textInput.length === 1 && targetName !== "input") {
      UPC = UPC !== null ? UPC + textInput : textInput;
      if (UPC.length > 5) {
        try {
          let item_details = productsList.filter(
            (val) => val.barcode && val.barcode === UPC
          );
          if (item_details && item_details.length > 0) {

            temp_items.push(item_details[0]);

            setList(temp_items);
            calucalateTotal();
            UPC = "";
          }
        } catch (err) {
          UPC = "";
        }
      }
    }
    //setUPC(newUPC)
  };

  document.addEventListener("keydown", handleBarcodeScan);

  /*useEffect(() => {
    
      return () => {
        document.removeEventListener("keydown", handleBarcodeScan);
      }
    }, [])*/

  return (
    <div className="row">
      <div className="col-12 stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">{language["new"]}</h4>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p htmlFor="delivery-time">{language["type"]}</p>
                {/* <select
                  name="delivery-time"
                  className="form-control"
                  onChange={(val) => setOrderType(val.target.value)}
                >
                  {orderTypeList && orderTypeList.map(ot => <option key={ot.id} value={ot.name}>{ot.name}</option>)}
                </select> */}
                {orderTypeList && orderTypeList.map(item => <label htmlFor={"ot"+item.id} key={"ot"+item.id} className="btn btn-sm m-1 text-white" style={{backgroundColor: item.color, borderWidth: 3, borderColor: orderType === item.name?"#000":""}} onClick={() => setOrderType(item.name)}><input className="d-none" id={"ot-"+item.id} type="radio" value={item.name} /> {item.name}</label>)}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <p htmlFor="payment-method">
                  {language["payment-method"]}
                </p>
                <label htmlFor="pm-cash" className="btn btn-sm m-1" style={{backgroundColor: 'lime', borderWidth: 3, borderColor: payment === 'cash'?"#000":""}} onClick={() => setPayment('cash')}><input className="d-none" id="pm-cash" type="radio" value={'cash'} /> {language["cash"]}</label>
                <label htmlFor="pm-card" className="btn btn-sm m-1" style={{backgroundColor: 'lime', borderWidth: 3, borderColor: payment === 'card'?"#000":""}} onClick={() => setPayment('card')}><input className="d-none" id="pm-card" type="radio" value={'card'} /> {language["card"]}</label>
                {/* <select
                  name="delivery-time"
                  className="form-control"
                  onChange={(val) => setPayment(val.target.value)}
                >
                  <option value="cash">{language["cash"]}</option>
                  <option value="cod">{language["card"]}</option>
                </select> */}
              </div>
            </div>
            <hr />
            <h4>{language["items"]}</h4>
            <form className="from row mb-3 mt-3" ref={formData}>
              <div className="col-5">
                {/* <label htmlFor="item-title">{language['title']}</label> */}
                <input
                  type="text"
                  list="products-list"
                  id="item-title"
                  placeholder={language["title"]}
                  className="form-control"
                  onChange={handleSearchProduct}
                />
                <datalist
                  name="products-list"
                  id="products-list"
                  onClick={(e) => console.log(e)}
                >
                  {optionsList &&
                    optionsList.length > 0 &&
                    optionsList.map((item) => (
                      <option key={item.title} value={item.title}>
                        {language["dir"] === "rtl" ? item.title_ar : item.title}
                      </option>
                    ))}
                </datalist>
              </div>
              <div className="col-3">
                {/* <label htmlFor="item-qty">{language['qty']}</label> */}
                <input
                  value={qty}
                  type="number"
                  id="item-qty"
                  min="1"
                  placeholder={language["qty"]}
                  className="form-control"
                  onChange={(val) => setQty(val.target.value)}
                />
              </div>
              <div className="col-2 align-top">
                <button
                  className="btn btn-sm btn-success align-top"
                  onClick={handleAddProduct}
                >
                  <i className="fa fa-plus"></i> {language["add"]}
                </button>
              </div>
            </form>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th>{language["title"]}</th>
                  <th>{language["qty"]}</th>
                  <th>{language["price"]}</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((item) => (
                    <tr key={"order-item-" + item.id}>
                      <td>
                        {language["language"] === "english"
                          ? item.title
                          : item.title_ar}
                      </td>
                      <td>
                        <input
                          type="number"
                          min="1"
                          placeholder={item.qty}
                          onKeyUp={(val) =>
                            handleItemChange(item.id, "qty", val.target.value)
                          }
                          className="form-control p-1"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          placeholder={orderType === 'Talabat'?item.talabat !== 0?item.talabat:item.price:item.price}
                          onKeyUp={(val) =>
                            handleItemChange(item.id, "price", val.target.value)
                          }
                          className="form-control p-1"
                        />
                      </td>
                      <td>
                        <b>{(parseFloat(orderType === 'Talabat'?item.talabat !== 0?item.talabat:item.price:item.price) * parseInt(item.qty)).toFixed(3)}</b>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                {!list ||
                  (list.length === 0 && (
                    <tr className="text-center text-muted p-5 m-5">
                      <td>{language["empty-cart"]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <hr />
            <div className="row">
              <div className="col-6 p-2 fs-3">
                {language["total"]}: OMR {total}
              </div>
              <div className="col-6 d-flex justify-content-center">
                {/* <label htmlFor="paid">{language['paid']}</label> */}
                <input type="number" placeholder={language["paid"]} name="paid" id="paid" className="text-center form-control m-2" step={3} onChange={handlePaid} />
                <div className="p-2">
                  {language['remain']}: OMR {remain?remain:0}
                </div>
              </div>
            </div>
            <p>{dMsg}</p>
            <div className="text-center mt-5">
              <button className="btn btn-success m-2" onClick={handleOrder}>
                {language["save"]}
              </button>
              <button
                className="btn btn-success m-2"
                onClick={() => handleOrder("new")}
              >
                {language["save-new"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newrevenue;
