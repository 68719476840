import { Link, useParams } from "react-router-dom";
import {
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore/lite";
import { useState, useEffect } from "react";

const Search = ({ database, setLoading, user, language }) => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);
  const { term } = useParams();

  const getOrders = () => {
    let temp_arr = [];
    getDocs(query(collection(database, "products")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          try {
            if (
              doc.data().title.toLowerCase().includes(term) ||
              doc.data().title_ar.includes(term)
            ) {
              temp_arr.push({
                id: doc.id,
                title: doc.data().title,
                title_ar: doc.data().title_ar,
                describtion: doc.data().describtion,
                describtion_ar: doc.data().describtion_ar,
                price: doc.data().price,
                image: doc.data().image,
                category: doc.data().category,
              });
            }
          } catch {
            //console.log(doc.data());
          }
        });
        setData(temp_arr);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      deleteDoc(doc(database, "products", item)).then(() => {
        setUpdate(Date.now());
      });
    }
  };

  useEffect(() => {
    getOrders();
  }, [update]);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["search"]}</h4>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["price"]}</th>
                <th>{language["status"]}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr>
                    <td>
                      {language["language"] === "arabic"
                        ? item.title_ar
                        : item.title}
                    </td>
                    <td>{item.price}</td>
                    <td>{item.items}</td>
                    <td>
                      <label
                        className={
                          item.enable
                            ? "badge badge-success"
                            : "badge badge-danger"
                        }
                      >
                        {item.enable ? "Available" : "Unavailable"}
                      </label>
                    </td>
                    <td>
                      <Link
                        className="btn btn-success m-2"
                        to={"/editproducts/" + item.id}
                      >
                        <i className="mdi mdi-pen"></i> {language["edit"]}
                      </Link>
                      <Link className="btn btn-info m-2" to={"/inventory/" + item.id}>
                        <i className="mdi mdi-package-check"></i> {language["inventory"]}
                      </Link>
                      <button
                        className="btn btn-danger m-2"
                        onClick={() => handleDelete(item.id)}
                      >
                        <i className="mdi mdi-delete"></i> {language["delete"]}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Search;
