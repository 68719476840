import { addDoc, collection, serverTimestamp } from "firebase/firestore/lite";
import { useEffect, useState } from "react";

export default function Notification({
  database,
  user,
  setLoading,
  navigate,
  language,
}) {
  const today = new Date()
  const MyDateString =
  today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2)
  
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("now");
  const [date, setDate] = useState(MyDateString);
  const [time, setTime] = useState("01:20:00");


  const sendNotification = () => {
    setMsg(" ");
    if (title === "" || desc === "") {
      setMsg(language['required-error-msg']);
    } else {
      let dateTime = new Date(date+' '+time).getTime();
      addDoc(collection(database, "notifications"), {
        title: title,
        content: desc,
        data: dateTime,
        status: status,
        timestamp: serverTimestamp(),
        done: status === 'now'?true:false
      })
      .then(res => {
        if(status === 'now'){
          fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "key=AAAAhn88LWU:APA91bGwiaHKoCq2Bd5VwXBmxwUTEbseqcISVdvEmoVM-YrTEL5Ko14HQOMPR3rhIfL_h65TnW5vJz0E1-3nyoewtYsC58xpOR7akGC1VE-cabvxLfeY6x6valudjB7XLhrDy2Bh8Mga",
            },
            body: JSON.stringify({
              to: `/topics/customers`,
              priority: "high",
              notification: {
                body: desc,
                title: title,
                badge: "1",
                android_channel_id: "high-priority",
              },
              data: {
                vibrate: 1,
              },
            }),
          })
          .then((response) => {
            window.location.href = "/notifications";
            //setMsg(language["success-msg"]);
          })
          .catch((err) => {
            setMsg(language["error-msg"]);
          });
        } else {
          window.location.href = "/notifications";
        }
      })
      .catch((err) => {
        setMsg(language["error-msg"]);
      });
    }
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["new"]}</h4>
          <p className="card-description">{language["notifications"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <select className="form-control " onChange={val => setStatus(val.target.value)}>
                <option value="now">{language['now']}</option>
                <option value="scheduled">{language['scheduled']}</option>
              </select>
              {status === 'scheduled' && <>
              <input
                type="date"
                onChange={(val) => setDate(val.target.value)}
                className="form-control"
                defaultValue={date}
                placeholder={language["date"]}
              />
              <input
                type="time"
                onChange={(val) => setTime(val.target.value)}
                className="form-control"
                defaultValue={time}
              />
              </>}
            </div> 
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"]}
              />
            </div>
            <div className="input-group m-3">
              <textarea
                placeholder={language["message"]}
                className="form-control"
                onChange={(val) => setDesc(val.target.value)}
              ></textarea>
            </div>
            <div className="p-3 text-danger">{msg}</div>
          </form>

          <button className="btn m-3 btn-success" onClick={sendNotification}>
            {language["send"]}
          </button>
        </div>
      </div>
    </div>
  );
}
