import { createRef, useEffect, useState } from "react";
import {
  query,
  addDoc,
  collection,
  getDocs,
  getDoc,
  doc,
  serverTimestamp,
  where,
} from "firebase/firestore/lite";

const NewMerchant = ({ language, database }) => {
  const [title, setTitle] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [area, setArea] = useState("");
  const [vatin, setVatin] = useState("");
  const [password, setPassowrd] = useState("");
  const [dMsg, setDMsg] = useState(null);
  const [position, setPosition] = useState(null);
  const [monthlyTarget, setMonthlyTarget] = useState(0);

  const handleAdd = (op) => {
    let branch = window.localStorage.getItem("branch");

    if (title !== '' && mobile !== '' && name !== '') {
      addDoc(collection(database, "merchents"), {
        name: name,
        title: title,
        mobile: mobile,
        area: area,
        email: email,
        vatin: vatin,
        lat: position?position.lat:0,
        lon: position?position.lon:0,
        monthlyTarget: monthlyTarget,
        timestamp: serverTimestamp(),
      })
      .then((res) => {
        if (op === "new") {
          window.location.reload();
        } else {
          window.location.href = "/merchants";
        }
      })
      .catch((err) => {
        setDMsg(language['error-msg'])
      });
    } else {
      setDMsg(language['required-error-msg'])
    }
  };

  const handleLocation = (url) => {
    let regex = new RegExp("@(.*),(.*),");
    let lat_long_match = url.target.value.match(regex);
    let temp_lat_lon = {
      lat: lat_long_match[1],
      lon: lat_long_match[2],
    };

    setPosition(temp_lat_lon);
  };

  return (
    <div className="row">
      <div className="col-12 stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">{language["new"]}</h4>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["title"]}</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder={language["title"]}
                  className="form-control"
                  onChange={(val) => setTitle(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["agent"]}</label>
                <input
                  id="name"
                  name="name"
                  placeholder={language["full-name"]}
                  className="form-control"
                  onChange={(val) => setName(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["mobile"]}</label>
                <input
                  type="tel"
                  id="name"
                  name="name"
                  placeholder={language["mobile"]}
                  className="form-control"
                  onChange={(val) => setMobile(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="email">{language["email"]}</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder={language["email"]}
                  className="form-control"
                  onChange={(val) => setEmail(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="area">{language["area"]}</label>
                <input
                  id="area"
                  name="email"
                  placeholder={language["area"]}
                  className="form-control"
                  onChange={(val) => setArea(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="location-on-map">
                  {language["location-on-map"]}
                </label>
                <input
                  type="text"
                  id="location-on-map"
                  name="location-on-map"
                  placeholder={
                    language["location-on-map"] + " (Google Maps Url)"
                  }
                  onChange={handleLocation}
                  className="form-control"
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="vatin">VATIN</label>
                <input
                  id="vatin"
                  name="vatin"
                  placeholder="VATIN"
                  className="form-control"
                  onChange={(val) => setVatin(val.target.value)}
                />
              </div>
              
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="monthly-target">{language["monthly-target"]}</label>
                <input
                  id="monthly-target"
                  name="monthly-target"
                  placeholder={language["monthly-target"]}
                  type="number"
                  className="form-control"
                  onChange={(val) => setMonthlyTarget(val.target.value)}
                />
              </div>

            </div>
            <hr />
            <p>{dMsg}</p>
            <div className="text-center mt-5">
              <button className="btn btn-success m-2" onClick={handleAdd}>
                {language["save"]}
              </button>
              <button
                className="btn btn-success m-2"
                onClick={() => handleAdd("new")}
              >
                {language["save-new"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMerchant;