import { addDoc, collection, serverTimestamp } from "firebase/firestore/lite";
import React, { useState } from "react";

export default function NewTalabat({
    database,
    user,
    setLoading,
    navigate,
    language,
  }) {
  const today = new Date();
  const MyDateString =
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2);

  const [total, setTotal] = useState(0);
  const [msg, setMsg] = useState("");
  const [branch, setBranch] = useState("Maabela TGO");
  const [date, setDate] = useState(MyDateString);

  const handleNewOrder = () => {
    addDoc(collection(database, "talabat-orders"), {
      orderId: Date.now(),
      userId: 8965656,
      total: total,
      branch: branch,
      date: date,
      branch: branch,
      timestamp: serverTimestamp(),
    })
    .then(res => {
      console.log(res);
      window.location.href = "/talabat";
    })
    .catch(err => {
      console.log(err);
      setMsg(language['error-msg'])
    })

  };
  
  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["new"]}</h4>
          <p className="card-description">Talabat</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <select
                className="form-control "
                onChange={(val) => setBranch(val.target.value)}
              >
                <option value="Maabela TGO">Maabela TGO</option>
                <option value="Maabela-TMP">Maabela TMP</option>
                <option value="Maabela TMP">Maabela Shop</option>
                <option value="Bawshar TGO">Bawshar TGO</option>
                <option value="Bawshar-TMP">Bawshar TMP</option>
                <option value="Bawshar TMP">Bawshar Shop</option>
                <option value="Miscellaneous Sale">Miscellaneous Sale</option>
              </select>
            </div>
            <div className="input-group m-3">
              <input
                type="number"
                onChange={(val) => setTotal(val.target.value)}
                className="form-control "
                placeholder={language["total"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="date"
                defaultValue={date}
                onChange={(val) => setDate(val.target.value)}
                className="form-control "
                placeholder={MyDateString}
              />
            </div>
            <div className="p-3 text-danger">{msg}</div>
          </form>

          <button className="btn m-3 btn-success" onClick={handleNewOrder}>
            {language["add"]}
          </button>
        </div>
      </div>
    </div>
  );
}
