import { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
} from "firebase/firestore/lite";
import { Link } from "react-router-dom";

export default function Promocodes({
  database,
  setLoading,
  language,
  usersData,
}) {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);

  useEffect(() => {
    setLoading(true);
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "promos")))
      .then((querySnapshot) => {
        setLoading(false);
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
            enable: doc.data().enable,
            validation_date: doc.data().validation_date,
            code: doc.data().code,
            date: doc.data().date,
            limit: doc.data().limit,
            amount: doc.data().amount,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [update]);

  const handleDelete = (item) => {
    setLoading(true);
    let r = window.confirm("Are you sure?");
    if (r) {
      deleteDoc(doc(database, "promos", item)).then(() => {
        setLoading(false);
        setUpdate(Date.now());
      });
    }
  };

  const handleAvailable = (item, val) => {
    updateDoc(doc(database, "promos", item), {
      enable: !val,
    })
      .then((res) => {
        setUpdate(Date.now());
      })
      .catch((res) => console.log(res));
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["promocode"]}</h4>
          {usersData && usersData.userType === "admin" && (
            <Link
              to="/newcode"
              type="button"
              className="btn btn-success btn-rounded btn-fw"
            >
              <i className="mdi mdi-plus"></i> {language["new"]}
            </Link>
          )}

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>Code</th>
                <th>{language["amount"]}</th>
                <th>{language["date"]}</th>
                <th>{language["enable"]}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">
                      {language["dir"] === "rtl" ? item.title_ar : item.title}
                    </td>
                    <td className="col">{item.code}</td>
                    <td className="col">{item.amount + "%"}</td>
                    <td className="col">{item.validation_date}</td>

                    <td className="text-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          defaultChecked={item.enable}
                          onClick={(e) => handleAvailable(item.id, item.enable)}
                        />
                      </div>
                    </td>

                    <td className="col">
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>{" "}
                          {language["delete"]}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
