import { Link } from "react-router-dom";

const Sidebar = ({ logout, usersData, language }) => {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-category">{language["app-title"]}</li>
        <li className="nav-item">
          <Link className="nav-link" to="/">
            <span className="icon-bg">
              <i className="mdi mdi-home menu-icon"></i>
            </span>
            <span className="menu-title">{language["dashboard"]}</span>
          </Link>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#finance"
            aria-expanded="false"
            aria-controls="ui-basic"
          >
            <span className="icon-bg">
              <i className="mdi mdi-book menu-icon"></i>
            </span>
            <span className="menu-title">{language["finance"]}</span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="finance">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className="nav-link" to="/orders/list">
                  {language["revenue"]}
                </Link>
                {usersData && usersData.userType === "admin" && <Link className="nav-link" to="/finance/expenses">
                  {language["expenses"]}
                </Link>}
                {/* <Link className="nav-link" to="/finance/assets">{language["assets"]}</Link> */}
              </li>
            </ul>
          </div>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#merchants"
            aria-expanded="false"
            aria-controls="ui-basic"
          >
            <span className="icon-bg">
              <i className="mdi mdi-account-group menu-icon"></i>
            </span>
            <span className="menu-title">{language["merchants"]}</span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="merchants">
            <ul className="nav flex-column sub-menu">
              {usersData && usersData.userType === "admin" && <li className="nav-item">
                <Link className="nav-link" to="/merchants">
                  {language["merchants"]}
                </Link>
              </li>}
              <li className="nav-item">
                <Link className="nav-link" to="/merchants/products">
                  {language['products-list']}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/talabat">
                  Talabat
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/merchants/summary">
                  {language['summary']}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/orders/merchants">
                  {language['orders-list']}
                </Link>
              </li> */}
            </ul>
          </div>
        </li>
        
        {/* <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#orders"
            aria-expanded="false"
            aria-controls="ui-basic"
          >
            <span className="icon-bg">
              <i className="mdi mdi-basket menu-icon"></i>
            </span>
            <span className="menu-title">{language["orders"]}</span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="orders">
            <ul className="nav flex-column sub-menu">
            </ul>
          </div>
        </li> */}

        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#products"
            aria-expanded="false"
            aria-controls="ui-basic"
          >
            <span className="icon-bg">
              <i className="mdi mdi-cube menu-icon"></i>
            </span>
            <span className="menu-title">{language["website"]}</span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="products">
            <ul className="nav flex-column sub-menu">
              {/* <li className="nav-item">
                <Link className="nav-link" to="/orders/list">
                  {language["orders-list"]}
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/subscriptions">
                  {language["subscription"]}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/orders/canceled">
                  {language["canceled-orders"]}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/products/list">
                  {language["products-list"]}
                </Link>
              </li>
              {usersData && usersData.userType === "admin" && <li className="nav-item">
                  <Link className="nav-link" to="/products/offers">
                    {language["offers"]}
                  </Link>
              </li>}
              {usersData && usersData.userType === "admin" && (
                <li className="nav-item">
                  <Link className="nav-link" to="/categories">
                    {language["categories"]}
                  </Link>
                </li>
              )}
              {usersData && usersData.userType === "admin" && (
              <li className="nav-item">
                <Link className="nav-link" to="/slideshow">
                  {/* <span className="icon-bg">
                    <i className="mdi mdi-image menu-icon"></i>
                  </span> */}
                  <span className="menu-title">{language["slideshow"]}</span>
                </Link>
              </li>
              )}
            </ul>
          </div>
        </li>

        {usersData && usersData.userType === "admin" && (
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#mobileapp"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span className="icon-bg">
                <i className="mdi mdi-cellphone menu-icon"></i>
              </span>
              <span className="menu-title">{language["mobile-app"]}</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="mobileapp">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/appslideshow">
                    <span className="menu-title">{language["slideshow"]}</span>
                  </Link>
                  <Link className="nav-link" to="/notifications">
                    <span className="menu-title">
                      {language["notifications"]}
                    </span>
                  </Link>
                  <Link className="nav-link" to="/promocode">
                    <span className="menu-title">{language["promocode"]}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        )}


        {usersData && usersData.userType === "admin" &&<li className="nav-item">
          <Link className="nav-link" to="/tasks">
            <span className="icon-bg">
              <i className="mdi mdi-playlist-check menu-icon"></i>
            </span>
            <span className="menu-title">{language["tasks"]}</span>
          </Link>
        </li>}

        {usersData && usersData.userType === "admin" && (
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#reports"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span className="icon-bg">
                <i className="mdi mdi-chart-bar menu-icon"></i>
              </span>
              <span className="menu-title">{language["reports"]}</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="reports">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/reports/shop">
                    <span className="menu-title">{language["sales"]}</span>
                  </Link>
                  <Link className="nav-link" to="/reports">
                    <span className="menu-title">{language['monthly-earnings']}</span>
                  </Link>
                  <Link className="nav-link" to="/reports/products">
                    <span className="menu-title">{language['products']} {language['reports']}</span>
                  </Link>
                  {/* <Link className="nav-link" to="/analytics">
                <span className="menu-title">{language["website-analytics"]}</span>
              </Link> */}
                </li>
              </ul>
            </div>
          </li>
        )}
        {usersData && usersData.userType === "admin" && (
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#shop"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span className="icon-bg">
                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              </span>
              <span className="menu-title">{language["shop"]}</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="shop">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/information">
                    {language["shop-information"]}
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/branches">
                    {language["branches"]}
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/cites">
                    {language["delivery-cites"]}
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        )}
        <li className="nav-item sidebar-user-actions">
          <div className="sidebar-user-menu">
            <a onClick={logout} href="!#" className="nav-link">
              <i className="mdi mdi-logout menu-icon"></i>
              <span className="menu-title">{language["logout"]}</span>
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
