import { useEffect, useState } from "react";
import { getDocs, collection, query, addDoc, updateDoc, getDoc, doc } from "firebase/firestore/lite";
import {  ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Navigate, useParams } from "react-router-dom";
import Barcode from 'react-barcode';

const EditMerchantProduct = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [price, setPrice] = useState("");
  const [merchantPrice, setMerchantPrice] = useState("");
  const [limit, setLimit] = useState(1000);
  const [merchant, setMerchant] = useState("0,all");
  const [image, setImage] = useState(Date.now() + ".jpg");
  const [list, setList] = useState(null);
  const [branches, setBranches] = useState([]);
  const [msg, setMesg] = useState("");
  const [barcode, setBarcode] = useState(" ");
  const [unit, setUnit] = useState("");
  const [priceList, setPriceList] = useState([]);
  const { id } = useParams()

  const getMerchants = () => {
    let temp_arr = [];

    setLoading(true);
    getDocs(query(collection(database, "merchents")))
    .then((querySnapshot) => {
      querySnapshot.forEach((merchant) => {
        temp_arr.push({
          id: merchant.id,
          title: merchant.data().title,
        });
      });
      setList(temp_arr);

      getDoc(doc(database, 'merchants-products', id))
      .then(doc => {
        setTitle(doc.data().title)
        setTitleAr(doc.data().title_ar)
        setBarcode(doc.data().barcode)
        setUnit(doc.data().unit)
        setPriceList(doc.data().priceList)
      })
    })
    .catch((err) => console.log(err));
  };

  const addProduct = () => {
    setMesg("")
    if (title === "") {
      setMesg(language["required-error-msg"]);
    } else {
      updateDoc(doc(database, "merchants-products", id), {
        title: title,
        title_ar: title_ar,
        unit: unit,
        barcode: barcode,
        priceList: priceList,
      }).then((res) => {
        navigate("/merchants/products");
      })
      .catch(err => setMesg(language['error-msg']));
    }
  };

  const handleUpload = (file) => {
    const imageRef = ref(storage, "images/" + image);
    uploadBytes(imageRef, file.target.files[0]) //, metadata)
      .then((snapshot) => {
        // console.log('Uploaded', snapshot.totalBytes, 'bytes.');
        //console.log('File metadata:', snapshot.metadata);
        // Let's get a download URL for the file.
        getDownloadURL(snapshot.ref).then((url) => {
          //console.log('File available at', url);
          // ...
        });
      })
      .catch((error) => {
        console.error("Upload failed", error);
        // ...
      });
  };

  useEffect(() => {
    setImage(Date.now() + ".jpg");
    getMerchants();
  }, []);

  const addToPriceList = () => {
    let info = merchant.split(',');
    let item = {
      id: info[0],
      title: info[1],
      price: merchantPrice,
    }    
    setPriceList(ol => [...ol, item]);
  };

  const removeFromPriceList = (item) => {    
    let temp_arr = priceList.filter(val => val.id !== item)
    
    console.log(temp_arr);

    setPriceList(temp_arr)
  }

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["add"]}</h4>
          <p className="card-description">{language["products"]}</p>
          <div className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                defaultValue={title_ar}
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                defaultValue={title}
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            <label className="d-block mb-2" htmlFor="">
              {language["unit"]}
            </label>
            <br />
            <div className="input-group m-3">
              <input
                type="text"
                defaultValue={unit}
                onChange={(val) => setUnit(val.target.value)}
                className="form-control "
                placeholder={language["unit"]}
              />
            </div>
            {/* <br />
            <label className="d-block mb-2" htmlFor="">
              {language["price"]}
            </label>
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setPrice(val.target.value)}
                className="form-control"
                placeholder={language["price"]}
              />
            </div> */}
            <label className="d-block mb-2" htmlFor="">
              Barcode / SKU
            </label>
            <br />
            <div className="input-group m-3">
              <input
                type="text"
                value={barcode}
                onChange={(val) => setBarcode(val.target.value)}
                className="form-control"
                placeholder="Barcode"
              />
            </div>
              <label>{language['add'] + ' ' + language['price']}:</label>
            <div className="row m-3">
            <div className="col-4">
              <input
                type="text"
                onChange={(val) => setMerchantPrice(val.target.value)}
                className="form-control"
                placeholder={language["amount"] + ' OMR'}
              />
            </div>
              <div className="col-6">
                <select
                  name="merchant"
                  className="form-control"
                  onChange={(val) => setMerchant(val.target.value)}
                >
                  <option value={["0", "all"]}>{language['all'] + ' ' + language["merchants"]}</option>
                  {list &&
                    list.map((item) => (
                      <option key={'item-'+item.id} value={[item.id, item.title]}>
                        {item.title}
                      </option>
                    ))}
                </select>
              </div>

              <button className="btn col-2 btn-success" onClick={addToPriceList}>
                {language["add"]}
              </button>

              <div className="p-3">
                {priceList && priceList.map((item) => (
                  <div className="badge badge-success m-2" onClick={() => removeFromPriceList(item.id)} key={item.id}>
                    {item.title} (OMR {item.price}) <i className="fa fa-times"></i>
                  </div>
                ))}
              </div>
            </div>
            <Barcode value={barcode} />
            <div className="p-3 text-danger">{msg}</div>
            {/* <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={handleUpload}
                className="form-control"
                placeholder="Upload Picture"
              />
            </div> */}
          </div>

          <button className="btn m-3 btn-success" onClick={addProduct}>
            {language["save"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditMerchantProduct;
