import { collection, query, getDocs, where } from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Home = ({ database, setLoading, user, usersData, language }) => {
  const [undelivered, setUndelivered] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [ddelivered, setDdelivered] = useState(0);
  const [canceledTotal, setCanceledTotal] = useState(0);
  const [canceled, setCanceled] = useState(0);
  const [total, setTotal] = useState(0);
  const [lastMonthTotal, setLastMonthTotal] = useState(0);
  const [daily, setDaily] = useState(0);
  const [dailyEranings, setDailyEarnings] = useState([]);
  const [monthlyEranings, setMonthlyEarnings] = useState([]);

  const getStatics = () => {
    setLoading(true);
    let count = 0;
    let totalMonthlyOrders = 0;
    let totalDailyOrders = 0;
    let date = new Date();
    let doc_mtotal = 0;
    let doc_last_month_total = 0;
    let doc_m_c_total = 0;
    let doc_dtotal = 0;
    let doc_canceled = 0;
    let dcount = 0;

    let thismonth = null,
      nextMonth = null,
      lastMonth;
    if (date.getMonth() == 11) {
      thismonth = new Date(date.getFullYear(), 0, 1);
    } else {
      thismonth = new Date(date.getFullYear(), date.getMonth(), 1);
    }

    if (date.getMonth() == 11) {
      nextMonth = new Date(date.getFullYear() + 1, 0, 1);
      lastMonth = new Date(date.getFullYear() - 1, 0, 1);
    } else {
      nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    }

    let today = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
      0
    );
    today.setDate(today.getDate());

    let tomorrow = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
      0
    );
    tomorrow.setDate(tomorrow.getDate() + 1);

    let todayUnixTime = today.getTime();
    let tommorowUnixTime = tomorrow.getTime();

    let thisMonthUnixTime = thismonth.getTime();
    let thisnextUnixTime = nextMonth.getTime();
    let thisLastUnixTime = lastMonth.getTime();
    let dailyeriningsarray = [];
    let monthlyeriningsarray = [];
    let branch = window.localStorage.getItem("branch");

    //getDocs(query(collection(database, "orders"), where("orderId", ">=", thisMonthUnixTime), where("orderId", "<", thisnextUnixTime)))
    getDocs(
      query(
        collection(database, "orders"),
        where("orderId", ">=", thisLastUnixTime)
      )
    )
      //getDocs(query(collection(database, "orders")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let tot =
            doc.data().type === "delivery"
              ? (
                  parseFloat(doc.data().total) +
                  parseFloat(doc.data().details.delivery.delivery_fees)
                ).toFixed(3)
              : doc.data().total;

          if (
            doc.data().orderId >= thisMonthUnixTime &&
            doc.data().orderId < thisnextUnixTime &&
            doc.data().branch === branch
          ) {
            if (
              doc.data().delivered === false &&
              doc.data().canceled === false
            ) {
              count++;
            }

            if (doc.data().canceled === true) {
              doc_canceled++;
              doc_m_c_total += parseFloat(tot);
            }
            if (doc.data().canceled === false) {
              doc_mtotal += parseFloat(tot);
              monthlyeriningsarray.push(tot);
            }
            totalMonthlyOrders++;
          }

          if (
            doc.data().orderId >= thisLastUnixTime &&
            doc.data().orderId < thisMonthUnixTime &&
            doc.data().branch === branch
          ) {
            if (doc.data().canceled === false) {
              doc_last_month_total += parseFloat(tot);
            }
          }

          if (
            doc.data().orderId >= todayUnixTime &&
            doc.data().orderId < tommorowUnixTime &&
            doc.data().branch === branch
          ) {
            if (doc.data().canceled === false) {
              doc_dtotal += parseFloat(tot);
            }

            if (
              doc.data().delivered === false &&
              doc.data().canceled === false
            ) {
              dcount++;
            }

            if (doc.data().canceled === false) {
              dailyeriningsarray.push(tot);
            }
            totalDailyOrders++;
          }
        });

        setUndelivered(count);
        setDelivered(totalMonthlyOrders);
        setTotal(doc_mtotal.toFixed(3));
        setLastMonthTotal(doc_last_month_total.toFixed(3));
        setCanceled(doc_canceled);
        setMonthlyEarnings(monthlyeriningsarray);
        setDaily(doc_dtotal.toFixed(3));
        setDdelivered(dcount);
        setDailyEarnings(dailyeriningsarray);
        setCanceledTotal(doc_m_c_total.toFixed(3));
      })
      .catch((err) => setLoading(false));

    /*getDocs(query(collection(database, "orders"), where("orderId", ">=", todayUnixTime), where("orderId", "<", tommorowUnixTime)))
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                doc_total += parseFloat(doc.data().total);
                if(doc.data().delivered === false){
                    count++;
                }
                dailyeriningsarray.push(doc.data().total)
            })
            setDaily(doc_total.toFixed(3));
            setDdelivered(querySnapshot.docs.length);
            setDailyEarnings(dailyeriningsarray)
        }).catch(err => setLoading(false))
        //setUpdate(Date.now())*/
  };

  const setStatics = () => {
    if (window.$(".Undelevered-progress").length) {
      window.$(".Undelevered-progress").circleProgress({
        value: 100 / ((delivered * 100) / undelivered),
        size: 125,
        thickness: 7,
        startAngle: 80,
        fill: {
          gradient: ["#7922e5", "#1579ff"],
        },
      });
    }

    if (window.$(".Daily-progress").length) {
      window.$(".Daily-progress").circleProgress({
        value: 100 / ((delivered * 100) / ddelivered),
        size: 125,
        thickness: 7,
        startAngle: 10,
        fill: {
          gradient: ["#429321", "#b4ec51"],
        },
      });
    }

    if (window.$(".Monthly-progress").length) {
      window.$(".Monthly-progress").circleProgress({
        value: 100 / ((delivered * 100) / undelivered),
        size: 125,
        thickness: 7,
        startAngle: 10,
        fill: {
          gradient: ["#f76b1c", "#fad961"],
        },
      });
    }

    if (window.$(".Canceled-progress").length) {
      window.$(".Canceled-progress").circleProgress({
        value: (canceled * 100) / delivered / 100,
        size: 125,
        thickness: 7,
        startAngle: 10,
        fill: {
          gradient: ["#9f041b", "#f5515f"],
        },
      });
    }

    let pageiVewAnalyticData = {
      labels: monthlyEranings,
      datasets: [
        {
          label: "This Day",
          data: dailyEranings,
          backgroundColor: ["rgba(216,247,234, 0.19)"],
          borderColor: ["#3dd597"],
          borderWidth: 2,
          fill: true,
          pointBorderColor: "#fff",
          pointBackgroundColor: "#3dd597",
          pointBorderWidth: 2,
          pointRadius: 4,
        },
        {
          label: "This Month",
          data: monthlyEranings,
          backgroundColor: ["rgba(216,247,234, 0.19)"],
          borderColor: ["#3dd597"],
          borderWidth: 2,
          fill: false,
          pointBorderColor: "#fff",
          pointBackgroundColor: "#0162ff",
          pointBorderWidth: 2,
          pointRadius: 4,
        },
      ],
    };

    let pageiVewAnalyticOptions = {
      scales: {
        yAxes: [
          {
            display: true,
            gridLines: {
              drawBorder: false,
              display: true,
              drawTicks: false,
              color: "#eef0fa",
              zeroLineColor: "rgba(90, 113, 208, 0)",
            },
            ticks: {
              beginAtZero: true,
              stepSize: 50,
              display: true,
              padding: 10,
            },
          },
        ],
        xAxes: [
          {
            display: true,
            position: "bottom",
            gridLines: {
              drawBorder: false,
              display: false,
              drawTicks: false,
            },
            ticks: {
              beginAtZero: true,
              stepSize: 10,
              fontColor: "#a7afb7",
              padding: 10,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      legendCallback: function (chart) {
        let text = [];
        text.push('<ul className="' + chart.id + '-legend">');
        for (var i = 0; i < chart.data.datasets.length; i++) {
          text.push(
            '<li><span className="legend-box" style="background:' +
              chart.data.datasets[i].borderColor[i] +
              ';"></span><span className="legend-label text-dark">'
          );
          if (chart.data.datasets[i].label) {
            text.push(chart.data.datasets[i].label);
          }
          text.push("</span></li>");
        }
        text.push("</ul>");
        return text.join("");
      },
      elements: {
        point: {
          radius: 1,
        },
        line: {
          tension: 0,
        },
      },
      tooltips: {
        backgroundColor: "rgba(2, 171, 254, 1)",
      },
    };
    let barChartCanvas = window
      .$("#page-view-analytic")
      .get(0)
      .getContext("2d");
    // This will get the first returned node in the jQuery collection.
    let barChart = new window.Chart(barChartCanvas, {
      type: "line",
      data: pageiVewAnalyticData,
      options: pageiVewAnalyticOptions,
    });
    document.getElementById("pageViewAnalyticLengend").innerHTML =
      barChart.generateLegend();
  };
  useEffect(() => {
    getStatics();
  }, []);

  useEffect(() => {
    setStatics();
  }, [dailyEranings]);

  return (
    <>
      <h3 className="d-inline title">{language['dashboard']}</h3>
      <Link
          to="/newrevenue"
          type="button"
          className="col-1 btn btn-sm btn-success btn-rounded ml-1 mr-1 mb-2 d-inline"
        >
          <i className="mdi mdi-plus"></i> {language["new"]}
      </Link>
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="mb-2 text-dark font-weight-normal">
                {language["undelevered-orders"]}
              </h5>
              <h2 className="mb-4 text-dark font-weight-bold">{undelivered}</h2>
              <div className="dashboard-progress Undelevered-progress d-flex align-items-center justify-content-center item-parent">
                <i className="mdi mdi-truck icon-md absolute-center text-dark"></i>
              </div>
              <p className="mt-4 mb-0">{language["completed"]}</p>
              <h3 className="mb-0 font-weight-bold mt-2 text-dark">
                {delivered}
              </h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="mb-2 text-dark font-weight-normal">
                {language["canceled-orders"]}
              </h5>
              <h2 className="mb-4 text-dark font-weight-bold">{canceled}</h2>
              <div className="dashboard-progress Canceled-progress d-flex align-items-center justify-content-center item-parent">
                <i className="mdi mdi-close icon-md absolute-center text-dark"></i>
              </div>
              <p className="mt-4 mb-0">{language["total"]}</p>
              <h3 className="mb-0 font-weight-bold mt-2 text-dark">
                {canceledTotal}
              </h3>
            </div>
          </div>
        </div>
        {usersData && usersData.userType === "admin" && <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="mb-2 text-dark font-weight-normal">
                {language["daily-earnings"]}
              </h5>
              <h2 className="mb-4 text-dark font-weight-bold">{daily}</h2>
              <div className="dashboard-progress Daily-progress d-flex align-items-center justify-content-center item-parent">
                <i className="mdi mdi-food-apple icon-md absolute-center text-dark"></i>
              </div>
              <p className="mt-4 mb-0">{language["this-month"]}</p>
              <h3 className="mb-0 font-weight-bold mt-2 text-dark">{total}</h3>
            </div>
          </div>
        </div>}
        {usersData && usersData.userType === "admin" && <div className="col-xl-3  col-lg-6 col-sm-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body text-center">
              <h5 className="mb-2 text-dark font-weight-normal">
                {language["monthly-earnings"]}
              </h5>
              <h2 className="mb-4 text-dark font-weight-bold">{total}</h2>
              <div className="dashboard-progress Monthly-progress d-flex align-items-center justify-content-center item-parent">
                <i className="mdi mdi-calendar-month icon-md absolute-center text-dark"></i>
              </div>
              <p className="mt-4 mb-0">{language["last-month"]}</p>
              <h3 className="mb-0 font-weight-bold mt-2 text-dark">
                {lastMonthTotal}
              </h3>
            </div>
          </div>
        </div>}
      </div>
      <div className="row">
      <div className="col-12  grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-xl-flex justify-content-between mb-2">
                <h4 className="card-title">{language["earnings"]}</h4>
                <div
                  className="graph-custom-legend primary-dot"
                  id="pageViewAnalyticLengend"
                ></div>
              </div>
              <canvas id="page-view-analytic"></canvas>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
