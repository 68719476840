const locale = [
  {
    language: "english",
    dir: "ltr",
    lang: "ع",
    "app-title": "Buthoor",
    "app-slogo": "Slogo",
    moto: "Moto",
    "view-basket": "View Basket",
    "shopping-cart": "Shopping Cart",
    "delivery-fees": "Delivery Fees",
    search: "Search",
    "search-text": "Search products",
    about: "About",
    "my-orders": "My Orders",
    make: "Make",
    color: "Color",
    "plate-no": "Plate No",
    "full-name": "Full Name",
    mobile: "Mobile",
    email: "E-mail",
    way: "way",
    "house-no": "House No.",
    area: "Area",
    "delivery-time": "Delivery Time",
    "location-on-map": "Location",
    "required-error-msg": "Please fill all the required feilds...",
    "finish-order": "Finish Order",
    "whatsapp-note":
      "You will be directed to Whatssapp, Please press send to complete your order.",
    ok: "Whattsapp",
    cancel: "Cancel",
    "complete-order": "Complete your order:",
    pickup: "Pick Up",
    delivery: "Delivery",
    "map-note": "Click on map to set your location.",
    checkout: "Checkout",
    next: "Next",
    total: "Total",
    "empty-cart": "Empety cart! Add item to cart to finsh your order.",
    add: "Add",
    reorder: "Reorder",
    "add-more": "Add More Items",
    item: "Item",
    qty: "Qty",
    price: "Price",
    price_old: "Old Price",
    password: "Password",
    login: "Login",
    logout: "Logout",
    register: "New Account",
    "google-login": "Google Login",
    thankyou: "Thank You",
    preparing:
      "Preparing your order, we'll connect with you as soon as possible!",
    home: "Home",
    "error-msg": "Sorry, Something went wrong...",
    "success-msg": "Done successfully.",
    "login-error-msg": "Wrong E-mail or Password, try again or tab register.",
    "payment-method": "Payment Method",
    "guest-login": "Continue as guest",

    dashboard: "Dashboard",
    orders: "Orders",
    "orders-list": "Orders List",
    "canceled-orders": "Canceled Orders",
    products: "Products",
    "products-list": "Product List",
    categories: "Categories List",
    offers: "Offers",
    slideshow: "Slideshow",
    reports: "Reports",
    shop: "Shop",
    "shop-information": "Shop Information",
    "delivery-cites": "Delivery Cites",
    "undelevered-orders": "Undelivered Orders",
    "daily-earnings": "Daily Earnings",
    "monthly-earnings": "Monthly Earnings",
    completed: "Completed this month",
    "out-of": "Out Of",
    "this-day": "This Day",
    "this-month": "This Month",
    earnings: "Earnings",
    orderid: "Order ID",
    date: "Date",
    type: "Type",
    items: "Items",
    status: "Status",
    undelivered: "Undelivered",
    delivered: "delivered",
    view: "view",
    print: "Print",
    "send-to-whatsapp": "Send to Whatsapp",
    edit: "Edit",
    new: "New",
    delete: "Delete",
    add: "Add",
    show: "Show",
    "select-month": "Select Month",
    "all-areas": "All Areas",
    "all-product": "All products",
    "edit-shop-main-info": "Edit shop maininfo",
    "search-products": "Search Products",
    cancel: "Cancel",
    update: "Update",
    confirm: "Are you sure?",
    title: "Title",
    describtion: "Describtion",
    available: "Available",
    unavailable: "Unavailable",
    pending: "Pending",
    arabic: "Arabic",
    english: "English",
    "payment-status": "Payment Status",
    paid: "Paid",
    unpaid: "Unpaid",
    "payment-fee": "Online payment fee",
    refund: "Refund",
    calculate: "Calculate",
    inventory: "Inventory",
    subscription: "Delivery Subscription",
    "2-a-month": "Once every month",
    "4-a-month": "Weekly",
    "8-a-month": "Two times a week",
    "subs-note":
      "Subscription will start after your first order and we'll ship current items on your cart according to the selected package until you cancel your subscription",
    "no-sub": "Not subscribed",
    today: "Today",
    all: "All",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    "confirm-delivery": "Confirm Delivery",
    "bulk-price": "Bulk Price",
    package: "Bulk Unit",
    "packaging-cost": "Packaging Cost",
    "time-cost": "Time Cost",
    weight: "Weight",
    rate: "Rate",
    interest: "Gross",
    "profit-goal": "Profit Goal",
    "kg-pc": "KG/PC",
    "previous-prices": "Previous Prices",
    "qty-limit": "Set Quantity Limit",
    "monthly-discount": "Monthly delivery fees discount (%)",
    "opening-times": "Opening Times",
    "minimum-order": "Minimum Order Amount",
    branch: "Branch",
    branches: "Branches",
    download: "Download",
    category: "Category",
    subtotal: "Subtotal",
    newsletter: "Newsletter",
    sales: "Financial Performance",
    "website-analytics": "Website Analytics",
    save: "Save",
    "save-new": "Save & New",
    cash: "Cash",
    //"cod": "Card on delivery",
    cod: "Bank transfer",
    "last-month": "Last Month",
    "mobile-app": "Mobile App",
    notifications: "Notifications",
    promocode: "Promo Codes",
    message: "Message",
    send: "Send",
    expire: "Expire at",
    amount: "Amount",
    finance: "Finance",
    revenue: "Revenue",
    expenses: "Expenses",
    "revenue-list": "Revenue List",
    "expenses-list": "Expenses List",
    card: "By Visa",
    "receipt-photo": "Receipt Photo",
    from: "From",
    to: "To",
    rent: "Rent",
    salary: "Salary",
    "gov-expenses": "Govt. Expenses",
    "bank-charges": "Bank Charges",
    "water-electricity": "Water & Electricity",
    "repairs-maintenance": "Repairs and Maintenance",
    "printing-stationery": "Printing and Stationery",
    "mobile-phone": "Mobile and phone Exp.",
    "cleaning-utenils": "Cleaning & Utenils",
    "car-instalments": "Car Instalments",
    "car-maintenance": "Car Maintenance",
    "advertising-expenses": "Advertising Expenses",
    "fuel-expenses": "Fuel Expenses",
    "sadaqa": "Sadaqa",
    "new-online": "New Online Order",
    "cancel-reason": "Cancel Reason",
    "close": "Close",
    "sale-return": "Sale Return",
    "customer-cancel": "Customer Canceled the order",
    "item-unavailable": "Items is unavailable",
    "items-damage": "Order items are damaged",
    "other": "Other",
    "return": "returned",
    "retail_sales": "Retail sales",
    "delivery_apps": "Delivery Applications",
    "website_app": "Website - Application",
    "other_income": "Other Income",
    "total_revenues": "Total Revenues",
    "purchase_returns": "Purchase Returns",
    "net-profit": "Net Profit",
    "gross-profit": "Gross Profit",
    "profit-loss": "Profit & Loss",
    "remain": "Remainder",
    "operation-expenses": "Operation Expenses",
    "app-commotions": "App Commotions",
    "packaging-expenses": "Packaging Expenses",
    "raw-materials": "Raw Materials",
    "import-csv": "Import CSV from file",
    "import": "Import",
    "now": "Now",
    "scheduled": "Scheduled",
    "tasks": "Tasks",
    "todo": "To Do",
    "doing": "In Progress",
    "done": "Done",
    "due": "Due",
    "merchants": "Merchants",
    "merchant": "Merchant",
    "invoices": "Invoices",
    "export": "Export",
    "agent": "Agent Name",
    "apply-vat": "Apply VAT",
    "issue-return": "Issue Return",
    "unit": "Unit",
    "sub-total": "Sub Total",
    "total-vat": "Vat Total",
    "assets-materials-equipments": "Assets, Material & Equipments",
    "meeting-fees": "Meeting Fees",
    "sandwiches": "Sandwiches",
    "drinking-water": "Drinking Water",
    "stickers": "Stickers",
    "fruits-vegetables": "Fruits & Vegetables",
    "furnitures": "Furnitures",
    "entertainment-gist": "Entertainment Gist",
    "upload": "Upload",
    "summary": "Summary",
    "monthly-target": "Monthly Target",
    "website": "Website",
  },
  {
    language: "arabic",
    dir: "rtl",
    lang: "En",
    "app-title": "بزور",
    "app-slogo": "جملة المتجر الترويجية",
    moto: "جملة المتجر الترويجية 2",
    "view-basket": "عرض السلة",
    "shopping-cart": "سلة التسوق",
    "delivery-fees": "رسوم التوصيل",
    search: "بحث",
    "search-text": "البحث عن المنتجات",
    about: "من نحن",
    "my-orders": "الطلبات الخاصة بي",
    make: "النوع",
    color: "اللون",
    "plate-no": "رقم اللوحة",
    "full-name": "الإسم الكامل",
    mobile: "رقم الهاتف",
    email: "البريد الإلكتروني",
    way: "السكة",
    "house-no": "رقم المنزل",
    area: "المنطقة",
    "delivery-time": "وقت التوصيل",
    "location-on-map": "الموقع",
    "required-error-msg": "الرجاء تعبئة جميع الحقول المطلوبة",
    "finish-order": "إتمام الطلب",
    "whatsapp-note":
      "سيتم تحويلك إلى وتساب، الرجاء الضغط على الإرسال لإتمام الطلب",
    ok: "وتساب",
    cancel: "إلغاء",
    "complete-order": "إنهاء الطلب:",
    pickup: "إستلام",
    delivery: "توصيل",
    "map-note": "إضغط على الخريطة لتحديد الموقع الخاص بك.",
    checkout: "إنهاء",
    next: "التالي",
    arabic: "العربية",
    english: "الإنجليزية",
    total: "المجموع",
    "empty-cart": "سلة التسوق فارغة! إضف إلى السلة لتكمل الطلب.",
    add: "إضافة",
    reorder: "إعادة الطلب",
    "add-more": "أضافة المزيد",
    item: "المنتج",
    qty: "الكمية",
    price: "السعر",
    price_old: "السعر القديم",
    password: "كلمة المرور",
    login: "تسجيل الدخول",
    logout: "الخروج من الحساب",
    register: "حساب جديد",
    "google-login": "تسجيل الدخول بحساب جوجل",
    thankyou: "شكرا لك",
    preparing: "يتم تحضير طلبك، سيتم التواصل معك في أقرب فرصة!",
    home: "الصفحة الرئيسية",
    "error-msg": "عزرا، هنالك خطأ...",
    "success-msg": "تم بنجاح.",
    "login-error-msg":
      "كلمة المرور او البريد الإلكتروني خاطئ، حاول مجدداً او اضغط تسجيل",
    "payment-method": "طريقة الدفع",
    "guest-login": "المتابعة كضيف",

    dashboard: "لوحة التحكم",
    orders: "الطلبات",
    "orders-list": "قائمة الطلبات",
    "canceled-orders": "الطلبات الملغية",
    products: "المنتجات",
    "products-list": "قائمة المنتجات",
    categories: "قائمة الأصناف",
    offers: "العروض",
    slideshow: "عارض الصور",
    reports: "التقارير",
    shop: "المحل",
    "shop-information": "معلومات المحل",
    "delivery-cites": "مناطق التوصيل",
    "undelevered-orders": "الطلبات التي لم يتم توصيلها",
    "daily-earnings": "الأرباح اليومية",
    "monthly-earnings": "الأرباح الشهرية",
    completed: "المنتهية هذا الشهر",
    "out-of": "من أصل",
    "this-day": "هذا اليوم",
    "this-month": "هذا الشهر",
    earnings: "الأرباح",
    orderid: "رقم الطلب",
    date: "التاريخ",
    type: "النوع",
    items: "العناصر",
    status: "الحالة",
    undelivered: "لم يتم موصل",
    delivered: "تم التوصيل",
    view: "عرض",
    print: "طباعة",
    "send-to-whatsapp": "إرسال عن طريق وتساب",
    edit: "تعديل",
    new: "جديد",
    delete: "حذف",
    add: "إضافة",
    show: "عرض",
    "select-month": "إختر الشهر",
    "all-areas": "كل المناطق",
    "all-product": "كل المنتجات",
    "edit-shop-main-info": "تعديل بيانات المحل",
    "search-products": "البحث عن منتجات",
    cancel: "إلغاء",
    update: "تحديث",
    confirm: "هل أنت متأكد؟",
    title: "العنوان",
    describtion: "الوصف",
    available: "متوفر",
    unavailable: "غير متوفر",
    pending: "في الإنتظار",
    "payment-status": "حالة الدفع",
    paid: "تم الدفع",
    unpaid: "لم يتم الدفع",
    "payment-fee": "ضريبة الدفع عن طريق البطاقة",
    refund: "ارجاع المبلغ",
    calculate: "حساب",
    inventory: "المخزن",
    subscription: "اشترك في التوصيل الدوري",
    "2-a-month": "مرتين شهريا",
    "4-a-month": "اسبوعيا",
    "8-a-month": "مرتين اسبوعيا",
    "subs-note":
      "الاشتراك سوف يبدا بعد اكمال طلبك بنجاح وسيتم توصيل المنتجات المختارة في سلة التسوق دوريا بحسب الباقة المختارة لغاية الغاء الاشتراك من قبلكم.",
    "no-sub": "غير مشترك",
    today: "اليوم",
    all: "الكل",
    monday: "الاثنين",
    tuesday: "الثلاثاء",
    wednesday: "الاربعاء",
    thursday: "الخميس",
    friday: "الجمعة",
    saturday: "السبت",
    sunday: "الاحد",
    "confirm-delivery": "تاكيد التوصيل",
    "bulk-price": "سعر الشراء",
    package: "الحزمة",
    weight: "الوزن",
    "packaging-cost": "سعر التغليف",
    "time-cost": "سعر الوقت",
    rate: "المعدل",
    interest: "الفائدة",
    "kg-pc": "كيلو/قطعة",
    "profit-goal": "الفائدة المستهدفة",
    "previous-prices": "الأسعار السابقة",
    "qty-limit": "وضع حد الطلب",
    "monthly-discount": "نسبة الخصم للتوصيل الشهري ٪",
    "opening-times": "اوقات العمل",
    "minimum-order": "مبلغ الحد الادني للطلب",
    branch: "الفرع",
    branches: "افرع المحل",
    download: "تحميل",
    category: "القسم",
    subtotal: "مجموع السلة",
    newsletter: "النشرة البريدية",
    sales: "الأداء المالي",
    "website-analytics": "تقرير الموقع الالكتروني",
    save: "حفظ",
    "save-new": "حفظ وجديد",
    cash: "نقدا",
    //"cod": "الدفع بالبطاقة عند الاستلام",
    cod: "تحويل بنكي",
    "last-month": "الشهر الماضي",
    "mobile-app": "تطبيق الهاتف",
    notifications: "التنبيهات",
    promocode: "الرموز الترويجية",
    message: "الرسالة",
    send: "ارسال",
    expire: "تاريخ الانتهاء",
    amount: "المبلغ",
    finance: "المحاسبة",
    revenue: "الايرادات",
    expenses: "المصروفات",
    "revenue-list": "قائمة الايرادات",
    "expenses-list": "قائمة المصروفات",
    card: "البطاقة البنكية",
    "receipt-photo": "صورة الايصال",
    from: "من",
    to: "الى",
    rent: "الايجار",
    salary: "الرواتب",
    "gov-expenses": "المصاريف الحكومية",
    "bank-charges": "المصاريف البنكية",
    "water-electricity": "الماء والكهرباء",
    "repairs-maintenance": "الصيانة",
    "printing-stationery": "الطباعة",
    "mobile-phone": "الهواتف والاتصالات",
    "cleaning-utenils": "النظافة",
    "car-instalments": "اقساط السيارات",
    "car-maintenance": "صيانة السيارات",
    "advertising-expenses": "مصاريف الدعاية والاعلان",
    "fuel-expenses": "مصاريف الوقود",
    "sadaqa": "الصدقات",
    "new-online": "طلب جديد للتوصيل",
    "cancel-reason": "سبب الإلغاء",
    "close": "اغلاق",
    "sale-return": "مسترجع المبيعات",
    "customer-cancel": "الزبون الغي الطلب",
    "item-unavailable": "الاغراض غير متوفرة",
    "items-damage": "الاغراض تالفة",
    "other": "اخرى",
    "return": "تم الارجاع",
    "retail_sales": "ايرادات المبيعات لمحل",
    "delivery_apps": "تطبيقات التوصيل",
    "website_app": "الموقع الالكتروني - التطبيق",
    "other_income": "دخل آخر",
    "total_revenues": "مجموع الايرادات",
    "purchase_returns": "مردودات الشراء",
    "net-profit": "صافي الربح",
    "gross-profit": "مجمل الربح",
    "profit-loss": "بيان الربح والخسارة",
    "remain": "الباقي",
    "operation-expenses": "مصاريف التشغيل",
    "app-commotions": "عمولة التطبيقات",
    "packaging-expenses": "تكلفة مصاريف التغليف",
    "raw-materials": "المواد الخام",
    "import-csv": "استيراد الملفات من ملف CSV",
    "import": "استيراد",
    "now": "الآن",
    "scheduled": "مجدولة",
    "tasks": "المهام",
    "todo": "سيتم التنفيذ",
    "doing": "تحت التنفيذ",
    "done": "تم التنفيذ",
    "due": "للتنفيذ في",
    "merchants": "التجار",
    "merchant": "التاجر",
    "invoices": "الفواتير",
    "export": "تصدير",
    "agent": "اسم المفوض",
    "apply-vat": "تطبيق القيمة المضافة",
    "issue-return": "امر استرجاع",
    "unit": "الوحدة",
    "sub-total": "المجموع الصافي",
    "total-vat": "مجموع القيمه المضافة",
    "assets-materials-equipments": "الاصول، المواد والمعدات",
    "meeting-fees": "مصاريف الاجتماعات",
    "sandwiches": "الساندوتشات",
    "drinking-water": "مياه الشرب",
    "stickers": "الملصقات",
    "fruits-vegetables": "الفواكه والخضروات",
    "furnitures": "الأثاث",
    "entertainment-gist": "الترفيه",
    "upload": "رفع",
    "summary": "الخلاصه",
    "monthly-target": "الهدف الشهري",
    "website": "الموقع الالكتروني",

  },
];

export default locale;
