import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import { Link } from "react-router-dom";

export default function Summary({ database, setLoading, language, usersData }) {
  const MyDate = new Date();
  const MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    "01";
  const lastDay = new Date(MyDate.getFullYear(), MyDate.getMonth() + 1, 0);
  const toDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    lastDay.getDate();

  const [data, setData] = useState(null);
  const [shadowData, setShadowData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [ordersTotal, setOrdersTotal] = useState(null);
  const [merchantsTotal, setMerchantsTotal] = useState(null);
  const [merchantsList, setMerchantsList] = useState(null);
  const [products, setProducts] = useState(null);
  const [merchantsProducts, setMerchantsProducts] = useState(null);
  const [talabat, setTalabat] = useState(null);
  const [talabatTotals, setTalabatTotals] = useState(null)
  const [grandTotals, setGrandTotal] = useState(null);
  const [merchantsProductsTotals, setMerchantsProductsTotals] = useState(null);
  const [fromDate, setFromDate] = useState(MyDateString);
  const [toDate, setToDate] = useState(toDateString);
  const [csv, setCsv] = useState("");
  const formatter = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "omr",
  });

  let talabat_branches = ["Maabela TGO", "Maabela-TMP", "Maabela TMP", "Bawshar TGO", "Bawshar-TMP", "Bawshar TMP", "Miscellaneous Sale"];

  useEffect(() => {
    setLoading(true);
    let temp_arr = [];
    setLoading(true);
    getDocs(
      query(collection(database, "merchents"), orderBy("timestamp", "desc"))
    )
      .then((querySnapshot) => {
        setLoading(false);
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            name: doc.data().name,
            enable: doc.data().enable,
            mobile: doc.data().mobile,
            email: doc.data().email,
            area: doc.data().area,
            monthlyTarget: doc.data().monthlyTarget,
            //lat: doc.data().lat,
            //lon: doc.data().lon,
            timestamp: doc.data().timestamp,
          });
        });
        setMerchantsList(temp_arr);
        loadProductsList();
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [update]);

  const loadProductsList = () => {
    let temp_arr = [];
    getDocs(
      query(
        collection(database, "merchants-products")
        //orderBy("timestamp", "desc")
      )
    )
      .then((querySnapshot) => {
        setLoading(false);
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            name: doc.data().title_ar,
            barcode: doc.data().barcode,
            priceList: doc.data().priceList,
            timestamp: doc.data().timestamp,
          });
        });
        //console.log(temp_arr);
        setProducts(temp_arr); 
        getTalabat()
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const getTalabat = () => {
    let selectedNextDay = new Date(toDate);
    let nextDay = new Date(selectedNextDay);
    nextDay.setDate(selectedNextDay.getDate() + 1);

    getDocs(
      query(
        collection(database, "talabat-orders"),
        where("date", ">=", fromDate),
        where("date", "<=", nextDay.toLocaleDateString("en-CA")),
        orderBy("date", "desc")
      )
    )
      .then((querySnapshot) => {
        let temp_arr = [];
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            total: doc.data().total,
            date: doc.data().date,
            branch: doc.data().branch,
            timestamp: doc.data().timestamp,
          });
        });
        setTalabat(temp_arr)
      })
      .catch((err) => setLoading(false));
    
  }

  const handleDisplay = () => {
    let selectedNextDay = new Date(toDate);
    let nextDay = new Date(selectedNextDay);
    nextDay.setDate(selectedNextDay.getDate());

    let temp_arr = [];
    let temp_csv = "";
    let tmp_merchant = [];

    setLoading(true);

    getDocs(
      query(
        collection(database, "merchant-orders"),
        where("invoiceDate", ">=", fromDate),
        where("invoiceDate", "<=", nextDay.toLocaleDateString("en-CA")),
        orderBy("invoiceDate", "desc")
      )
    )
      .then((querySnapshot) => {
        let vatTotal_tmp = 0;
        let total_tmp = 0;
        let tmp_totals = [];
        let total_orders = [];
        let merchants_products = [];
        let merchants_products_totals = [];
        let talabat_totals = [];
        
        querySnapshot.forEach((doc) => {
          let returnReason = '';
          temp_arr.push({
            id: doc.id,
            timestamp: doc.data().timestamp,
            total: doc.data().total,
            items: doc.data().items,
            merchant: doc.data().merchant,
            merchantBranch: doc.data().merchantBranch,
            orderId: doc.data().orderId,
            vat: doc.data().vat,
            lpo: doc.data().lpo,
            date: doc.data().date,
            invoiceDate: doc.data().invoiceDate,
            totalItems: 0,
            returnTotal: 0,
            returnTotalAmount: 0,
            returnReason: "",
            returnPercintage: 0
          });

          if (doc.data().items) {
            let returnedNo = 0;
            let returnTotal = 0;
            let totalItems = 0;
            let returnTotalAmount = 0;
            doc.data().items.map((i) => {
              console.log(i);
              
              totalItems = totalItems + parseInt(i.qty);
              
              vatTotal_tmp = vatTotal_tmp + i.price * parseInt(i.qty) * (doc.data().vat / 100);

              if (i.cqty) {
                returnedNo = returnedNo + parseInt(i.cqty);
                returnTotal = returnTotal + (i.price * parseInt(i.cqty) + parseInt(i.cqty) * (i.price * (doc.data().vat / 100)));
                returnTotalAmount = returnTotalAmount + (i.price * parseInt(i.cqty) + parseInt(i.cqty) * (i.price * (doc.data().vat / 100)));
                returnReason = i.returnReason
              }
            });


            //console.log(totalItems);
            const itemIndex = temp_arr.findIndex(item => item.id === doc.id)
            temp_arr[itemIndex].returnReason = returnReason;
            temp_arr[itemIndex].returnTotal = returnedNo;
            temp_arr[itemIndex].totalItems = totalItems;
            temp_arr[itemIndex].returnTotalAmount = returnTotalAmount;
            temp_arr[itemIndex].returnPercintage = parseInt((100 * returnedNo) /  totalItems)

          }

          tmp_merchant[doc.data().merchant] = tmp_merchant[doc.data().merchant]
            ? tmp_merchant[doc.data().merchant] + doc.data().total
            : doc.data().total;
        });

        if (merchantsList) {
          merchantsList.map((m, index) => {
            let tmp_total_orders = [], tmp_ttotals = [];
            for (let index = 0; index < new Date(toDate).getDate(); index++) {
              tmp_total_orders[index] = {
                total: 0,
                invoiceDate: '',
                merchant: m.id,
                vat: 0
              }

              tmp_ttotals[index] = {
                total: 0
              }
            }
            temp_arr.map((item) => {
              if (item.merchant == m.id) {
                tmp_total_orders[new Date(item.invoiceDate).getDate() - 1] = {
                  total: tmp_total_orders[new Date(item.invoiceDate).getDate() - 1].total + item.total,
                  vat: item.vat,
                  invoiceDate: item.invoiceDate,
                  merchant: item.merchant
                }

                tmp_ttotals[new Date(item.invoiceDate).getDate() - 1] = {
                  total: tmp_ttotals[new Date(item.invoiceDate).getDate() - 1].total + item.total,
                  vat: item.vat,
                  date: item.invoiceDate,
                }
              }
            })

            tmp_totals.push(tmp_ttotals)
            total_orders.push(tmp_total_orders)
          
            if (products) {
              let ptmp = [];

              products.map((p) => {
                ptmp.push({
                  id: p.id,
                  merchant: m.id,
                  qty: 0,
                  cqty: 0,
                  total: 0,
                });
              });              

              products.map((p) => {
                temp_arr.map((item) => {
                  if (item.merchant == m.id) {
                    item.items.map((i) => {
                      let tmp_vat =  item.vat?(i.price * (parseInt(item.vat) / 100)):0;
                      if (i.id == p.id) {
                        let tmp_p = ptmp.findIndex((pi) => pi.id === p.id);

                        if (
                          tmp_p != -1 &&
                          ptmp[tmp_p].merchant == item.merchant
                        ) {
                          ptmp[tmp_p].qty += parseInt(i.qty);
                          if(i.cqty){
                            ptmp[tmp_p].cqty = ptmp[tmp_p].cqty + parseInt(i.cqty)
                          }
                          ptmp[tmp_p].total =
                            ptmp[tmp_p].total +
                            (i.price *
                              (parseInt(i.qty) -
                                (i.cqty ? parseInt(i.cqty) : 0))) + (tmp_vat * (parseInt(i.qty) -
                                (i.cqty ? parseInt(i.cqty) : 0)));

                        }
                      }
                    });
                  }
                });
              });

              //tmp_totals.push(tmp_ttotals)
              merchants_products.push(ptmp);
            }
          });

          merchantsList.map((m) => {
            let qty_total = 0;
            let cqty_total = 0;
            let pqty_total = 0;
            let total_amount = 0;

            merchants_products.map((mp) => {
              mp.map((pitem) => {
                if (pitem.merchant == m.id) {
                  qty_total = qty_total + pitem.qty;
                  cqty_total = cqty_total + pitem.cqty;
                  total_amount = total_amount + parseFloat(pitem.total)
                }
              });
            });

            pqty_total = parseInt((cqty_total / qty_total) * 100);

            merchants_products_totals.push({
              merchant: m.id,
              qty: qty_total,
              cqty: cqty_total,
              total: pqty_total,
              total_amount: total_amount
            });
          });
        }

        if(talabat){
          talabat_branches.map(tb => {
            let tmp_total_orders = [];
            let tmp_ttotals = []
            for (let index = 0; index < new Date(toDate).getDate(); index++) {
              tmp_total_orders[index] = {
                total: 0,
                vat: 0,
                date: '',
                branch: '',
              }
              tmp_ttotals[index] = {
                total: 0
              }
            }
            talabat.map(item => {
              if(tb.toLocaleLowerCase() === item.branch.toLocaleLowerCase()){
                tmp_total_orders[new Date(item.date).getDate() - 1] = {
                  total: parseFloat(tmp_total_orders[new Date(item.date).getDate() - 1].total) + parseFloat(item.total),
                  vat: 0,
                  date: item.date,
                  branch: item.branch
                }
              }
              tmp_ttotals[new Date(item.date).getDate() - 1] = {
                total: parseFloat(tmp_ttotals[new Date(item.date).getDate() - 1].total) + parseFloat(item.total),
                date: item.date,
              }
            })
            tmp_totals.push(tmp_ttotals)
            talabat_totals.push(tmp_total_orders)
          })
        }

        let gt = new Array(new Date(toDate).getDate());

        tmp_totals.map((item, i) => {
          item.map((ot, index) => {
            gt[index] = (gt[index]?gt[index]:0) + ot.total
          })
        })

        setGrandTotal(gt);
        setTalabatTotals(talabat_totals);
        setOrdersTotal(total_orders);
        setMerchantsProductsTotals(merchants_products_totals);
        setData(temp_arr);
        setShadowData(temp_arr);
        setMerchantsTotal(tmp_merchant);
        setMerchantsProducts(merchants_products);
        setLoading(false);
        setUpdate(Date.now());
      })
      .catch((err) => setLoading(false));
  };

  const handleExpert = (exportType, merchant_id) => {
    let tmp_csv = ""
    let d = Date.now();
    switch (exportType) {
      case "orders":
        tmp_csv = 'Merchant, ';
        
        [...Array(new Date(toDate).getDate())].map((x, i) => {
          tmp_csv += (i + 1) + ', '
        })
        tmp_csv += 'Total, VAT Total\n';

        if(merchantsList){
          merchantsList.map((item, index) => {
            tmp_csv += item.title + ', '
            if(ordersTotal && ordersTotal[index]){
              ordersTotal[index].map(ot => {
                tmp_csv += (ot.total?formatter.format(ot.total).replace("OMR", ""):0) + ", "
              })

              tmp_csv += (formatter.format(ordersTotal[index].reduce((partialSum, a) => partialSum + a.total, 0))) + ', '

              tmp_csv += (formatter.format(ordersTotal[index].reduce((partialSum, a) => partialSum + parseFloat(a.vat), 0))) + ","
            }
            tmp_csv += '\n'
          })
          
          if(talabat_branches){
            talabat_branches.map((tb, index) => {
              tmp_csv += (tb == "Maabela TMP"?"Maabela Shop":tb == "Bawshar TMP"?"Bawshar Shop":tb.replace("-", " ")) + ", "

              if(talabatTotals){
                if(talabatTotals[index]){
                  talabatTotals[index].map(ot => {
                    tmp_csv += (ot.total?formatter.format(ot.total).replace("OMR", ""):0) + ", "
                  })

                  tmp_csv += (formatter.format(talabatTotals[index].reduce((partialSum, a) => partialSum + a.total, 0))) + "\n"
                }
              } else {
                //tmp_csv += "\n"
              }
            })
          }
          tmp_csv += '\n'
          
          tmp_csv += "Total, "
          if(grandTotals){
            grandTotals.map(ot => {
              tmp_csv += (ot?formatter.format(ot).replace("OMR", ""):0) + ", "
            })
            tmp_csv += (formatter.format(grandTotals.reduce((partialSum, a) => partialSum + a, 0))) + '\n'
          } else {
          tmp_csv += '\n'
          }
        } else {
          tmp_csv += '\n'
        }
        
      break;

      case "products":
        tmp_csv = 'Merchant,'
        if(products){
          products.map((p) => {
            tmp_csv += p.title + ', '
          })
        }
        tmp_csv += 'Qty, Return, % Return, Total\n'
        
        if(merchantsList){
          merchantsList.map((item, index) => {
            tmp_csv += item.title + ', '
            if(merchantsProducts){
              if(merchantsProducts[index]){
                merchantsProducts[index].map((m) => {
                  if(m.merchant === item.id){
                    tmp_csv += m.qty + ', '
                  }
                })
              }
            }

            if(merchantsProductsTotals){
              merchantsProductsTotals.map(
                (m) => {
                  if(m.merchant === item.id){
                    tmp_csv += m.qty + ', ' + m.cqty + ', ' + (m.total ? m.total : 0) + '%, ' + (m.total_amount ? formatter.format(m.total_amount) : 0) + '\n'
                  }
              })
            }
          })
        }

      break;

      case "merchants":
        tmp_csv = 'Date, Invoice, Branch, Total, Return, Returned\n'
        if(shadowData){
          shadowData.map(md => {
              if(md.merchant == merchant_id){
                tmp_csv += md.invoiceDate+","+"BR/"+md.orderId+","+md.merchantBranch.area+","+md.totalItems+","+md.returnTotal+","+md.returnPercintage+"%\n";
              }
          })
        }
      break;
    
      default:
        break;
    }

    var element = document.createElement("a");

    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(tmp_csv)
    );
    element.setAttribute("download", exportType + " " + fromDate + "-" + toDate + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handlePrint = (printType, mitem) => {
    let mywindow = document.getElementById("ipd").contentWindow.document;
    mywindow.open();
    

    mywindow.write(
      '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
    );
    mywindow.write(
      '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"></div><br/>'
    );

    switch (printType) {
      case "products":
        const pdiv = document.getElementById("productsList").innerHTML;
        mywindow.write(
          `<h3>${language["products"]}: ${fromDate + " ~ " + toDate}<h3><br /><hr />`
        );
        mywindow.write('<table border="1">')
        mywindow.write(pdiv);
        mywindow.write('</table>')
        break;

        case "orders":
          const odiv = document.getElementById("ordersList").innerHTML;
          mywindow.write(
            `<h3>${language["daily-earnings"]}: ${fromDate + " ~ " + toDate}<h3><br /><hr />`
          );
          mywindow.write('<table border="1">')
          mywindow.write(odiv);
          mywindow.write('</table>')
        break;

      case "merchants":
        if (merchantsList) {
          merchantsList.map((item, index) => {
            if(item.id == mitem){
            mywindow.write(
              `<h3>${item.title} ${language["orders"]}: ${fromDate + " ~ " + toDate}<h3><br /><hr />`
            );
            mywindow.write(`<div className="row">
                        <b className="col-6 p-2">
                          ${language["total"]}: 
                          ${merchantsTotal && merchantsTotal[item.id]
                            ? formatter.format(merchantsTotal[item.id])
                            : "OMR 0"}
                        </b>
                        <b className="col-6 p-2">
                          ${language["monthly-target"]}: 
                          ${merchantsTotal && merchantsTotal[item.id]
                            ? formatter.format(item.monthlyTarget)
                            : "OMR 0"} 
                          (
                          ${merchantsTotal &&
                            merchantsTotal[item.id] &&
                            (
                              ((merchantsTotal[item.id] - item.monthlyTarget) /
                                item.monthlyTarget) *
                              100
                            ).toFixed()}
                          % )
                          <br />
                        </b>
                      </div><br /><br />`)
            mywindow.write(
              `<table border=1>
          <thead>
            <tr>
              <th className="col">${language["date"]}</th>
              <th className="col">${language["invoices"]}</th>
              <th className="col-4">${language["branch"]}</th>
              <th className="col">Total Supply</th>
              <th className="col">Total Items</th>
              <th className="col-2">Total Retruns</th>
              <th className="col-2">Return Quantity</th>
              <th className="col-2">Return Code</th>
              <th className="col-2">% Retured</thth>
            </tr>
          </thead><tbody>`
            );
            if (shadowData) {
              shadowData.map((md) => {
                if (md.merchant == item.id) {
                  mywindow.write(`<tr class="text-small">
                            <td className="col" key={md.id}>
                              ${md.invoiceDate}
                            </td>
                            <td className="col btn">
                              BR/${md.orderId}
                            </td>
                            <td className="col-4">
                              ${md.merchantBranch.area}
                            </td>
                            <td className="col">
                              ${formatter.format(md.total)}
                            </td>
                            <td className="col">
                              ${md.totalItems}
                            </td>
                            <td className="col-2">${md.returnTotal}</td>
                            <td className="col-2">${formatter.format(md.returnTotalAmount)}</td>
                            <td className="col-2">${md.returnReason}</td>
                            <td className="col-2">${md.returnPercintage}%</td>
                          </tr>`);
                }
              });
            }
          }
          });
        }
        mywindow.write(`</tbody></table>`);

        break;

      default:
        break;
    }
    mywindow.close();
    let frm = document.getElementById("ipd").contentWindow;
    setTimeout(() => {
      frm.focus();
      frm.print();
    }, 300);
  };

  return (
    <>
      <div className="d-block mb-2 pb-2 border-bottom">
        <h2 className="title mr-3 ml-3">
          {language["merchants"]}: {language["summary"]}
        </h2>
        <DatePicker
          range
          defaultValue={fromDate + " ~ " + toDate}
          value={fromDate + " ~ " + toDate}
          onChange={(val) => {
            try {
              setFromDate(
                val[0].year +
                  "-" +
                  ("0" + val[0].month).slice(-2) +
                  "-" +
                  ("0" + val[0].day).slice(-2)
              );

              setToDate(
                val[1].year +
                  "-" +
                  ("0" + val[1].month).slice(-2) +
                  "-" +
                  ("0" + val[1].day).slice(-2)
              );
            } catch (err) {
              //console.log(err);
            }
          }}
          placeholder={language["select-day"]}
          //minDate={new Date()}
          className="green"
          style={{ padding: 18 }}
        />
        <button
          onClick={handleDisplay}
          className="m-2 btn btn-success"
          type="button"
        >
          {language["update"]}
        </button>
      </div>

      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-9">
              <h3 className="card-title">{language["daily-earnings"]}</h3>
            </div>
            <div className="col">
              <button
                className="btn btn-sm btn-success m-1"
                onClick={() => handlePrint("orders")}
              >
                <i className="fa fa-print"></i> {language["print"]}
              </button>
              <button className="btn btn-sm btn-success m-1" onClick={() => handleExpert('orders')}>
                <i className="fa fa-download"></i>{" "}
                {language["export"]}
              </button>
            </div>
          </div>
          <div className="text-nowrap overflow-auto">
            <table className="table table-bordered table-hover table-striped" id="ordersList">
              <thead>
                <tr>
                  <th>{language["merchant"]}</th>
                  {[...Array(new Date(toDate).getDate())].map((x, i) => <th key={"days-"+i} scope="col" className="text-truncate">{(i + 1)}</th>)}
                  <th>{language["total"]}</th>
                  <th>{language["total-vat"]}</th>
                </tr>
              </thead>
              <tbody>
                {merchantsList &&
                  merchantsList.map((item, index) => (
                    <tr>
                      <th scope="row" key={item.id}>
                        {item.title}
                      </th>

                      {ordersTotal && ordersTotal[index] && ordersTotal[index].map(ot => <td>{ot.total?formatter.format(ot.total).replace("OMR", ""):0}</td>)}

                      <td>{ordersTotal && ordersTotal[index] && formatter.format(ordersTotal[index].reduce((partialSum, a) => partialSum + a.total, 0))}</td>

                      <td>{ordersTotal && ordersTotal[index] && formatter.format(ordersTotal[index].reduce((partialSum, a) => partialSum + parseFloat(a.vat), 0))}</td>
                    </tr>
                  ))}
                  
                  {talabat_branches && talabat_branches.map((tb, index) => (<tr>
                    <th>{tb == "Maabela TMP"?"Maabela Shop":tb == "Bawshar TMP"?"Bawshar Shop":tb.replace("-", " ")}</th>
                    
                    {talabatTotals && talabatTotals[index] && talabatTotals[index].map(ot => <td>{ot.total?formatter.format(ot.total).replace("OMR", ""):0}</td>)}

                    <td>{talabatTotals && talabatTotals[index] && formatter.format(talabatTotals[index].reduce((partialSum, a) => partialSum + a.total, 0))}</td>

                  </tr>))}
                  <tr>
                    <th>{language['total']}</th>
                    {grandTotals && grandTotals.map(ot => <td>{ot?formatter.format(ot).replace("OMR", ""):0}</td>)}
                    <td>{grandTotals && formatter.format(grandTotals.reduce((partialSum, a) => partialSum + a, 0))}</td>
                  </tr>
                  
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-9">
              <h3 className="card-title">{language["products"]}</h3>
            </div>
            <div className="col">
              <button
                className="btn btn-sm btn-success m-1"
                onClick={() => handlePrint("products")}
              >
                <i className="fa fa-print"></i> {language["print"]}
              </button>
              <button className="btn btn-sm btn-success m-1" onClick={() => handleExpert('products')}>
                <i className="fa fa-download"></i>{" "}
                {language["export"]}
              </button>
            </div>
          </div>
          <div className="text-nowrap overflow-auto">
            <table className="table table-bordered table-hover table-striped" id="productsList">
              <thead>
                <tr>
                  <th>{language["merchant"]}</th>
                  {products &&
                    products.map((p) => (
                      <th key={p.id} scope="col" className="text-truncate">
                        {p.title}
                      </th>
                    ))}
                  <th>{language["qty"]}</th>
                  <th>{language["return"]}</th>
                  <th>% {language["return"]}</th>
                  <th>{language["total"]}</th>
                </tr>
              </thead>
              <tbody>
                {merchantsList &&
                  merchantsList.map((item, index) => (
                    <tr>
                      <th scope="row" key={item.id}>
                        {item.title}
                      </th>
                      {merchantsProducts &&
                        merchantsProducts[index] &&
                        merchantsProducts[index].map(
                          (m) => m.merchant === item.id && <td>{m.qty}</td>
                        )}
                      {merchantsProductsTotals &&
                        merchantsProductsTotals.map(
                          (m) =>
                            m.merchant === item.id && (
                              <>
                                <td>{m.qty}</td>
                                <td>{m.cqty}</td>
                                <td>{m.total ? m.total : 0}%</td>
                                <td>{m.total_amount ? formatter.format(m.total_amount) : 0}</td>
                              </>
                            )
                        )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="accordion" id="accordionExample">
        {merchantsList &&
          merchantsList.map((item, index) => (
            <div className="card" id={"item" + item.id + index} key={"item" + item.id + index}>
              <div className="card-header" id={"heading" + index}>
                <a className="mb-0 btn" href={"#item" + item.id + index}>
                  <button
                    className="btn collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target={"#" + item.id}
                    aria-expanded="false"
                    aria-controls={item.id}
                  >
                    {item.title}
                  </button>
                </a>
              </div>
              <div
                id={item.id}
                className="collapse"
                aria-labelledby={"heading" + index}
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      {language["orders"]}: {fromDate + " ~ " + toDate}
                      <br />
                      <div className="row">
                        <b className="col-6 p-2">
                          {language["total"]}:{" "}
                          {merchantsTotal && merchantsTotal[item.id]
                            ? formatter.format(merchantsTotal[item.id])
                            : "OMR 0"}
                        </b>
                        <b className="col-6 p-2">
                          {language["monthly-target"]}:{" "}
                          {merchantsTotal && merchantsTotal[item.id]
                            ? formatter.format(item.monthlyTarget)
                            : "OMR 0"}{" "}
                          ({" "}
                          {merchantsTotal &&
                            merchantsTotal[item.id] &&
                            (
                              ((merchantsTotal[item.id] - item.monthlyTarget) /
                                item.monthlyTarget) *
                              100
                            ).toFixed()}
                          % )
                          <br />
                        </b>
                      </div>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-sm btn-success m-1"
                        onClick={() => handlePrint("merchants", item.id)}
                      >
                        <i className="fa fa-print"></i> {language["print"]}
                      </button>
                      <button className="btn btn-sm btn-success m-1" onClick={() => handleExpert('merchants', item.id)}>
                        <i className="fa fa-download"></i>{" "}
                        {language["export"]}
                      </button>
                    </div>
                  </div>
                  <hr />
                  <table>
                    <thead>
                  <tr className="font-weight-bold text-small">
                    <th >{language["date"]}</th>
                    <th >{language["invoice"]}</th>
                    <th >{language["branch"]}</th>
                    <th className="pl-3 pr-3 text-center">Total Supply</th>
                    <th className="pl-3 pr-3 text-center">Total Items</th>
                    <th className="pl-3 pr-3 text-center">Total Retruns</th>
                    <th className="pl-3 pr-3 text-center">Return Quantity</th>
                    <th className="pl-3 pr-3 text-center">Return Code</th>
                    <th className="pl-3 pr-3 text-center">% Retured</th>
                  </tr>
                  </thead>
                  <tbody>
                  {/* <hr /> */}
                  {shadowData &&
                    shadowData.map((md) => (
                      <>
                        {md.merchant == item.id && (
                          <tr className="text-small">
                            <td key={md.id}>
                              {md.invoiceDate}
                            </td>
                            <td>
                              <Link
                                className="btn text-small"
                                to={"/merchants/invoice/" + md.id}
                              >
                                BR/{md.orderId}
                              </Link>
                            </td>
                            <td >
                              {md.merchantBranch.area}
                            </td>
                            <td className="pl-3 pr-3 text-center">{formatter.format(md.total)}</td>
                            <td className="pl-3 pr-3 text-center">{md.totalItems}</td>
                            <td className="pl-3 pr-3 text-center">{formatter.format(md.returnTotalAmount)}</td>
                            <td className="pl-3 pr-3 text-center">{md.returnTotal}</td>
                            <td className="pl-3 pr-3 text-center">{md.returnReason}</td>
                            <td className="pl-3 pr-3 text-center">{md.returnPercintage}%</td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          ))}
      </div>
    </>
  );
}
