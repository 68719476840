import { collection, deleteDoc, doc, getDocs, orderBy, query } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Barcode } from "react-thermal-printer";

export const MerchantProducts = ({
  database,
  setLoading,
  language,
  usersData,
}) => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);

  useEffect(() => {
    setLoading(true);
    let temp_arr = [];
    setLoading(true);
    getDocs(
      query(
        collection(database, "merchants-products"),
        //orderBy("timestamp", "desc")
      )
    )
      .then((querySnapshot) => {
        setLoading(false);
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            name: doc.data().title_ar,
            barcode: doc.data().barcode,
            priceList: doc.data().priceList,
            timestamp: doc.data().timestamp,
          });
        });
        console.log(temp_arr);
        setData(temp_arr);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [update]);


  const handleDelete = (item) => {
    setLoading(true);
    let r = window.confirm("Are you sure?");
    if (r) {
      deleteDoc(doc(database, "merchants-products", item)).then(() => {
        setLoading(false);
        setUpdate(Date.now());
      });
    }
  };

  useEffect(() => {
    if (data) {
      let mywindow = document.getElementById("ipd").contentWindow.document;

      mywindow.open();

      mywindow.write(
        '<html dir="' +
          language["dir"] +
          '"><head><title>' +
          language["products"] +
          '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 15px">'
      );

      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );

      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"><h1>' +
          language["products-list"] +
          "</h1></div>"
      );

      mywindow.write(
        `<br/><table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>${language["title"]}</td><td>${language["title"]}</td><td>${language["price"]}</td><td>Barcode</td></tr>`
      );

      let num = 1
      data.map((item) => {
        mywindow.write(`<tr><td>${num}</td><td>${item.title}</td><td>`)
        {item.priceList && item.priceList.map((p) => (
          mywindow.write(`${p.title} - ${p.price} `)
        ))}
        mywindow.write(`</td><td>${item.barcode}</td></tr>`)
        //mywindow.write(`<td>${<Barcode value={item.barcode} />}</td>`);
        //mywindow.write('<td><script type="text/javascript">new QRCode(document.getElementById("qrcode'+num+'"), {text: "' + item.barcode +'", width: 128, height: 128});</script><div id="qrcode'+num+'" style="width: 30%"></div></td></tr>')
        num++
      });

      mywindow.write("</table>");
      //mywindow.write(document.getElementById('pid').innerHTML);

      mywindow.close();
    }
  }, [data]);

  const printProducts = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  return (
    <div className="row">
      <div className="col-12 stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">{language["products-list"]}</h4>
            <Link
              to="/merchants/products/new"
              type="button"
              className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
            >
              <i className="mdi mdi-plus"></i> {language["new"]}
            </Link>

            <button
              className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
              onClick={printProducts}
            >
              <i className="mdi mdi-print"></i> {language["print"]}
            </button>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="col-6">{language["title"]}</th>
                  <th className="col-3">{language["price"]}</th>
                  {/* <th>{language["status"]}</th> */}
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data && data.map(item => <tr>
                <td className="col-6">{item.title}</td>
                <td className="col-3">
                  {(item.priceList && item.priceList.length !== 0) && item.priceList.map((price) => (<div className="row"><div className="d-inline badge badge-success text-wrap m-2" key={'item-'+price.id}>
                    <i className="fa fa-bill"></i> {price.title} (OMR {price.price})
                  </div>
                  </div>))}
                </td>
                <td className="col-3">
                  <Link
                    className="btn btn-success m-2"
                    to={"/merchants/products/edit/" + item.id}
                  >
                    <i className="mdi mdi-pen"></i> {language["edit"]}
                  </Link>
                  <button
                    className="btn btn-danger m-2"
                    onClick={() => handleDelete(item.id)}
                  >
                    <i className="mdi mdi-delete"></i> {language["delete"]}
                  </button>
                </td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
