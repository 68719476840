import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken } from "firebase/messaging";
import { query, getDoc, doc, getFirestore } from "firebase/firestore/lite";
import "bootstrap/dist/css/bootstrap.min.css";

import Nav from "./template/Nav";
import Sidebar from "./template/Sidebar";
import Footer from "./template/Footer";

import locale from "./data/Locale";
import Home from "./pages/Home";
import { useState, useEffect } from "react";
import ProductsList from "./pages/Products";
import Orders from "./pages/Orders";
import Order from "./pages/Order";
import Categories from "./pages/Categories";
import Newproduct from "./pages/Newproduct";
import Newcategory from "./pages/Newcategory";
import Editcategory from "./pages/Editcategory";
import EditProduct from "./pages/EditProduct";
import Slideshow from "./pages/Slideshow";
import Newslideshow from "./pages/Newslideshow";
import Login from "./pages/Login";
import Search from "./pages/Search";
import Information from "./pages/Information";
import Cites from "./pages/Cites";
import Newcity from "./pages/Newcity";
import Reports from "./pages/Reports";
import Inventory from "./pages/Inventory";
import Subscriptions from "./pages/Subscriptions";
import EditCity from "./pages/EditCity";
import Branches from "./pages/Branches";
import NewBranch from "./pages/NewBranch";
import EditBranch from "./pages/EditBranch";
import Newsletter from "./pages/Newsletter";
import NewLetter from "./pages/NewLetter";
import NewOrder from "./pages/NewOrder";
import Appslideshow from "./pages/Appslideshow";
import Newappslideshow from "./pages/Newappslideshow";
import Notifications from "./pages/Notifications";
import Promocodes from "./pages/Promocodes";
import NewCode from "./pages/Newcode";
import Newrevenue from "./pages/Newrevenue";
import Expenses from "./pages/Expenses";
import Newexpnese from "./pages/Newexpense";
import Bill from "./pages/Bill";
import Expense from "./pages/Expense";
import ShopReport from "./pages/ShopReport";
import ProductsReport from "./pages/ProductsReport";
import ImportCSV from "./pages/ImportCSV";
import Notification from "./pages/Notification";
import Tasks from "./pages/Tasks";
import Task from "./pages/Task";
import Merchants from "./pages/Merchants";
import NewMerchant from "./pages/NewMerchant";
import { Merchant } from "./pages/Merchant";
import Summary from "./pages/Summary";
import { Invoice } from "./pages/Invoice";
import NewInvoice from "./pages/NewInvoice";
import { MerchantProducts } from "./pages/MerchantProducts";
import NewMerchantProduct from "./pages/NewMerchantProduct";
import EditMerchant from "./pages/EditMerchant";
import EditMerchantProduct from "./pages/EditMerchantProduct";
import MerchantBranches from "./pages/MerchantBranches";
import NewMerchantBranch from "./pages/NewMerchantBranch";
import Talabat from "./pages/Talabat";
import NewTalabat from "./pages/NewTalabat";

const App = () => {
  const [language, setLanguage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [usersData, setUderData] = useState(false);
  const [user, setUser] = useState(null);
  let navigate = useNavigate();
  let location = useLocation();

  const firebaseConfig = {
    apiKey: "AIzaSyDpzVXjdrvE0JWJIiABsPJrbUXL3hkO7h4",
    authDomain: "bothoor-5f147.firebaseapp.com",
    projectId: "bothoor-5f147",
    storageBucket: "bothoor-5f147.appspot.com",
    messagingSenderId: "577660267877",
    appId: "1:577660267877:web:62ec2a5033d74663e5058c",
    measurementId: "G-86C2MRZK48",
  };

  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const auth = getAuth(firebaseApp);
  const database = getFirestore(firebaseApp);
  const storage = getStorage(firebaseApp);
  const messaging = getMessaging(firebaseApp);

  getToken(messaging, {
    vapidKey:
      "BApQdacxO8jeoggCN6rViKRqmtC2Htcm-TLUpV1zvddI41feLKWyDJkf4TItn86shyyd3YZryvwQP00XIdBlZB8",
  })
    .then((res) => {
      fetch("https://iid.googleapis.com/iid/v1/" + res + "/rel/topics/all", {
        method: "POST",
        headers: new Headers({
          Authorization:
            "key=AAAAhn88LWU:APA91bGwiaHKoCq2Bd5VwXBmxwUTEbseqcISVdvEmoVM-YrTEL5Ko14HQOMPR3rhIfL_h65TnW5vJz0E1-3nyoewtYsC58xpOR7akGC1VE-cabvxLfeY6x6valudjB7XLhrDy2Bh8Mga",
        }),
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            //throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
          }
          //console.log('Subscribed to "all"');
        })
        .catch((error) => {
          //console.error(error);
        });
      //console.log(res);
    })
    .catch((err) => {
      /*console.log(err)*/
    });

  const handleLanguage = (lang) => {
    if (lang === "arabic") {
      setLanguage(locale[1]);
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      localStorage.setItem("language", "arabic");
    } else {
      setLanguage(locale[0]);
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      localStorage.setItem("language", "english");
    }
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
        console.log("erro9r");
      });
  };

  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang !== null) {
      if (locale) {
        if (lang === "arabic") {
          setLanguage(locale[1]);
          document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
        } else {
          setLanguage(locale[0]);
          document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
        }
      }
    } else {
      if (locale) {
        setLanguage(locale[0]);
      }
    }
  }, []);

  useEffect(() => {
    setLoading2(true);
    onAuthStateChanged(auth, (userData) => {
      setUser(userData);
      if (userData) {
        getDoc(query(doc(database, "admins", userData.uid)))
          .then((querySnapshot) => {
            if (querySnapshot.data()) {
              setUderData(querySnapshot.data());
              if (
                window.localStorage.getItem("branch") === null ||
                window.localStorage.getItem("branch") === ""
              ) {
                window.localStorage.setItem(
                  "branch",
                  querySnapshot.data().branch
                );
              }
            } else {
              logout();
            }
          })
          .catch((err) => setLoading2(false));
      }
      setLoading2(false);
    });
  }, [user]);

  if (loading2) {
    return (
      <div className="loading">
        <img
          className="img"
          src="/loading.gif"
          alt="buthoor"
        />
      </div>
    );
  }

  if (!user) {
    return <Login language={language} auth={auth} setUser={setUser} />;
  }

  if (user) {
    return (
      <div className="container-scroller">
        <Nav
          language={language}
          handleLanguage={handleLanguage}
          logout={logout}
          navigate={navigate}
          usersData={usersData}
          database={database}
        />
        <div className="container-fluid page-body-wrapper">
          <Sidebar language={language} logout={logout} usersData={usersData} />
          <div className="main-panel">
            <div className="content-wrapper">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                      language={language}
                      database={database}
                      user={user}
                      auth={auth}
                      setUser={setUser}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="orders/:list"
                  element={
                    <Orders
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="finance/expenses"
                  element={
                    <Expenses
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="newsletter"
                  element={
                    <Newsletter
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="order/:order_item"
                  element={
                    <Order
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="finance/revenue/:order_item"
                  element={
                    <Bill
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="finance/expenses/:expense_item"
                  element={
                    <Expense
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="finance/expenses/:order_item"
                  element={
                    <Expense
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="orders/import"
                  element={
                    <ImportCSV
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="products/:list"
                  element={
                    <ProductsList
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="search/:term"
                  element={
                    <Search
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="reports"
                  element={
                    <Reports
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="reports/shop"
                  element={
                    <ShopReport
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="reports/products"
                  element={
                    <ProductsReport
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="slideshow"
                  element={
                    <Slideshow
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="appslideshow"
                  element={
                    <Appslideshow
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="categories"
                  element={
                    <Categories
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="cites"
                  element={
                    <Cites
                      language={language}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                    />
                  }
                />
                <Route
                  path="newproduct"
                  element={
                    <Newproduct
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="neworder"
                  element={
                    <NewOrder
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="newrevenue"
                  element={
                    <Newrevenue
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="newexpenses"
                  element={
                    <Newexpnese
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="newslideshow"
                  element={
                    <Newslideshow
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="newappslideshow"
                  element={
                    <Newappslideshow
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="newcategory"
                  element={
                    <Newcategory
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="newcity"
                  element={
                    <Newcity
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="newcode"
                  element={
                    <NewCode
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="new-newsletter"
                  element={
                    <NewLetter
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="editcategory/:category"
                  element={
                    <Editcategory
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="editproducts/:product"
                  element={
                    <EditProduct
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="information"
                  element={
                    <Information
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="inventory/:item"
                  element={
                    <Inventory
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="subscriptions"
                  element={
                    <Subscriptions
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="cites/:city"
                  element={
                    <EditCity
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="branches"
                  element={
                    <Branches
                      usersData={usersData}
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="branches/edit/:item"
                  element={
                    <EditBranch
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="branches/new"
                  element={
                    <NewBranch
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="merchants"
                  element={
                    <Merchants
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/invoice/new/:id"
                  element={
                    <NewInvoice
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/summary/"
                  element={
                    <Summary
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/new/"
                  element={
                    <NewMerchant
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/edit/:id"
                  element={
                    <EditMerchant
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/products"
                  element={
                    <MerchantProducts
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/products/new"
                  element={
                    <NewMerchantProduct
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/products/edit/:id"
                  element={
                    <EditMerchantProduct
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/branches/:id"
                  element={
                    <MerchantBranches
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/branches/new/:id"
                  element={
                    <NewMerchantBranch
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/:id"
                  element={
                    <Merchant
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="merchants/invoice/:id"
                  element={
                    <Invoice
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="talabat"
                  element={
                    <Talabat
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="talabat/new"
                  element={
                    <NewTalabat
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="tasks"
                  element={
                    <Tasks
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                      usersData={usersData}
                    />
                  }
                />
                <Route
                  path="tasks/new"
                  element={
                    <Task
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="tasks/:item"
                  element={
                    <Task
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="notifications"
                  element={
                    <Notifications
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="notifications/new"
                  element={
                    <Notification
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
                <Route
                  path="promocode"
                  element={
                    <Promocodes
                      usersData={usersData}
                      language={language}
                      navigate={navigate}
                      database={database}
                      user={user}
                      setLoading={setLoading}
                      storage={storage}
                    />
                  }
                />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
};

export default App;
