import { createRef, useEffect, useState } from "react";
import {
  query,
  addDoc,
  collection,
  getDocs,
  getDoc,
  doc,
  serverTimestamp,
  where,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes } from "firebase/storage";

const Newexpnese = ({ language, database, storage }) => {
  const [amount, setAmount] = useState("");
  const [desc, setDesc] = useState("");
  const [dMsg, setDMsg] = useState(null);
  const [rdate, setDate] = useState("");
  const [rType, setType] = useState("rent");
  
  const [selectedImages, setSelectedImages] = useState(null);
  const [imagesNames, setImageNames] = useState([]);
  const [image, setImage] = useState("");
  const [uploadImages, setUploadedImages] = useState(null)

  const formData = createRef();

  const values = [
    "rent",
    "salary",
    "gov-expenses",
    "bank-charges",
    "water-electricity",
    "repairs-maintenance",
    "printing-stationery",
    "mobile-phone",
    "cleaning-utenils",
    "car-instalments",
    "car-maintenance",
    "advertising-expenses",
    "fuel-expenses",
    "sadaqa",
    "app-commotions",
    "packaging-expenses",
    "assets-materials-equipments",
    "meeting-fees",
    "sandwiches",
    "drinking-water",
    "stickers",
    "fruits-vegetables",
    "furnitures",
    "entertainment-gist",
    "other"
  ]

  const handleNewExpense = async (op) => {
    setDMsg("")
    let branch = window.localStorage.getItem("branch");
    let enable = false;
    let uploaded = true;

    if (amount !== "") {
      enable = true;
    } else {
      setDMsg(language["required-error-msg"]);
    }

    if(uploadImages){
      
      for (let index = 0; index < uploadImages.length; index++) {
        const img = uploadImages[index];        
        const imageRef = ref(storage, "data/" + imagesNames[index]);
        await uploadBytes(imageRef, img) //, metadata)
        .then((snapshot) => {
          //getDownloadURL(snapshot.ref).then((url) => { });
        })
        .catch((error) => {
          setDMsg(language["error-msg"]);
          uploaded = false
          // ...
        });
      }
    }

    if (enable === true && uploaded == true) {
      addDoc(collection(database, "expenses"), {
        orderId: Date.now(),
        type: rType,
        userId: 8965656,
        desc: desc,
        enable: true,
        date: rdate,
        amount: parseFloat(amount),
        image: image,
        images: imagesNames,
        branch: branch,
        timestamp: serverTimestamp(),
      })
        .then((res) => {
          //
          if (op === "new") {
            window.location.reload();
          } else {
            window.location.href = "/finance/expenses";
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleUpload = (file) => {
    const imagesList = [];
    const imagesNamesList = [];

    for (let index = 0; index < file.target.files.length; index++) {
      let img = null;
      try {
        img = URL.createObjectURL(file.target.files[index])
        let imgName = Date.now().toString(36) + Math.random().toString(36).substring(2) + ".jpg";
        imagesList.push(img)
        imagesNamesList.push(imgName)

      } catch (error) {
        img = null
      }
    }

    setSelectedImages(imagesList)
    setImageNames(imagesNamesList)
    setUploadedImages(file.target.files)
    
  };

  return (
    <div className="row">
      <div className="col-12 stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">{language["new"]}</h4>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="order-type">{language["type"]}</label>
                <select
                  name="order-type"
                  className="form-control"
                  onChange={(val) => setType(val.target.value)}
                >
                  {values && values.map((item, index) => <option key={index+"ops"} value={item}>{language[item]}</option>)}
                </select>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["amount"]}</label>
                <input
                  type="number"
                  min={0}
                  id="amount"
                  name="amount"
                  placeholder={language["amount"]}
                  className="form-control"
                  onChange={(val) => setAmount(val.target.value)}
                />
              </div>

              <div className="col-12 mt-3 mb-3">
                <label htmlFor="name">{language["describtion"]}</label>
                <textarea
                  placeholder={language["describtion"]}
                  className="form-control"
                  onChange={(val) => setDesc(val.target.value)}
                ></textarea>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["date"]}</label>
                <input
                  type="date"
                  min={0}
                  id="date"
                  name="amount"
                  placeholder={language["date"]}
                  className="form-control"
                  onChange={(val) => setDate(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="receipt">{language["receipt-photo"]}</label>
                <input
                  type="file"
                  name="receipt"
                  id="receipt"
                  accept="image/*"
                  onChange={handleUpload}
                  className="form-control"
                  placeholder="Upload Picture"
                  multiple
                />
              </div>
            </div>
            <div className="row mt-3 p-3">
              {selectedImages && selectedImages.map((img, index) => <img key={"img-891721-"+index} src={img} className="thumbnail col-2 m-1" />)}
            </div>

            <p>{dMsg}</p>
            <div className="text-center mt-5">
              <button className="btn btn-success m-2" onClick={handleNewExpense}>
                {language["save"]}
              </button>
              <button
                className="btn btn-success m-2"
                onClick={() => handleNewExpense("new")}
              >
                {language["save-new"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newexpnese;
