import { useEffect, useState } from "react";
import {
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
  where,
  limit,
} from "firebase/firestore/lite";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-multi-date-picker";

export const Merchant = ({ database, setLoading, language, usersData }) => {
  const { id } = useParams();
  const MyDate = new Date();
  const MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    "01";
  const lastDay = new Date(MyDate.getFullYear(), MyDate.getMonth() + 1, 0);
  const toDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
  lastDay.getDate();

  const [data, setData] = useState(null);
  const [shadowData, setShadowData] = useState(null);
  const [update, setUpdate] = useState(null);

  const [fromDate, setFromDate] = useState(MyDateString)
  const [toDate, setToDate] = useState(toDateString)
  const [csv, setCsv] = useState('')
  const formatter = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "omr",
  });

  useEffect(() => {
    getOrders();
  }, [update]);

  const getOrders = () => {
    let selectedNextDay = new Date(toDate)
    let nextDay = new Date(selectedNextDay)
    nextDay.setDate(selectedNextDay.getDate() + 1)

    let temp_arr = [];
    let temp_csv = "";
    setLoading(true);

    getDocs(
      query(
        collection(database, "merchant-orders"),
      where("invoiceDate", ">=", fromDate), where("invoiceDate", "<=", nextDay.toLocaleDateString('en-CA')),
      where("merchant", "==", id),
      orderBy("invoiceDate", "desc")
    )
    )
/*
    getDocs(
      query(
        collection(database, "merchant-orders"),
        
        where("timestamp", ">=", new Date(fromDate + ' 00:00:00')), where("timestamp", "<=", nextDay),
        where("merchant", "==", id),
        orderBy("timestamp", "desc")
      )
    )
    */
      .then((querySnapshot) => {
        //lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            timestamp: doc.data().timestamp,
            total: doc.data().total,
            items: doc.data().items,
            merchantBranch: doc.data().merchantBranch,
            orderId: doc.data().orderId,
            vat: doc.data().vat,
            lpo: doc.data().lpo,
            date: doc.data().date,
            invoiceDate: doc.data().invoiceDate,
          });
        });
        setData(temp_arr);
        setShadowData(temp_arr);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const handleDelete = (item) => {
    setLoading(true);
    let r = window.confirm("Are you sure?");
    if (r) {
      deleteDoc(doc(database, "merchant-orders", item)).then(() => {
        setLoading(false);
        setUpdate(Date.now());
      });
    }
  };

  useEffect(() => {
    let temp_csv = ''
    if (data) {
      let mywindow = document.getElementById("ipd").contentWindow.document;

      mywindow.open();

      mywindow.write(
        '<html><head><title>' +language["orders"] +'</title></head><body>'
      );

      mywindow.write(
        '<img src="/assets/images/header.jpg" style="width: 100%" />'
      );

      mywindow.write(
        `<h3><u>${language['invoices']}: ${fromDate} ~ ${toDate}</u></h3>`
      );

      mywindow.write(
        `<table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>No.</td><td>LPO Date</td><td>Ref. No.</td><td>LPO</td><td>${language["branch"]}</td><td>${language["total"]} (OMR)</td></tr>`
      );
      temp_csv = `Ref. No., ${language["total"]} (OMR), ${language["branch"]}, LPO, ${language["date"]} \n`;

      let tAmount = 0

      data.map((item, index) => {
        temp_csv += `BR/${item.orderId},${item.total},${item.merchantBranch.area}, ${item.lpo}, ${item.date}\n`;
        
        let total = 0
        item.items.map(p => {
          total += (p.price * (p.qty - (p.cqty?parseInt(p.cqty):0)) + ((p.qty - (p.cqty?parseInt(p.cqty):0)) * (p.price * (item.vat / 100))))
        })

        tAmount += total;

        mywindow.write(
          `<tr>
          <td>${(index+1)}</td>
          <td>${item.date}</td>
          <td>BR/${item.orderId}</td>
          <td>${item.lpo}</td>
          <td>${item.merchantBranch.area}</td>
          <td>${formatter.format(total)}</td>
          </tr>`
        );
      });

      mywindow.write(`<tr><td></td><td></td><td></td><td></td><td><b>${language['total']}</b></td><td><b>${formatter.format(tAmount)}</b></td></tr>`)

      mywindow.write("</table>");

      mywindow.close();
      setCsv(temp_csv)
    }
  }, [data]);

  const handlePrint = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };



  const handleDownload = () => {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", fromDate + '-' + toDate  + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

  }

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["invoices"]}</h4>
          <Link
            to={"/merchants/invoice/new/" + id}
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="fa fa-plus"></i> {language["new"]}
          </Link>
          <Link
            to={"/merchants/branches/" + id}
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="fa fa-building"></i> {language["branches"]}
          </Link>
          <Link
            to={"/merchants/edit/" + id}
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="fa fa-edit"></i> {language["edit"]} {language['merchant']}
          </Link>
          <DatePicker
            range
            defaultValue={fromDate + ' ~ ' + toDate}
            value={fromDate + ' ~ ' + toDate}
            onChange={(val) => {
              try {
                setFromDate(val[0].year +"-" + ("0" + (val[0].month)).slice(-2) + "-" + ("0" + (val[0].day)).slice(-2))
                setToDate(val[1].year +"-" + ("0" + (val[1].month)).slice(-2) + "-" + ("0" + (val[1].day)).slice(-2))
              } catch (err) {
                console.log(err);
              }
            }}
            placeholder={language["select-day"]}
            //minDate={new Date()}
            className="green"
            style={{ padding: 18 }}
          />
          <button
            onClick={getOrders}
            className="m-2 btn btn-success"
            type="button"
          >
            {language["show"]}
          </button>
          {/* <button className="m-2 btn btn-secondary" type="button" onClick={() => handlePrint("all", "profit")}>{language['print']}</button>
          <button onClick={handleDownload} className="m-2 btn btn-info" type="button">{language['download']}</button> */}
          <div className="d-inline ">
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {language["export"]}
            </button>
            <div className="dropdown-menu">
              <div
                role="button"
                className="dropdown-item"
                onClick={() => handlePrint("all", "profit")}
              >
                <i className="fa fa-print"></i> {language["print"]}
              </div>
              <div
                role="button"
                className="dropdown-item"
                onClick={handleDownload}
              >
                <i className="fa fa-file"></i> {language["download"]}
              </div>
            </div>
          </div>

          <hr />

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["total"]}</th>
                <th>{language["date"]}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">
                      <Link
                        className="btn"
                        to={"/merchants/invoice/" + item.id}
                      >
                        <i className="fa fa-file"></i> BR/{item.orderId} - {item.merchantBranch.area?item.merchantBranch.area:''}
                      </Link>
                    </td>
                    <td className="col">{item.total}</td>
                    <td className="col">
                      {item.invoiceDate}
                    </td>

                    <td className="col">
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-sm btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
