import { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
  orderBy,
} from "firebase/firestore/lite";
import { Link } from "react-router-dom";

export default function Branches({
  database,
  setLoading,
  language,
  usersData,
}) {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);

  useEffect(() => {
    setLoading(true);
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "branches")))
      .then((querySnapshot) => {
        setLoading(false);
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
            enable: doc.data().enable,
            sort: doc.data().sort,
            date: doc.data().date,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [update]);

  const handleDelete = (item) => {
    setLoading(true);
    let r = window.confirm(
      "Are you sure?, this may effect/brake other part of website you can just disable this branch, if proceed update delivery places related to this branch after this action"
    );
    if (r) {
      deleteDoc(doc(database, "branches", item)).then(() => {
        setLoading(false);
        setUpdate(Date.now());
      });
    }
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["branches"]}</h4>
          {usersData && usersData.userType === "admin" && (
            <Link
              to="/branches/new"
              type="button"
              className="btn btn-success btn-rounded btn-fw"
            >
              <i className="mdi mdi-plus"></i> {language["new"]}
            </Link>
          )}
          {/* <p className="card-description"> {language[""]}</p> */}

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["date"]}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">
                      {language["dir"] === "rtl" ? item.title_ar : item.title}
                    </td>
                    <td className="col">{item.date}</td>
                    <td>
                      {usersData && usersData.userType === "admin" && (
                        <Link
                          className="btn btn-success m-2"
                          to={"/branches/edit/" + item.id}
                        >
                          <i className="mdi mdi-pen"></i> {language["edit"]}
                        </Link>
                      )}
                    </td>
                    <td className="col">
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>{" "}
                          {language["delete"]}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
