import { addDoc, collection, doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Task({
  database,
  user,
  setLoading,
  navigate,
  language,
}) {
  const { item } = useParams();  
  const [title, setTitle] = useState("");
  const [stage, setStage] = useState("todo");
  const [updated, setUpdate] = useState(Date.now());
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("now");
  const [date, setDate] = useState('');
  const [taskTitle, setTaskTile] = useState('')
  const [list, setList] = useState(null)

  useEffect(() => {
    setLoading(true);
    if(item){
      getDoc(doc(database, "tasks", item))
      .then((querySnapshot) => {
        setTaskTile(querySnapshot.data().title)
        setList(querySnapshot.data().list);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
    }
  }, [updated])

  const addTask = () => {
    setMsg(" ");
    if (title === "") {
      setMsg(language['required-error-msg']);
    } else {
      if(!item){
        let firstTask = []
        if(title){
          firstTask.push({
            title: title,
            stage: status === 'due'?stage:'',
            due: date
          })
        }
        addDoc(collection(database, "tasks"), {
          title: taskTitle,
          list: firstTask,
          timestamp: serverTimestamp()
        })
        .then(res => {
          window.location.href = '/tasks/'+res.id
        })
        .catch((err) => {
          setMsg(language["error-msg"]);
        })
      } else {
        let temp_arr = list;
        temp_arr.push({
          title: title,
          stage: status === 'due'?stage:'',
          due: date
        })
        updateDoc(doc(database, 'tasks', item), {
          title: taskTitle,
          list: temp_arr,
          timestamp: serverTimestamp()
        })
        .then(res => {
          setTitle(' ')
          setUpdate(Date.now())
        })
        .catch((err) => {
          setMsg(language["error-msg"]);
        })
      }
    }
  };

  const deleteTask = taskId => {
    let tempArr = list;
    let r = window.confirm(language['confirm'])
    if(r){
      tempArr.splice(taskId, 1)
      updateDoc(doc(database, 'tasks', item), {
        list: tempArr,
      })
      .then(res => {
        setUpdate(Date.now())
      })
      .catch((err) => {
        setMsg(language["error-msg"]);
      })
    }
  }
  
  const updateTask = (index, taskStage, checkbox) => {
    console.log(index, taskStage, checkbox);
    let tempArr = list;
    if(checkbox){
      if(taskStage === 'done'){
        tempArr[index].stage = 'todo'
      } else {
        tempArr[index].stage = 'done'
      }
    } else {
      tempArr[index].stage = taskStage
    }

    updateDoc(doc(database, 'tasks', item), {
      list: tempArr,
    })
    .then(res => {
      setUpdate(Date.now())
      setList(tempArr)
    })
    .catch((err) => {
      setMsg(language["error-msg"]);
    })
  }

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <p className="card-description">{language["tasks"]}</p>
          <input defaultValue={taskTitle} className="border-0 border-bottom fs-5 mb-3 col-12" onChange={val => setTaskTile(val.target.value)} placeholder={`${language['tasks']} ${language['title']}`} />
          <div className="row">
            <div className="input-group">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control col-6"
                placeholder={language["describtion"]}
                value={title}
              />
              <select className="form-control " onChange={val => setStage(val.target.value)}>
                <option value="todo">{language['todo']}</option>
                <option value="doing">{language['doing']}</option>
                <option value="done">{language['done']}</option>
              </select>
              <select className="form-control " onChange={val => setStatus(val.target.value)}>
                <option value="now">{language['date']}</option>
                <option value="due">{language['due']}</option>
              </select>
              {status === 'due' && <>
              <input
                type="date"
                onChange={(val) => setDate(val.target.value)}
                className="form-control"
                defaultValue={date}
                placeholder={language["date"]}
              />
              </>}
              <button className="btn btn-success" onClick={addTask}>
                {language["add"]}
              </button>
            </div> 
            <div className="p-3 text-danger">{msg}</div>
          </div>

          <ul className="list-group">
            <li className="list-group-item">
              <div className="row">
                <div className="col-6">
                  {language['title']}
                </div>
                <div className="col-2">
                  {language['due']}
                </div>
              </div>
            </li>
            {list && list.map((taskItem, index) => <li className="list-group-item" key={`mytask-${index}`}>
              <label htmlFor={`mytask-${index}`} className="row">
                <div className="col-6 p-2">
                  <input type="checkbox" defaultChecked={taskItem.stage == 'done'?true:false} id={`mytask-${index}`} onChange={val => updateTask(index, taskItem.stage, 'checkbox')} /> <span>{taskItem.stage === 'done'?<del>{taskItem.title}</del>:taskItem.title}</span>
                </div>
                <div className="col-2 p-2">
                  {taskItem.due}
                </div>
                <div className="col-3">
                  <select className="form-control" onChange={val => updateTask(index, val.target.value)} defaultValue={taskItem.stage}>
                    <option value="todo">{language['todo']}</option>
                    <option value="doing">{language['doing']}</option>
                    <option value="done">{language['done']}</option>
                  </select>
                </div>
                <div className="col">
                  <button
                    className="btn btn-sm border m-2"
                    onClick={() => deleteTask(index)}
                  >
                    <i className="mdi mdi-delete"></i>                  </button>
                </div>

              </label>
            </li>)}
          </ul>
        </div>
      </div>
    </div>
  );
}
