import { useEffect, useState } from "react";
import { collection, addDoc } from "firebase/firestore/lite";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Newcategory = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [image, setImage] = useState(Date.now() + ".jpg");
  const [msg, setMesg] = useState("");

  const addProduct = () => {
    if (title === "" || title_ar === "") {
      setMesg("Please fill all the required feilds...");
    } else {
      addDoc(collection(database, "categories"), {
        title: title,
        title_ar: title_ar,
        image: image,
        enable: true,
        slug: title.replace(" ", "-"),
        sort: 0,
      }).then((res) => {
        navigate("/categories");
      });
    }
  };

  const handleUpload = (file) => {
    const imageRef = ref(storage, "images/" + image);
    uploadBytes(imageRef, file.target.files[0]) //, metadata)
      .then((snapshot) => {
        // console.log('Uploaded', snapshot.totalBytes, 'bytes.');
        //console.log('File metadata:', snapshot.metadata);
        // Let's get a download URL for the file.
        getDownloadURL(snapshot.ref).then((url) => {
          //console.log('File available at', url);
        });
      })
      .catch((error) => {
        console.error("Upload failed", error);
      });
  };

  useEffect(() => {
    setImage(Date.now() + ".jpg");
  }, []);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["new"]}</h4>
          <p className="card-description">{language["categories"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            <div className="p-3 text-danger">{msg}</div>
            <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={handleUpload}
                className="form-control"
                placeholder="Upload Picture"
              />
            </div>
          </form>

          <button className="btn m-3 btn-success" onClick={addProduct}>
            {language["add"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newcategory;
