import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import { Link } from "react-router-dom";

export default function Talabat({ database, setLoading, language, usersData }) {
  const MyDate = new Date();
  const MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    "01";
  const lastDay = new Date(MyDate.getFullYear(), MyDate.getMonth() + 1, 0);
  const toDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    lastDay.getDate();

  const [data, setData] = useState(null);
  const [shadowData, setShadowData] = useState(null);
  const [update, setUpdate] = useState(null);

  const [fromDate, setFromDate] = useState(MyDateString);
  const [toDate, setToDate] = useState(toDateString);
  const [csv, setCsv] = useState("");
  const formatter = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "omr",
  });

  useEffect(() => {
    getOrders();
  }, [update]);

  const getOrders = () => {
    let selectedNextDay = new Date(toDate);
    let nextDay = new Date(selectedNextDay);
    nextDay.setDate(selectedNextDay.getDate() + 1);

    let temp_arr = [];
    let temp_csv = "";
    setLoading(true);

    getDocs(
      query(
        collection(database, "talabat-orders"),
        where("date", ">=", fromDate),
        where("date", "<=", nextDay.toLocaleDateString("en-CA")),
        orderBy("date", "desc")
      )
    )
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            total: doc.data().total,
            date: doc.data().date,
            branch: doc.data().branch,
            timestamp: doc.data().timestamp,
          });
        });
        setData(temp_arr);
        setShadowData(temp_arr);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const handleDelete = (item) => {
    setLoading(true);
    let r = window.confirm("Are you sure?");
    if (r) {
      deleteDoc(doc(database, "talabat-orders", item)).then(() => {
        setLoading(false);
        setUpdate(Date.now());
      });
    }
  };

  useEffect(() => {
    let temp_csv = ''
    if (data) {
      let mywindow = document.getElementById("ipd").contentWindow.document;

      mywindow.open();

      mywindow.write(
        '<html><head><title>' +language["orders"] +'</title></head><body>'
      );

      mywindow.write(
        '<img src="/assets/images/header.jpg" style="width: 100%" />'
      );

      mywindow.write(
        `<h3><u>Talabat: ${fromDate} ~ ${toDate}</u></h3>`
      );

      temp_csv = `${language["branch"]}, ${language["date"]} (OMR), ${language["total"]} \n`;

      mywindow.write(
        `<table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>${language["branch"]}</td><<td>${language["date"]}</td><td>${language["total"]} (OMR)</td></tr>`
      );
        
      let total = 0;
      data.map((item, index) => {
        temp_csv += `${item.branch},${item.date},${item.total}\n`;

        mywindow.write(
          `<tr>
          <td>${item.branch}</td>
          <td>BR/${item.date}</td>
          <td>${formatter.format(item.total)}</td>
          </tr>`
        );
        total = total + parseFloat(item.total);
      });
      mywindow.write(`<tr><td></td><td><b>${language['total']}</b></td><td><b>${formatter.format(total)}</b></td></tr>`)

      mywindow.write("</table>");

      mywindow.close();
      setCsv(temp_csv)
    }
  }, [data]);

  const handlePrint = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  const handleDownload = () => {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", fromDate + "-" + toDate + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Talabat</h4>
          <Link
            to={"/talabat/new/"}
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="fa fa-plus"></i> {language["new"]}
          </Link>

          <DatePicker
            range
            defaultValue={fromDate + " ~ " + toDate}
            value={fromDate + " ~ " + toDate}
            onChange={(val) => {
              try {
                setFromDate(
                  val[0].year +
                    "-" +
                    ("0" + val[0].month).slice(-2) +
                    "-" +
                    ("0" + val[0].day).slice(-2)
                );
                setToDate(
                  val[1].year +
                    "-" +
                    ("0" + val[1].month).slice(-2) +
                    "-" +
                    ("0" + val[1].day).slice(-2)
                );
              } catch (err) {
                console.log(err);
              }
            }}
            placeholder={language["select-day"]}
            //minDate={new Date()}
            className="green"
            style={{ padding: 18 }}
          />
          <button
            onClick={getOrders}
            className="m-2 btn btn-success"
            type="button"
          >
            {language["show"]}
          </button>
          <div className="d-inline ">
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {language["export"]}
            </button>
            <div className="dropdown-menu">
              <div
                role="button"
                className="dropdown-item"
                onClick={() => handlePrint("all", "profit")}
              >
                <i className="fa fa-print"></i> {language["print"]}
              </div>
              <div
                role="button"
                className="dropdown-item"
                onClick={handleDownload}
              >
                <i className="fa fa-file"></i> {language["download"]}
              </div>
            </div>
          </div>

          <hr />
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["branch"]}</th>
                <th>{language["date"]}</th>
                <th>{language["total"]}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">{item.branch}</td>
                    <td className="col">{item.date}</td>
                    <td className="col">{formatter.format(item.total)}</td>

                    <td className="col">
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-sm btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
