import { useState } from "react";
import { getDoc, query, updateDoc, doc } from "firebase/firestore/lite";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export default function EditBranch({ database, user, setLoading, navigate, language }) {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [enable, setEnable] = useState(true);
  const [msg, setMsg] = useState("");
  const { item } = useParams();

  useEffect(() => {
    setLoading(true);
    getDoc(query(doc(database, "branches", item)))
      .then((querySnapshot) => {
        setTitle(querySnapshot.data().title);
        setTitleAr(querySnapshot.data().title_ar);
      })
      .catch((err) => console.log(err));
  }, []);

  const updateBranch = () => {
    if (title === "" || title_ar === "") {
      setMsg("Please fill all the required fields...");
    } else {
      updateDoc(doc(database, "branches", item), {
        title: title,
        title_ar: title_ar,
        enable: enable,
      }).then((res) => {
        navigate("/branches");
      });
    }
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["new"]}</h4>
          <p className="card-description">{language["categories"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
                value={title_ar}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
                value={title}
              />
            </div>
            <div className=" m-3">
              <label htmlFor="">{language["available"]}</label> <br />
              <select
                name="category"
                value={enable}
                className="form-control"
                onChange={(val) => setEnable(val.target.value)}
                defaultValue={enable}
              >
                <option key={"enable1"} value={true}>
                  {language["available"]}
                </option>
                <option key={"enable2"} value={false}>
                  {language["unavailable"]}
                </option>
              </select>
            </div>
            <div className="p-3 text-danger">{msg}</div>
          </form>

          <button className="btn m-3 btn-success" onClick={updateBranch}>
            {language["update"]}
          </button>
        </div>
      </div>
    </div>
  );
}
