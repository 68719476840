import { doc, getDoc, query, updateDoc } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToWords } from "to-words";

export const Invoice = ({ database, setLoading, language, usersData }) => {
  const MyDate = new Date();
  const MyDateString =  MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ('0' + MyDate.getDate()).slice(-2);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [info, setInfo] = useState(null);
  const [show, setShow] = useState(false);
  const [cqty, setCQty] = useState(0);
  const [reason, setReason] = useState("");
  const [updated, setUpdated] = useState(Date.now());
  const [branchInfo, setBranchInfo] = useState(null);
  const [optionsList, setOptionsList] = useState(null);
  const [productsList, setProductsList] = useState(null);
  const [product, setProduct] = useState("");
  const [qty, setQty] = useState(null);
  const [returnDate, setReturnDate] = useState(MyDateString)
  const [returnReason, setReturnReason] = useState("");
  const [total, setTotal] = useState(0);
  const [vatTotal, setVatTotal] = useState(0);
  const [totalReturned, setTotalReturned] = useState(0)
  const [totalReturnedNo, setTotalReturnedNo] = useState(0)
  const [msg, setMesg] = useState("");

  const formatter = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "omr",
  });

  useEffect(() => {
    getDoc(query(doc(database, "merchant-orders", id)))
      .then((doc) => {

        setData(doc.data());
        setMerchant(doc.data().merchant);
        setBranchInfo(doc.data().merchantBranch);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, [updated]);

  useEffect(() => {
    if(data){
      let vatTotal_tmp = 0;
      let returnTotal = 0;
      let returnedNo = 0;
      let total_tmp = 0;

      data.items.map(i => {
        vatTotal_tmp = vatTotal_tmp + (((parseFloat(i.price) * parseInt(i.qty))) * ((parseInt(data.vat) / 100)));

        if(i.cqty){
          returnedNo = returnedNo + parseInt(i.cqty);
          returnTotal = returnTotal + (i.price * parseInt(i.cqty) + parseInt(i.cqty) * (i.price * (data.vat / 100)));
        }
      })

      total_tmp = data.total - returnTotal;
      setVatTotal(vatTotal_tmp);
      setTotalReturned(returnTotal);
      setTotalReturnedNo(returnedNo);
      setTotal(total_tmp);
    }
  }, [data])
  

  useEffect(() => {
    if (merchant) {
      getDoc(query(doc(database, "merchents", merchant)))
        .then((doc) => {
          setInfo(doc.data());
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [merchant]);

  useEffect(() => {
    if (info) {
      loadPrint();
    }
  }, [info]);

  const loadPrint = () => {
    let mywindow = document.getElementById("ipd").contentWindow.document;
    let content = "";

    if (data) {
      let total = convertNumToWord(data.total); //getCurrency(data.total);

      content = `<html>
        <head>
          <title>Buthoor - Invoice</title>
          <style>
          @media print { 
            body {
              padding: 0;
              margin: 0;
            }
            .footer {
              position: absolute;
              bottom: 0;
            }

            .full {
              display: block;
              width: 100%;

            }

            h3 {
              display: block;
              text-align: center;
            }

            .col-3 {
              display: inline-block;
              width: 48%;
            }

            .col-4 {
              display: inline-block;
              width: 30%;
            }

            .col-5 {
              display: inline-block;
              width: 10%;
            }

            .col-6 {
              display: inline-block;
              width: 5%;
            }

            .border {
              padding: 5px;
              margin: 3px;
              border: 1px solid #000;
            }

            .inline-list {
              display: block; 
              width: 100%; 
              margin: 0 !important;
              padding: 0 !important;
              margin-top: 15px;
            }

            .inline-list .inline-item {
              display: inline-block; 
              margin: 0 !important; 
              padding: 3px !important; 
              border: 1px solid #000;
              vertical-align: top;
              font-size: 14px;
              font-weight: bold;
            }
          }
          </style>
        </head>
        <body>
          <img src="/assets/images/header.jpg" class="full" />
          <h3>INVOICE</h3>
          <div class="row">
            <div class="col-3"> Ref No. : BR/${data.orderId}</div>
            <div class="col-3"> Date: ${
              data.invoiceDate ? data.invoiceDate : new Date().toDateString()
            }</div>
          </div>
          <div class="border">
            <p><u><b>Consignee Address:</b></u></p>
            <div class="row">
              <div class="col-3">${info.title}</div>
              <div class="col-3">LPO NO: ${data.lpo}</div>

              <div class="col-3">Branch: ${
                branchInfo ? branchInfo.area : ""
              }</div>
              <div class="col-3">Date.: ${data.date}</div>

              <div class="col-3">${branchInfo ? branchInfo.area : ""}</div>
              <div class="col-3">VAT: ${info.vatin ? info.vatin : ""}</div>

              <div class="col-3">Mob: ${
                branchInfo ? branchInfo.mobile : ""
              }</div>
            </div>
          </div>

          <br/>
          <br/>

          <div class="inline-list">
            <div class="inline-item col-6">S. No</div>
            <div class="inline-item col-4">Particulars</div>
            <div class="inline-item col-5">Qty</div>
            <div class="inline-item col-5">Unit Rate</div>
            <div class="inline-item col-5">Vat 5%</div>
            <div class="inline-item col-5">Amount</div>
            <div class="inline-item col-5">Total Rate</div>
          </div>`;

      data.items.map((item, index) => {
        content += `<div class="inline-list">
        <div class="inline-item col-6">${index + 1}</div>
        <div class="inline-item col-4">${item.title}</div>
        <div class="inline-item col-5">${item.qty}</div>
        <div class="inline-item col-5">${item.price}</div>
        <div class="inline-item col-5">${(
          item.price *
          (data.vat / 100)
        ).toFixed(3)}</div>
        <div class="inline-item col-5">${(item.qty * item.price).toFixed(
          3
        )}</div>
        <div class="inline-item col-5">${(
          item.price * (item.qty - (item.cqty ? parseInt(item.cqty) : 0)) +
          (item.qty - (item.cqty ? parseInt(item.cqty) : 0)) *
            (item.price * (data.vat / 100))
        ).toFixed(3)}</div>
      </div>`;
      });

      content += `<div class="inline-list">
      <div class="inline-item" style="width: 71.7%; color: #ffffff;">.</div>
      <div class="inline-item col-5">${language["total-vat"]}</div>
      <div class="inline-item col-5">${formatter.format(vatTotal)}</div>
    </div>`;

      content += `<div class="inline-list">
        <div class="inline-item" style="width: 71.7%; color: #ffffff;">.</div>
        <div class="inline-item col-5">${language["total"]}</div>
        <div class="inline-item col-5">${formatter.format(data.total)}</div>
      </div>`;

      content += `</br>
          <p>Amount in Words: ${total}</p>
          <p>Payment Terms:  Net 30 days from the date of Delivery</p>

          <br/>
          <br/>

          <p>
            <b>
            Account Details: <br/>
            Beneficiary Name: AL ZIMAM TRADING & PROJECT MANAGEMENT <br />
            Account No: 10650136183001 <br/>
            Bank Name: NATIONAL BANK OF OMAN <br/>
            Swift Code: NBOMORX <br/>
            </b>
          </p>
          
          <div class="row">
            <div class="col-3">Prepared By</div>
            <div class="col-3">Acknowledged By</div>
            <br/><br/>
            <div class="col-3"><b>BUTHOOR MART</b><br/><b>VATINOM1100283005</b></div>
            <div class="col-3">Seal & Date</div>
          </div
          <img src="/assets/images/footer.jpg" class="full footer" />
        </body>
      </html>`;

      mywindow.write(content);
      mywindow.close();
    }
  };

  const convertNumToWord = (num2Con) => {
    if (num2Con === 0) return "Zero";
    const toWords = new ToWords({
      localeCode: "en-US",
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: true,
        doNotAddOnly: false,
        currencyOptions: {
          // can be used to override defaults for the selected locale
          name: "Rial",
          plural: "Rials",
          symbol: "OMR",
          fractionalUnit: {
            name: "Bisa",
            plural: "Bisas",
            symbol: "",
          },
        },
      },
    });

    return toWords.convert(num2Con, { ignoreZeroCurrency: true });
  };

  function getCurrency(num) {
    let decones = [
      { "01": "One" },
      { "02": "Two" },
      { "03": "Three" },
      { "04": "Four" },
      { "05": "Five" },
      { "06": "Six" },
      { "07": "Seven" },
      { "08": "Eight" },
      { "09": "Nine" },
      { 10: "Ten" },
      { 11: "Eleven" },
      { 12: "Twelve" },
      { 13: "Thirteen" },
      { 14: "Fourteen" },
      { 15: "Fifteen" },
      { 16: "Sixteen" },
      { 17: "Seventeen" },
      { 18: "Eighteen" },
      { 19: "Nineteen" },
    ];
    let ones = [
      { 0: " " },
      { 1: "One" },
      { 2: "Two" },
      { 3: "Three" },
      { 4: "Four" },
      { 5: "Five" },
      { 6: "Six" },
      { 7: "Seven" },
      { 8: "Eight" },
      { 9: "Nine" },
      { 10: "Ten" },
      { 11: "Eleven" },
      { 12: "Twelve" },
      { 13: "Thirteen" },
      { 14: "Fourteen" },
      { 15: "Fifteen" },
      { 16: "Sixteen" },
      { 17: "Seventeen" },
      { 18: "Eighteen" },
      { 19: "Nineteen" },
    ];
    let tens = [
      { 0: "" },
      { 2: "Twenty" },
      { 3: "Thirty" },
      { 4: "Forty" },
      { 5: "Fifty" },
      { 6: "Sixty" },
      { 7: "Seventy" },
      { 8: "Eighty" },
      { 9: "Ninety" },
    ];
    let hundreds = [
      "Hundred",
      "Thousand",
      "Million",
      "Billion",
      "Trillion",
      "Quadrillion",
    ];

    let num_arr = num.toFixed(3).split(".");
    let wholenum = num_arr[0];
    let decnum = num_arr[1];
    let whole_arr = wholenum.split(",").reverse(); //array_reverse(explode(",",$wholenum));
    whole_arr.sort();

    let rettxt = "";
    whole_arr.map((key, i) => {
      if (i < 20) {
        rettxt += ones[i];
      } else if (i < 100) {
        rettxt += tens[tens.splice(0, 1, i)];
        rettxt += " " + ones[ones.splice(1, 1, i)];
      } else {
        rettxt += ones[ones.splice(i, 0, 1)] + " " + hundreds[0];
        rettxt += " " + tens[tens.splice(1, 1, i)];
        rettxt += " " + ones[ones.splice(2, 1, i)];
      }
      if (key > 0) {
        rettxt += " " + hundreds[key] + " ";
      }
    });

    rettxt = rettxt + " Rial";

    if (decnum > 0) {
      rettxt += " and ";
      if (decnum < 20) {
        rettxt += decones[parseInt(decnum)];
      } else if (decnum < 100) {
        rettxt += tens[tens.splice(0, 1, decnum)];
        rettxt += " " + ones[ones.splice(decnum, 1, 1)];
      } else {
        rettxt += ones[ones.splice(0, 1, decnum)] + " " + hundreds[0];
        rettxt += " " + tens[tens.splice(1, 1, decnum)];
        rettxt += " " + ones[ones.splice(2, 1, decnum)];
      }
      rettxt = rettxt + " Baiza";
    }
    return rettxt;
  }

  const printOrder = () => {
    //setOrderPrintDate(printDate);
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  const handleRemoveItem = (item) => {
    let r = window.confirm("Are you sure?");
    if (r && cqty !== 0) {
      let temp_arr = data.items;
      let new_item = data.items.findIndex((val) => val.id === item);

      temp_arr[new_item].enable = !temp_arr[new_item].enable;
      temp_arr[new_item].cqty = cqty;
      temp_arr[new_item].reason = reason;

      updateDoc(doc(database, "merchant-orders", id), {
        items: temp_arr,
      })
        .then((res) => {
          setUpdated(Date.now());
          setCQty(0);
        })
        .catch((err) => {
          setCQty(0);
          //console.log(error)
        });
    }
  };

  const whatsapp = () => {
    let mob = branchInfo ? branchInfo.mobile : "";
    window.open("https://wa.me/968" + mob, "system");
  };

  const handleSearchProduct = (e) => {
    let term = e.target.value;
    let temp_options = [];

    if (term.length > 3) {
      setProduct(term);

      productsList.forEach((item) => {
        try {
          if (
            item.title.toLowerCase().includes(term) ||
            item.title_ar.includes(term)
          ) {
            temp_options.push(item);
          }
        } catch {
          //console.log(doc.data());
        }
      });

      setOptionsList(temp_options);
    }
  };

  const handleReturn = () => {
    let temp_arr = data.items;
    let items_index = temp_arr.findIndex(item => item.title.toLowerCase() == product.toLowerCase() || item.title_ar == product)
    temp_arr[items_index].cqty = qty
    temp_arr[items_index].returnDate = returnDate
    temp_arr[items_index].returnReason = returnReason

    updateDoc(doc(database, "merchant-orders", id), {
      items: temp_arr
    })
    .then(res => setUpdated(Date.now()))
    .catch(err => setMesg(language['error-msg']))
  }

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          {data && (
            <>
              <strong className="fs-4">Ref No. : BR/{data.orderId}</strong>{" "}
              <br />
              <small>
                {data.invoiceDate
                  ? data.invoiceDate
                  : data.timestamp.toDate().toDateString() +
                    " - " +
                    data.timestamp.toDate().toLocaleTimeString()}{" "}
              </small>
              <br />
              <br />
              {info && (<div className="row">
                <div className="col-6 row">
                  <div className="col-6">
                    <b>LPO</b>
                  </div>
                  <div className="col-6">{data.lpo}</div>

                  <div className="col-6">
                    <b>LPO {language["date"]}</b>
                  </div>
                  <div className="col-6">{data.date}</div>

                  <div className="col-6">
                    <b>{language["agent"]}</b>
                  </div>
                  <div className="col-6">
                    {branchInfo ? branchInfo.name : ""}
                  </div>

                  <div className="col-6">
                    <b>{language["merchant"]}</b>
                  </div>
                  <div className="col-6">{info.title}</div>

                  <div className="col-6">
                    <b>{language["mobile"]}</b>
                  </div>
                  <div className="col-6">
                    {branchInfo ? branchInfo.mobile : ""}
                  </div>

                  <div className="col-6">
                    <b>{language["branch"]}</b>
                  </div>
                  <div className="col-6">
                    {branchInfo ? branchInfo.area : ""}
                  </div>

                  <div className="col-6">
                    <b>VTIN</b>
                  </div>
                  <div className="col-6">{info.vatin}</div>
                </div>
                <div className="col-6 row">
                  <div className="col-6">
                    <b>{language["return"]}</b>
                  </div>
                  <div className="col-6">{totalReturnedNo}</div>
                  <div className="col-6">
                    <b>{language["return"]} {language['total']}</b>
                  </div>
                  <div className="col-6">{formatter.format(totalReturned)}</div>
                  <div className="col-6">
                    <b>{language["total-vat"]}</b>
                  </div>
                  <div className="col-6">{formatter.format(vatTotal)}</div>
                  <div className="col-6">
                    <b>Total before return</b>
                  </div>
                  <div className="col-6">{formatter.format(data.total)}</div>
                  <div className="col-6">
                    <b>{language["total"]}</b>
                  </div>
                  {/* <div className="col-6">{formatter.format(data.total)}</div> */}
                  <div className="col-6">{formatter.format(total)}</div>
                </div>
                </div>
              )}
              <br />
            </>
          )}

          <div className="template-demo">
            <button
              type="button"
              onClick={printOrder}
              className="btn btn-sm btn-info btn-rounded btn-fw"
            >
              <i className="fa fa-print"></i> {language["print"]}
            </button>
            <button
              type="button"
              onClick={whatsapp}
              className="btn btn-sm btn-success btn-rounded btn-fw"
            >
              <i className="fa fab fa-whatsapp"></i> Whatsapp
            </button>
          </div>

          <hr />
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["qty"]}</th>
                <th>{language["price"]}</th>
                <th>VAT ({data && data.vat}%)</th>
                <th>{language["sub-total"]}</th>
                <th>{language["total"]}</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.items &&
                data.items.map((item) => (
                  <tr key={"order-item-" + item.id}>
                    <td>
                      {!item.enable ? (
                        <i className="fa fa-undo m-2"></i>
                      ) : (
                        <i className="fa fa-check m-2"></i>
                      )}
                      {language["language"] === "english"
                        ? item.title
                        : item.title_ar}
                    </td>
                    <td>{item.qty}</td>
                    <td>{formatter.format(item.price)}</td>

                    <td>
                      {formatter.format(
                        item.qty * item.price * (data.vat / 100)
                      )}
                    </td>
                    <td>
                      {formatter.format(
                        item.price * item.qty
                      )}
                    </td>
                    <td>
                      {formatter.format(
                        item.price * item.qty + item.qty *
                          //(item.qty - (item.cqty ? parseInt(item.cqty) : 0)) +
                          //(item.qty - (item.cqty ? parseInt(item.cqty) : 0)) *
                            (item.price * (data.vat / 100))
                      )}
                    </td>
                    {usersData && usersData.userType === "admin" && show && (
                      <td>
                        {item.enable && (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => handleRemoveItem(item.id)}
                          >
                            <i className="fa fa-check"></i> {language["done"]}
                          </button>
                        )}

                        {!item.enable && (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => handleRemoveItem(item.id)}
                          >
                            <i className="fa fa-undo"></i>
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>

          <hr />
          <h3>{language["return"].toUpperCase()}</h3>
          <div className="row">
            <div className="col-4">
              {/* <label htmlFor="item-title">{language['title']}</label> */}
              <input
                type="text"
                list="products-list"
                id="item-title"
                placeholder={language["title"]}
                className="form-control"
                onChange={val => setProduct(val.target.value)}
              />
              <datalist
                name="products-list"
                id="products-list"
                onClick={(e) => console.log(e)}
              >
                {data &&
                  data.items &&
                  data.items.map((item) => (
                    <option key={item.title} value={item.title}>
                      {language["dir"] === "rtl" ? item.title_ar : item.title}
                    </option>
                  ))}
              </datalist>
            </div>

            <div className="col-2">
              {/* <label htmlFor="item-qty">{language['qty']}</label> */}
              <input
                value={qty}
                type="number"
                id="item-qty"
                min="1"
                placeholder={language["qty"]}
                className="form-control"
                onChange={(val) => setQty(val.target.value)}
              />
            </div>

            <div className="col-2">
              {/* <label htmlFor="item-qty">{language['qty']}</label> */}
              <input
                value={returnDate}
                type="date"
                id="item-qty"
                className="form-control"
                onChange={(val) => setReturnDate(val.target.value)}
              />
            </div>
          
            <div className="col-3">
              {/* <label htmlFor="item-qty">{language['qty']}</label> */}
              <input
                value={returnReason}
                type="text"
                id="item-returnReason"
                className="form-control"
                placeholder={language && language['cancel-reason']}
                onChange={(val) => setReturnReason(val.target.value)}
              />
            </div>

            <div className="col-6 align-top">
              <button
                className="btn btn-success align-top"
                onClick={handleReturn}
              >
                <i className="fa fa-plus"></i> {language["add"]}
              </button>
            </div>
            <p>{msg}</p>
          </div>


          <hr />

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["cancel-reason"]}</th>
                <th>{language["qty"]}</th>
                <th>{language["price"]}</th>
                <th>VAT ({data && data.vat}%)</th>
                <th>{language["total"]}</th>
                <th>{language["date"]}</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.items &&
                data.items.map((item) => (item.cqty && item.cqty != 0) &&
                  <tr key={"order-item-" + item.id}>
                    <td>
                      {!item.enable ? (
                        <i className="fa fa-undo m-2"></i>
                      ) : (
                        <i className="fa fa-check m-2"></i>
                      )}
                      {language["language"] === "english"
                        ? item.title
                        : item.title_ar}
                    </td>
                    <td>{item.returnReason}</td>
                    <td>{item.cqty}</td>
                    <td>{formatter.format(item.price)}</td>

                    <td>
                      {formatter.format(
                        item.cqty * item.price * (data.vat / 100)
                      )}
                    </td>
                    <td>
                      {formatter.format(
                        item.price * item.cqty + item.cqty * (item.price * (data.vat / 100))
                      )}
                    </td>
                    <td>
                      {item.returnDate}
                    </td>
                  </tr>
                )}
                <h4 className="p-2">{language['total']}: {formatter.format(totalReturned)}</h4>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
