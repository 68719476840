import { useState } from "react";
import {
  collection,
  addDoc,
  getDocs,
  query,
  serverTimestamp,
} from "firebase/firestore/lite";
import { useEffect } from "react";

const NewCode = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [branch, setBranch] = useState("");
  const [validation_date, setValidationDate] = useState("");
  const [msg, setMesg] = useState("");
  const [code, setCode] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [enable, setEnable] = useState(1);
  const [amount, setAmount] = useState(0);
  const [data, setData] = useState(null);

  const addCode = () => {
    if (title === "" || title_ar === "") {
      setMesg(language["required-error-msg"]);
    } else {
      addDoc(collection(database, "promos"), {
        title: title,
        title_ar: title_ar,
        code: code,
        validation_date: validation_date,
        date: serverTimestamp(),
        branch: branch,
        limit: limit,
        enable: enable === 1 ? true : false,
        amount: amount,
      })
        .then((res) => {
          navigate("/promocode");
        })
        .catch((err) => {
          console.log(err);
          setMesg(language["error-msg"]);
        });
    }
  };

  useEffect(() => {
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "branches")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["add"]}</h4>
          <p className="card-description">{language["promocode"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setCode(val.target.value)}
                className="form-control "
                placeholder="Code"
              />
            </div>
            <div className="input-group m-3">
              <input
                type="date"
                onChange={(val) => setValidationDate(val.target.value)}
                className="form-control "
                placeholder="date"
              />
            </div>
            <div className="input-group m-3">
              <input
                type="number"
                min={0}
                onChange={(val) => setAmount(val.target.value)}
                className="form-control "
                placeholder={language["amount"] + " (%)"}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="number"
                min={0}
                onChange={(val) => setLimit(val.target.value)}
                className="form-control "
                placeholder={language["qty-limit"] + ", Default 1000"}
              />
            </div>
            <div className="input-group m-3">
              <select
                name="branch"
                className="form-control"
                onChange={(val) => setBranch(val.target.value)}
              >
                <option value="all">
                  {language["branches"] + " (" + language["all"] + ")"}
                </option>
                {data &&
                  data.map((item) => (
                    <option key={item.id} value={item.id}>
                      {language["language"] === "english"
                        ? item.title
                        : item.title_ar}
                    </option>
                  ))}
              </select>
            </div>
            <div className=" m-3">
              <select
                name="category"
                value={enable}
                className="form-control"
                onChange={(val) => setEnable(val.target.value)}
                defaultValue={enable}
              >
                <option key={"enable1"} value={1}>
                  {language["available"]}
                </option>
                <option key={"enable2"} value={0}>
                  {language["unavailable"]}
                </option>
              </select>
            </div>
            <div className="p-3 text-danger">{msg}</div>
          </form>

          <button className="btn m-3 btn-success" onClick={addCode}>
            {language["add"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewCode;
