import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  deleteDoc,
  startAfter,
} from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const Expenses = ({ database, setLoading, user, language, usersData }) => {
  const [data, setData] = useState(null);
  const [csv, setCsv] = useState("");
  const [update, setUpdate] = useState(null);
  const { list } = useParams();
  let lastVisible = null;

  const getOrders = () => {
    let temp_arr = [];
    let q = null;
    let temp_csv = ""
    let branch = window.localStorage.getItem("branch");
    setLoading(true);
    q = getDocs(
      query(
        collection(database, "expenses"), 
        //where("branch", "==", branch), 
        orderBy("timestamp", "desc"),
        limit(10)
      )
    )
    q.then((querySnapshot) => {
      lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      querySnapshot.forEach((doc) => {
        temp_arr.push({
          id: doc.id,
          orderId: doc.data().orderId,
          type: doc.data().type,
          image: doc.data().image,
          canceled: doc.data().canceled,
          amount: parseFloat(doc.data().amount).toFixed(3),
          enable: doc.data().enable,
          desc: doc.data().desc,
          timestamp: doc.data().timestamp,
          branch: doc.data().branch,
        });

        temp_csv +=
          doc.data().orderId +
          "," +
          doc.data().type +
          "," +
          parseFloat(doc.data().amount).toFixed(3) +
          "," +
          doc.data().desc +
          "," +
          "\n";
      });
      setCsv(temp_csv)
      setData(temp_arr);
      setLoading(false);
    }).catch((err) => setLoading(false));
  };

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      deleteDoc(doc(database, "expenses", item)).then(() => {
        setUpdate(Date.now());
      });
    }
  };

  useEffect(() => {
    getOrders();
    window.onscroll = function (ev) {
      var pageHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.innerHeight + window.scrollY >= pageHeight) {
        let temp_arr = [];
        let next = null;
        let branch = window.localStorage.getItem("branch");
        if (lastVisible) {
          next = getDocs(
            query(
              collection(database, "expenses"),
              //where("branch", "==", branch),
              orderBy("timestamp", "desc"),
              startAfter(lastVisible),
              limit(10)
            )
          );
        }

        if (lastVisible) {
          let temp_csv = csv
          setLoading(true);
          next
            .then((querySnapshot) => {
              lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
              if (querySnapshot.docs) {
                setLoading(false);
                querySnapshot.forEach((doc) => {
                  //temp_arr.push({
                  setData((ol) => [
                    ...ol,
                    {
                      id: doc.id,
                      orderId: doc.data().orderId,
                      type: doc.data().type,
                      image: doc.data().image,
                      amount: parseFloat(doc.data().amount),
                      enable: doc.data().enable,
                      note: doc.data().note,
                      timestamp: doc.data().timestamp,
                      branch: doc.data().branch,
                    },
                  ]);


                  temp_csv +=
                  doc.data().orderId +
                  "," +
                  doc.data().type +
                  "," +
                  parseFloat(doc.data().amount).toFixed(3) +
                  "," +
                  doc.data().desc +
                  "," +
                  "\n";

                })
                
                setCsv(temp_csv)
              }
            })
            .catch((err) => setLoading(false));
        }
      }
    };
  }, [update]);

  function download(filename, text) {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", language["expenses-list"] + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const handlePrint = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  useEffect(() => {
    if (data) {
      let mywindow = document.getElementById("ipd").contentWindow.document

      mywindow.open()

      mywindow.write(
        '<html dir="' + language["dir"] + '"><head><title>' + language["expenses"] + '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 15px">'
      )

      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      )

      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"><h1>' +
          language["expenses-list"] +
          "</h1></div>"
      )

      mywindow.write(
        `<br/><table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>${language["type"]}</td><td>${language["amount"]}</td><td>${language["describtion"]}</td><td>${language["date"]}</td><td></td></tr>`
      )

      data.map((item) => {
        let odate = item.timestamp.toDate().toLocaleDateString()
        mywindow.write(
          `<tr><td>${language[item.type]}</td><td>${item.amount}</td><td>${item.desc}</td><td>${item.date}</td><td>${odate}</td></tr>`
        )
      })

      mywindow.write("</table></body>")
      //mywindow.write(document.getElementById('pid').innerHTML);

      mywindow.close()
    }
  }, [data])

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["expenses"]}</h4>
          <Link
            to="/newexpenses"
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw"
          >
            <i className="mdi mdi-plus"></i> {language["new"]}
          </Link>

          <button
            onClick={handlePrint}
            type="button"
            className="btn btn-success btn-rounded btn-sm m-2 btn-fw"
          >
            <i className="fa fa-print"></i> {language["print"]}
          </button>

          {usersData && usersData.userType === "admin" && (
            <button
              onClick={download}
              type="button"
              className="btn btn-success btn-sm btn-rounded m-2 btn-fw"
            >
              <i className="mdi mdi-file-excel"></i> {language["download"]}
            </button>
          )}
          
          <p className="card-description"> {language["expenses-list"]}</p>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["amount"]}</th>
                <th></th>
                <th>{language["date"]}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">{language[item.type]}</td>
                    <td className="col">{item.amount}</td>
                    <td>
                      {item.canceled === true && (
                        <label className="badge badge-danger">
                          {language["cancel"]}
                        </label>
                      )}
                    </td>
                    <td className="col-4">
                      {item.timestamp.toDate().toLocaleDateString() +
                        " - " +
                        item.timestamp.toDate().toLocaleTimeString()}
                    </td>
                    <td className="col">
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-sm btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>{" "}
                          {language["delete"]}
                        </button>
                      )}
                    </td>
                    <td className="col">
                      <Link
                        className="btn btn-sm btn-success"
                        to={"/finance/expenses/" + item.id}
                      >
                        <i className="mdi mdi-eye"></i> {language["view"]}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

export default Expenses;
