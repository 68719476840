import { useEffect } from "react";
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  getDoc,
  getDocs,
  collection,
  query,
  orderBy,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
  where,
  limit,
} from "firebase/firestore/lite";

export default function Inventory({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) {
  const { item } = useParams();
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState(null);
  const [bulk, setBulk] = useState(0);
  const [pkg, setPackage] = useState(1);
  const [rate, setRate] = useState(50);
  const [talabatRate, setTalabatRate] = useState(80);
  const [price, setPrice] = useState(0);
  const [interest, setInterest] = useState(0);
  const [talabat, setTalabat] = useState(0);
  const [weight, setWeight] = useState(0);
  const [total, setTotal] = useState(0);
  const [timeCost, setTimeCost] = useState(0.025);
  const [packCost, setPackCost] = useState(0.030);
  const [gross, setGross] = useState(0);
  const [talabatGross, setTalabatGross] = useState(0);
  const [rateProfit, setRateProfit] = useState(0);
  const [qty, setQty] = useState(0);
  const [pqty, setPQty] = useState(0);
  const [csv, setCsv] = useState("");

  const [show, setShow] = useState(false);

  useEffect(() => {
    //return
    let temp_arr = []
    let temp_csv = "Bulk,KG/PC,Weight,Packing,Total,Talabat,Selling,Gross,Profit\n";
    setLoading(true);
    getDocs(
      query(
        collection(database, "inventory"),
        where("product", "==", item),
        limit(10)
      )
    )
      .then(docs => {
        setLoading(false);
        docs.forEach(item => {
          temp_arr.push({
            id: item.id,
            ...item.data()
          })

          temp_csv += `${item.bulk}, ${item.package},${item.weight},${item.packCost},${item.total},${item.talabat},${item.price},${item.gross},${item.rateProfit}%\n`;

        })
        setData(temp_arr);
        setCsv(temp_csv);
        
      })
      .catch((err) => setLoading(false));

    getDoc(query(doc(database, "products", item)))
      .then((doc) => {
        setProductData(doc.data())
        if(doc.data().qty){
          setQty(doc.data().qty)
        }
      })
      .catch((err) => setLoading(false));
  }, []);

  useEffect(() => {
    if (data && productData) {
      let mywindow = document.getElementById("ipd").contentWindow.document;

      mywindow.open();

      mywindow.write(
        "<html><head><title>" +
          productData.title +
          '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 15px">'
      );

      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );

      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"><h3>' +
          productData.title +
          "</h3></div>"
      );

      mywindow.write(
        `<br/><table style="width: 100%; text-align: center" border="1"><tr style="font-weight: bold;"><td>Date</td>
        <td>Bulk/OMR</td>
        <td>KG/PC</td>
        <td>Weight</td>
        <td>Rate</td>
        <td>Talabat Rate</td>
        <td>Package</td>
        <td>Total</td>
        <td>Selling</td>
        <td>Talabat</td>
        <td>Gross</td>
        <td>Talabat Gross</td></tr>`
      )

      data.map((item) => {
        mywindow.write(
          `<tr><td>${item.date}</td>
          <td>${parseFloat(item.bulk).toFixed(3)}</td>
          <td>${item.package}</td>
          <td>${item.weight}</td>
          <td>${item.rate}%</td>
          <td>${item.talabatTate}%</td>
          <td>${item.packCost}</td>
          <td>${item.total}</td>
          <td>${item.price}</td>
          <td>${item.talabat}</td>
          <td>${item.gross}</td>
          <td>${item.talabatGross}</td></tr>`
        )

      });

      mywindow.write("</table>");
      //mywindow.write(document.getElementById('pid').innerHTML);

      mywindow.close();
    }
  }, [data]);

  const printOrder = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  const handleUpdate = () => {
    calculatePrice()
    setLoading(true);

    if (parseFloat(bulk) === 0 || parseFloat(pkg) === 0) {
      return;
    }

    let d = new Date();
    let today = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();

    let newPrice = {
      product: item,
      bulk: bulk,
      package: pkg,
      rate: rate,
      talabatTate: talabatRate,
      price: price,
      //interest: interest,
      gross: gross,
      talabatGross: talabatGross,
      rateProfit: rateProfit,
      weight: weight,
      //timeCost: timeCost,
      packCost: packCost,
      talabat: talabat,
      total: total,
      date: today,
      timestamp: Date.now()
    };

    let tempArray = [...data, newPrice];

    setData(tempArray);
    //return
    updateDoc(doc(database, "products", item), {
      price: price,
      qty: parseInt(pkg)+parseInt(qty),
      talabat: talabat,
      bulk: bulk
    })
      .then((res) => {
        addDoc(collection(database, "inventory"), newPrice)
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const calculatePrice = () => {
    let totalCost = (bulk / weight) + parseFloat(packCost)
    let finalPrice = ((parseInt(rate) / 100) * totalCost) + totalCost
    let TalabatCost = ((parseInt(talabatRate) / 100 ) * totalCost) + totalCost
    let grossTotal = finalPrice - totalCost // finalPrice - (finalPrice * (30 / 100)) - totalCost;
    let talabatGrossTotal = (TalabatCost - totalCost).toFixed(3) // finalPrice - (finalPrice * (30 / 100)) - totalCost;
    let totalRateProfit = Math.round(100 * (grossTotal / finalPrice));
    /*
    let interestAmount = parseFloat(bulk) * (rate / 100);
    let TalabatCost = parseFloat(bulk) / parseInt(weight);
    let totalCost =
      parseFloat(packCost) + parseFloat(timeCost) + parseFloat(TalabatCost);
    let finalPrice = totalCost * (rate / 100) + totalCost;
    */

    setPrice(finalPrice.toFixed(3));
    //setInterest(interestAmount.toFixed(3));
    setTalabat(TalabatCost.toFixed(3));
    setTotal(totalCost.toFixed(3));
    setGross(grossTotal.toFixed(3));
    setRateProfit(totalRateProfit);
    setTalabatGross(talabatGrossTotal)
  };

  function download() {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", productData.title + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            {language["inventory"]}{" "}
            <button
              className="btn btn-success btn-sm"
              onClick={() => setShow(!show)}
            >
              <i className="fa fa-plus"></i> {language["new"]}
            </button>
          </h4>
          {productData && (
            <p className="p-3 fs-4">
              {language["language"] === "arabic"
                ? productData.title_ar
                : productData.title}{" "}
              <br />
              {language["language"] === "arabic"
                ? productData.describtion_ar
                : productData.describtion}{" "}
              <br />
              OMR {productData.price}{" "}
              <strike>
                {productData.old_price ? "(" + productData.old_price + ")" : ""}
              </strike>
            </p>
          )}
          {show && (
            <>
              <hr />
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="bulk" className="pl-3 pr-3 m-0">
                    {language["bulk-price"]}
                  </label>
                  <input
                    type="number"
                    id="bulk"
                    className="input form-control m-3"
                    min={0}
                    value={bulk}
                    placeholder="Bulk Price"
                    onChange={(e) => setBulk(e.target.value)}
                  />
                </div>

                <div className="col-3">
                  <label htmlFor="package" className="pl-3 pr-3 m-0">
                    {language["package"]} ({language["kg-pc"]})
                  </label>{" "}
                  <br />
                  <select id="package" className="form-control m-3" onChange={(e) => setPackage(e.target.value)}>
                    <option value="kg">KG</option>
                    <option value="kg">PC</option>
                  </select>
                </div>

                <div className="col-3">
                  <label htmlFor="weight" className="pl-3 pr-3 m-0">
                    {language["weight"]}
                  </label>
                  <input
                    type="number"
                    id="rate"
                    className="form-control m-3"
                    min={0}
                    placeholder={language["weight"]}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </div>

              </div>

              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="pkgcost" className="pl-3 pr-3 m-0">
                    {language["packaging-cost"]}
                  </label>{" "}
                  <br />
                  <input
                    type="number"
                    id="pkgcost"
                    defaultValue={0.030}
                    className="form-control m-3"
                    min={0}
                    placeholder={language["packaging-cost"]}
                    onChange={(e) => setPackCost(e.target.value)}
                  />
                </div>

                <div className="col-3">
                  <label htmlFor="total" className="pl-3 pr-3 m-0">
                    Total Product Price
                  </label>{" "}
                  <br />
                  <input
                    type="number"
                    id="total"
                    value={total}
                    className="form-control m-3"
                    min={0}
                    placeholder={language["total"]}
                    onChange={(e) => setTotal(e.target.value)}
                  />
                </div>

              </div>

              <div className="row">
                <div className="col-3">
                  <label htmlFor="rate" className="pl-3 pr-3 m-0">
                    {language["rate"]}
                  </label>
                  <input
                    type="number"
                    id="rate"
                    className="form-control m-3"
                    //min={0}
                    placeholder={"Talabat "+language["rate"]}
                    onChange={(e) => setTalabatRate(e.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="talabat" className="pl-3 pr-3 m-0">
                    Talabat Selling Price%
                  </label>
                  <input
                    type="number"
                    id="talabat"
                    value={talabat}
                    className="input form-control m-3"
                    //min={0}
                    placeholder={"Talabat " + language["price"]}
                    onChange={(e) => setTalabat(e.target.value)}
                  />
                </div>

                <div className="col-3">
                  <label htmlFor="interest" className="pl-3 text-bold pr-3 m-0">
                    Talabat Gross Profit:
                  </label>
                  <input
                    type="number"
                    id="interest"
                    className="form-control m-3"
                    min={0}
                    value={talabatGross}
                    placeholder="Talabat Gross Profit (%)"
                    onChange={(e) => setTalabatGross(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">

                <div className="col-3">
                  <label htmlFor="rate" className="pl-3 pr-3 m-0">
                    {language["rate"]}
                  </label>
                  <input
                    type="number"
                    id="rate"
                    className="form-control m-3"
                    min={0}
                    placeholder={language["rate"]}
                    onChange={(e) => setRate(e.target.value)}
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="interest" className="pl-3 text-bold pr-3 m-0">
                    App Selling Price%
                  </label>
                  <input
                    type="number"
                    id="interest"
                    className="form-control m-3"
                    min={0}
                    value={price}
                    placeholder="App Selling Price (%)"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>

                <div className="col-3">
                  <label htmlFor="interest" className="pl-3 text-bold pr-3 m-0">
                    Gross Profit:
                  </label>
                  <input
                    type="number"
                    id="interest"
                    className="form-control m-3"
                    min={0}
                    value={gross}
                    placeholder="interest"
                    onChange={(e) => setGross(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">

                {/* <div className="col-3">
                  <label htmlFor="interest" className="pl-3 text-bold pr-3 m-0">
                  Profit (%)
                  </label>
                  <input
                    type="number"
                    id="interest"
                    className="form-control m-3"
                    min={0}
                    value={rateProfit}
                    placeholder="interest"
                    onChange={(e) => setRateProfit(e.target.value)}
                  />
                </div> */}
              </div>

              <div className="p-3">
                <button
                  className="btn m-2 btn-success"
                  onClick={calculatePrice}
                >
                  {language["calculate"]}
                </button>
                <button
                  className="btn m-2 btn-secondary"
                  onClick={handleUpdate}
                >
                  {language["add"]}
                </button>
              </div>
            </>
          )}
          <hr />

          <h3>
            {language["previous-prices"]}{" "}
            <button
              className="btn btn-sm m-2 btn-secondary"
              onClick={printOrder}
            >
              <i className="fa fa-print"></i> {language["print"]}
            </button>{" "}
            <button className="btn btn-sm m-2 btn-secondary" onClick={download}>
              <i className="fa fa-download"></i> {language["download"]}
            </button>
          </h3>

          <ul className="list-group text-center">
            <li className="list-group-item list-group-flush d-flex justify-content-between align-items-center font-weight-bold text-small">
              <div style={{width: '10%'}}>{language["date"]}</div>
              <div className="col">Bulk/OMR</div>
              <div className="col">KG/PC</div>
              <div className="col">Weight</div>
              <div className="col">Rate</div>
              <div className="col">Talabat Rate</div>
              <div className="col">Package</div>
              {/* <div className="col">Time</div> */}
              <div className="col">Total</div>
              <div className="col">Selling</div>
              <div className="col">Talabat</div>
              <div className="col">Gross</div>
              <div className="col">Talabat Gross</div>
              {/* <div className="col">Profit</div> */}
            </li>
            {data &&
              data.length > 0 &&
              data.map((item) => (
                <li
                  key={item.id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{width: '10%'}}>{item.date}</div>
                  <div className="col">{parseFloat(item.bulk).toFixed(3)}</div>
                  <div className="col">{item.package}</div>
                  <div className="col">{item.weight}</div>
                  <div className="col">{item.rate}%</div>
                  <div className="col">{item.talabatTate}%</div>
                  <div className="col">{item.packCost}</div>
                  {/* <div className="col">{item.timeCost}</div> */}
                  <div className="col">{item.total}</div>
                  <div className="col">{item.price}</div>
                  <div className="col">{item.talabat}</div>
                  <div className="col">{item.gross}</div>
                  <div className="col">{item.talabatGross}</div>
                  {/* <div className="col"><button className=""><fa className="fa-times"></fa></button>></div> */}
                  {/* <div className="col">{item.rateProfit}%</div> */}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
