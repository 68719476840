import {
  collection,
  query,
  getDocs,
  limit,
  doc,
  deleteDoc,
  startAfter,
} from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const Newsletter = ({ database, setLoading, user, language, usersData }) => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [pages, setPAges] = useState(0);
  const { list } = useParams();
  let lastVisible = null;
  const getNewsletter = () => {
    let temp_arr = [];
    let q = null;
    setLoading(true);
    q = getDocs(query(collection(database, "newsletter"), limit(10)));
    q.then((querySnapshot) => {
      lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      querySnapshot.forEach((doc) => {
        temp_arr.push({
          id: doc.id,
          timestamp: doc.data().timestamp,
          title: doc.data().title,
        });
      });
      setData(temp_arr);
      setLoading(false);
    }).catch((err) => setLoading(false));
  };

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      deleteDoc(doc(database, "newsletter", item)).then(() => {
        setUpdate(Date.now());
      });
    }
  };

  useEffect(() => {
    getNewsletter();
    window.onscroll = function (ev) {
      var pageHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.innerHeight + window.scrollY >= pageHeight) {
        let temp_arr = [];
        let next = null;
        let branch = window.localStorage.getItem("branch");
        if (list === "list" && lastVisible) {
          next = getDocs(
            query(
              collection(database, "newsletter"),
              startAfter(lastVisible),
              limit(10)
            )
          );
          setLoading(true);
          next
            .then((querySnapshot) => {
              lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
              if (querySnapshot.docs) {
                setLoading(false);
                querySnapshot.forEach((doc) => {
                  //temp_arr.push({
                  setData((ol) => [
                    ...ol,
                    {
                      id: doc.id,
                      timestamp: doc.data().timestamp,
                      title: doc.data().title,
                    },
                  ]);
                });
              }
            })
            .catch((err) => setLoading(false));
        }
      }
    };
  }, [update]);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            {language["newsletter"]}{" "}
            <Link to="/new-newsletter" className="btn btn-sm btn-success">
              <i className="fa fa-plus"></i> {language["add"]}
            </Link>
          </h4>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["date"]}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    <td className="col-4">
                      {item.date.toDate().toLocaleDateString() +
                        " - " +
                        item.date.toDate().toLocaleTimeString()}
                    </td>
                    <td className="col">
                      <Link
                        className="btn btn-sm btn-success"
                        to={"/newsletter/" + item.id}
                      >
                        <i className="mdi mdi-eye"></i> {language["view"]}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
