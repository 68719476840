import { collection, getDocs, orderBy, query, where } from 'firebase/firestore/lite';
import React, { useEffect, useState } from 'react'

export default function ProductsReport({ language, database }) {
  const MyDate = new Date();
  const MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    "01";
  const lastDay = new Date(MyDate.getFullYear(), MyDate.getMonth() + 1, 0);
  const toDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
  lastDay.getDate();

  const [fromDate, setFromDate] = useState(MyDateString)
  const [toDate, setToDate] = useState(toDateString)
  const [data, setData] = useState(null)
  const [products, setProducts] = useState(null)
  const [orders, setOrders] = useState(null)
  const [updated, setUpdated] = useState(Date.now())
  const [total, setTotal] = useState(0)
  const [totalBulk, setTotalBulk] = useState(0)
  const [rtype, setRtype] = useState('difference')
  const [csv, setCsv] = useState("");

  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'omr',

  })
  
  //formatter.setCurrencySymbol("")

  useEffect(() => {
    setData(null)
    let temp_arr = []
    let product_temp = []
    let orders_temp = []
    let total = 0
    let total_bulk = 0
    let price_diff = 0
    let temp_csv = "Date, Product, Bulk,KG/PC,Weight,Packing,Total,Talabat,Selling,Gross,Profit, Price Difference\n"
    let selectedNextDay = new Date(toDate)
    let nextDay = new Date(selectedNextDay)
    nextDay.setDate(selectedNextDay.getDate() + 1)

    getDocs(query(collection(database, 'products'), orderBy('sort', 'asc')))
    .then(res => {
      res.forEach(doc => {
        product_temp.push({id: doc.id, ...doc.data()})
      })
      setProducts(product_temp)
    })
    .catch(err => {
      //console.log(err)
    })
    
    let lock = 0
    
    if(rtype === 'difference'){ 
    getDocs(query(collection(database, "inventory"), where('timestamp', ">=", new Date(fromDate).getTime()), where('timestamp', "<=", nextDay.getTime()), orderBy('timestamp', 'desc')))
    .then(res => {
      res.forEach((doc, index) => {
          products.map(product => {
            if(doc.data().product === product.id){
              lock = lock + 1
              if(lock === 1){
                price_diff += parseFloat(doc.data().talabat)
              } else if(lock === 2){
                price_diff = parseFloat(doc.data().talabat) - Math.abs(price_diff)

                temp_arr.push({
                  id: doc.id,
                  price_diff: price_diff,
                  title: product.title,
                  ...doc.data()
                })

                total += parseFloat(doc.data().price)
                
                total_bulk += parseFloat(doc.data().bulk)
                
                temp_csv += `${product.title}, ${doc.data().bulk}, ${doc.data().package},${doc.data().weight},${doc.data().packCost},${doc.data().total},${doc.data().talabat},${doc.data().price},${doc.data().gross},${doc.data().rateProfit}%,${price_diff.toFixed(3)}\n`;
              } else if(lock > 2) {
                lock = 0
              }
            }
          })
        
        })
      
      setData(temp_arr)
      setTotal(total.toFixed(3))
      setTotalBulk(total_bulk.toFixed(3))
      setCsv(temp_csv)
    })
    .catch(err => {
      //console.log(err);
    })
    } else if(rtype === 'inventory'){
      setTotal(0)
      setTotalBulk(0)
      setCsv('')
      setData(null)
      let items_arr = []
      
      getDocs(query(collection(database, "inventory"), where('timestamp', ">=", new Date(fromDate).getTime()), where('timestamp', "<=", nextDay.getTime()), orderBy('timestamp', 'desc')))
      .then(res => {
        res.forEach(doc => {
          products.map(product => {
            if(doc.data().product === product.id){
              items_arr.push({...{id: doc.id, title: product.title, title_ar: product.title_ar}, ...doc.data()})

              total += parseFloat(doc.data().price)
                
              total_bulk += parseFloat(doc.data().bulk)
              
              temp_csv += `${doc.data().date},${product.title}, ${doc.data().bulk}, ${doc.data().package},${doc.data().weight},${doc.data().packCost},${doc.data().total},${doc.data().talabat},${doc.data().price},${doc.data().gross},${doc.data().talabtGross},${doc.data().rate}%,${doc.data().talabatTate}%}\n`;
            }
          })
        })
        setTotal(total.toFixed(3))
        setTotalBulk(total_bulk.toFixed(3))
        setCsv(temp_csv)
        setData(items_arr)
      })
      .catch(err => {
        //
      })

    } else {
      getDocs(query(collection(database, 'orders'), where('timestamp', ">=", new Date(fromDate)), where('timestamp', "<=", nextDay), orderBy('timestamp', 'desc')))
      .then(res => {
        res.forEach(doc => {
          products.map(product => {
            let temp_lock = false;
            let waste = 0;
            doc.data().items.map(orderItem =>{
              if(product.id === orderItem.id && (product.qty && product.qty !== 0)){
                temp_lock = true;
                let product_qty = product.qty?product.qty:0;
                waste = waste + Math.abs(parseInt(product_qty) - parseInt(orderItem.qty))
              }
            })
            if(temp_lock){
              orders_temp.push({...{id: product.id, waste: waste}, ...product})
            }
          });
        })
        setData(orders_temp)
        setCsv(temp_csv)
      })
      .catch(err => {
        //console.log(err)
      })
    }
    

  }, [updated])

  useEffect(() => {
    if (data) {
      let mywindow = document.getElementById("ipd").contentWindow.document;

      mywindow.open();

      mywindow.write(
        "<html><head><title>" + language['products'] + ' - ' + language['reports'] + '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 15px">'
      );

      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );

      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"><h3>' +
          language['products'] + ' - ' + language['reports'] + ': ' + fromDate + ' ~ ' + toDate +"</h3></div>"
      );

      if(rtype === 'waste'){
        mywindow.write(
          `<tr>
            <td>Date<td>
            <td>Title<td>
            <td>Waste<td>
          </tr>`
        )
      } else {
        mywindow.write(
          `<br/><table style="width: 100%; text-align: center" border="1"><tr style="font-weight: bold;">
          <td>Date</td>
          <td>Title</td>
          <td>Bulk/OMR</td>
          <td>KG/PC</td>
          <td>Weight</td>
          <td>Rate</td>
          <td>Talabat Rate</td>
          <td>Package</td>
          <td>Total</td>
          <td>Selling</td>
          <td>Talabat</td>
          <td>Gross</td>
          <td>Talabat Gross</td>
          ${rtype === 'difference'?`<td>Price Difference</td>`:''}
          </tr>`
        )
      }

      data.map(item => {
        if(rtype === 'waste'){
          mywindow.write(
          `<tr>
          <td>${item.date}</td>
          <td>${item.title}</td>
          <td>${item.waste}</td>
          </tr/>`
          )
        } else {
          mywindow.write(`<tr>
            <td>${item.date}</td>
            <td>${item.title}</td>
            <td>${parseFloat(item.bulk).toFixed(3)}</td>
            <td>${item.package}</td>
            <td>${item.weight}</td>
            <td>${item.rate}%</td>
            <td>${item.talabatTate}%</td>
            <td>${item.packCost}</td>
            <td>${item.total}</td>
            <td>${item.price}</td>
            <td>${item.talabat}</td>
            <td>${item.gross}</td>
            <td>${item.talabatGross}</td>
            ${rtype === 'difference'?`<td>${parseFloat(item.price_diff).toFixed(3)}</td>`:''}
            </tr>`)
        }        
      });

      mywindow.write("</table>");
      //mywindow.write(document.getElementById('pid').innerHTML);

      mywindow.close();
    }
  }, [data]);

  const handleDisplay = () => {
    setUpdated(Date.now())
  }

  const handleDownload = () => {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", "products-reports-" + Date.now() + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const handlePrint = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  }

  return (<>
      <h2 className="title">{language['products']} - {language['reports']}</h2>

      <div className="d-block mb-3 pb-3">
        <div className="input-group input-group-sm mb-3">
          <select
            name="category"
            className="form-control"
            onChange={(val) => {
              setRtype(val.target.value)
              setData(null)
              setTotal(0)
              setTotalBulk(0)
            } }
          >
            {/* <option value="all">{language["all-product"]}</option> */}
            <option value="difference">Price Difference</option>
            <option value="waste">Wastage</option>
            <option value="inventory">{language["inventory"]}</option>
          </select>
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">{language["from"]}</span>
          </div>
          <input type="date" defaultValue={fromDate} className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(val) => setFromDate(val.target.value)} />
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">{language["to"]}</span>
          </div>
          <input type="date" defaultValue={toDate} className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(val) => setToDate(val.target.value)} />
          <div className="input-group-append">
            <button onClick={handleDisplay} className="btn btn-success" type="button">{language['show']}</button>
          </div>
          <div className="input-group-append">
            <button className="btn btn-secondary" type="button" onClick={() => handlePrint("all", "profit")}>{language['print']}</button>
          </div>
          <div className="input-group-append">
            <button onClick={handleDownload} className="btn btn-info" type="button">{language['download']}</button>
          </div>

          {/* <div className="input-group-prepend">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{language['reports']}</button>
            <div className="dropdown-menu">
              <a className="dropdown-item" onClick={() => handlePrint("", "revenue")}>{language['print']}</a>
              <a className="dropdown-item" onClick={handleDownload}>{language['download']}</a>
            </div>
          </div> */}
        </div>
      </div>

      <div className="d-block mb-3 pb-3">
        {rtype === 'inventory' && <h2>Bluk Total: {formatter.format(totalBulk)}</h2>}
        <ul className="list-group">
        {rtype === 'difference' && <li className="list-group-item list-group-flush d-flex justify-content-between align-items-center font-weight-bold text-small">
            {/* <div className="col-1">{language["date"]}</div> */}
            <div className="col-3">Name</div>
            <div className="col">Bulk</div>
            <div className="col">KG/PC</div>
            <div className="col">Weight</div>
            {/* <div className="col">Rate</div> */}
            <div className="col">Package</div>
            {/* <div className="col">Time</div> */}
            <div className="col">Total</div>
            <div className="col">Selling</div>
            <div className="col">Talabat</div>
            <div className="col">Gross</div>
            <div className="col">Profit</div>
            <div className="col">{rtype === 'difference'?'Price Difference':'Waste'}</div>
          </li>}
          {rtype === 'inventory' && <li className="list-group-item list-group-flush d-flex justify-content-between align-items-center font-weight-bold text-small">
              <div style={{width: '10%'}}>{language["name"]}</div>
              <div className="col">Bulk/OMR</div>
              <div className="col">KG/PC</div>
              <div className="col">Weight</div>
              <div className="col">Rate</div>
              <div className="col">Talabat Rate</div>
              <div className="col">Package</div>
              <div className="col">Total</div>
              <div className="col">Selling</div>
              <div className="col">Talabat</div>
              <div className="col">Gross</div>
              <div className="col">Talabat Gross</div>
            </li>}
          {data &&
            data.length > 0 &&
            data.map((item) => <div key={item.id}>
              {rtype === 'difference' && <li
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <div className="col-3">{item.title}</div>
                <div className="col">{parseFloat(item.bulk).toFixed(3)}</div>
                <div className="col">{
                parseFloat(item.package).toFixed(3)}</div>
                <div className="col">{item.weight}</div>
                <div className="col">{item.rate}%</div>
                <div className="col">{item.talabatTate}%</div>
                <div className="col">{parseFloat(item.packCost)}</div>
                <div className="col">{parseFloat(item.total).toFixed(3)}</div>
                <div className="col">{parseFloat(item.price).toFixed(3)}</div>
                <div className="col">{parseFloat(item.talabat).toFixed(3)}</div>
                <div className="col">{parseFloat(item.gross).toFixed(3)}</div>
                <div className="col">{item.talabatGross}</div>
                <div className='col'>{parseFloat(item.price_diff).toFixed(3)}</div>
              </li>}
              {rtype === 'inventory' && <li
                  key={item.id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{width: '10%'}}>{item.title}</div>
                  <div className="col">{parseFloat(item.bulk).toFixed(3)}</div>
                  <div className="col">{item.package}</div>
                  <div className="col">{item.weight}</div>
                  <div className="col">{item.rate}%</div>
                  <div className="col">{item.talabatTate}%</div>
                  <div className="col">{item.packCost}</div>
                  <div className="col">{item.total}</div>
                  <div className="col">{item.price}</div>
                  <div className="col">{item.talabat}</div>
                  <div className="col">{item.gross}</div>
                  <div className="col">{item.talabatGross}</div>
                </li>}
              { rtype === 'waste' && <li className="list-group-item d-flex justify-space-between align-items-center">
                <div className="col-10">{item.title}</div>
                <div className='col'>{item.waste}</div>
              </li>}
            </div>)}
        </ul>
      </div>
  </>)
}
