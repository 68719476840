import { useState } from "react";
import { collection, addDoc, getDocs, query } from "firebase/firestore/lite";
import { useEffect } from "react";

const Newcity = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [branch, setBranch] = useState("");
  const [msg, setMesg] = useState("");
  const [fees, setFees] = useState(0);
  const [data, setData] = useState(null);

  const addCity = () => {
    if (title === "" || title_ar === "") {
      setMesg(language["required-error-msg"]);
    } else {
      addDoc(collection(database, "delivery"), {
        title: title,
        title_ar: title_ar,
        fees: fees,
        branch: branch,
        enable: true,
      }).then((res) => {
        navigate("/cites");
      });
    }
  };

  useEffect(() => {
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "branches")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["add"]}</h4>
          <p className="card-description">{language["categories"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="number"
                onChange={(val) => setFees(val.target.value)}
                className="form-control "
                placeholder={language["delivery-fees"]}
              />
            </div>
            <div className="input-group m-3">
              <select
                name="branch"
                className="form-control"
                onChange={(val) => setBranch(val.target.value)}
              >
                {data &&
                  data.map((item) => (
                    <option key={item.id} value={item.id}>
                      {language["language"] === "english"
                        ? item.title
                        : item.title_ar}
                    </option>
                  ))}
              </select>
            </div>
            <div className="p-3 text-danger">{msg}</div>
          </form>

          <button className="btn m-3 btn-success" onClick={addCity}>
            {language["add"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newcity;
