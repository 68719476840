import { createRef, useEffect, useState } from "react";
import {
  query,
  addDoc,
  collection,
  getDocs,
  getDoc,
  doc,
  serverTimestamp,
  where,
  orderBy,
  runTransaction,
} from "firebase/firestore/lite";

import {
  Br,
  Cut,
  Line,
  Printer,
  Text,
  Row,
  render,
  Cashdraw,
  Barcode,
  QRCode,
} from "react-thermal-printer";
import { useParams } from "react-router-dom";

const NewInvoice = ({ language, database }) => {
  const [total, setTotal] = useState(0);
  const [dMsg, setDMsg] = useState(null);
  const [list, setList] = useState([]);
  const [optionsList, setOptionsList] = useState(null);
  const [qty, setQty] = useState(1);
  const [productsList, setProductsList] = useState(null);
  const [payment, setPayment] = useState("cash");
  const [vat, setVat] = useState(0);
  const [vatVal, setVatVal] = useState(true);
  const [lpo, setLPO] = useState("");
  const [branch, setBranch] = useState("0");
  const [date, setDate] = useState("");
  const [branches, setBranches] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState("");
  const { id } = useParams();
  // current date
  const d = new Date()
  const today = `${(d.getDate() < 10?'0'+d.getDate():d.getDate())}-${((d.getMonth() + 1) < 10?'0'+(d.getMonth() + 1):(d.getMonth() + 1))}-${d.getFullYear()}`;

  console.log(today);
  const formData = createRef();
  let UPC = "";

  useEffect(() => {
    let temp_arr = [];

    getDocs(query(collection(database, "merchants-products")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //if (doc.data().availability[branch].enable === true) {
          let price_default = doc
            .data()
            .priceList.find((item) => item.id === "0");
          let temp_price = doc.data().priceList.find((item) => item.id === id);
          let price = temp_price ? temp_price.price : price_default.price;

          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
            price: price,
            barcode: doc.data().barcode ? doc.data().barcode : "",
            qty: doc.data().qty ? parseInt(doc.data().qty) : 1,
            enable: true,
          });
          //}
        });
        setProductsList(temp_arr);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    calucalateTotal();
  }, [list, vatVal]);

  useEffect(() => {
    getDoc(query(doc(database, "main", "tJ682Usp1DrntvF3A20P"))).then((doc) => {
      setVat(doc.data().vat);
    });
  }, []);

  useEffect(() => {
    let temp_arr = [];
    getDocs(
      query(
        collection(database, "merchents-branches"),
        where("merchant", "==", id)
      )
    )
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            merchant: doc.data().merchant,
            name: doc.data().name,
            enable: doc.data().enable,
            mobile: doc.data().mobile,
            email: doc.data().email,
            area: doc.data().area,
            lat: doc.data().lat,
            lon: doc.data().lon,
          });
        });
        setBranches(temp_arr);
      })
      .catch((err) => {
        //
      });
  }, []);

  const handleAddProduct = (e) => {
    e.preventDefault();
    let sumTotal = 0;

    let temp_items = [...list];

    let item_details = productsList.filter(
      (val) =>
        val.title.toLowerCase() == formData.current[0].value.toLowerCase()
    );
    item_details[0].qty = qty;

    temp_items.push(item_details[0]);

    sumTotal = temp_items.reduce(
      (accumulator, currentValue) =>
        accumulator +
        parseFloat(currentValue.price) * parseInt(currentValue.qty),
      0
    );

    setList(temp_items);
    setQty(1);
    formData.current[0].value = "";
  };

  const handleRemoveItem = (item) => {
    let temp_arr = null;
    let r = window.confirm(language["confirm"]);

    if (r) {
      temp_arr = list.filter((val) => val.id !== item);
      setList(temp_arr);
    }
  };

  const calucalateTotal = () => {
    let temp_total = 0;
    const initialValue = 0;
    temp_total = list.reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) +
        parseFloat(currentValue.price) * parseInt(currentValue.qty) +
        (vatVal
          ? parseFloat(currentValue.price) *
            (vat / 100) *
            parseInt(currentValue.qty)
          : 0),
      initialValue
    );
    setTotal(temp_total.toFixed(3));
  };

  const handleSearchProduct = (e) => {
    let term = e.target.value;
    let temp_options = [];

    if (term.length > 3) {
      productsList.forEach((item) => {
        try {
          if (
            item.title.toLowerCase().includes(term) ||
            item.title_ar.includes(term)
          ) {
            temp_options.push(item);
          }
        } catch {
          //console.log(doc.data());
        }
      });

      setOptionsList(temp_options);
    }
  };

  const handleOrder = async (op) => {
    let currentBranch = window.localStorage.getItem("branch");
    let enable = false;
    const orderId = Date.now();

    if (branch === "0") {
      setDMsg(language["branch"] + ": " + "error-msg");
    } else if(invoiceDate === ''){
      setDMsg(language["required-error-msg"]);
    } else if(list.length !== 0) {
      enable = true;
    }

    if (enable === true) {
      addDoc(collection(database, "merchant-orders"), {
        orderId: orderId,
        merchant: id,
        userId: 8965656,
        items: list,
        total: parseFloat(total),
        canceled: false,
        payment: payment,
        merchantBranch: branch,
        date: date,
        invoiceDate: invoiceDate,
        lpo: lpo,
        branch: currentBranch,
        vat: vat,
        vatEnabled: vatVal,
        enable: true,
        timestamp: serverTimestamp(),
      })
        .then((res) => {
          if (op === "new") {
            window.location.reload();
          } else {
            window.location.href = "/merchants/" + id;
          }
        })
        .catch((err) => {
          if (op === "new") {
            window.location.reload();
          } else {
            window.location.href = "/merchants/" + id;
          }
        });
    }
  };

  const handleItemChange = (item, type, val) => {
    let temp_arr = list;
    temp_arr.map((list_item) => {
      if (list_item.id == item) {
        if (type === "qty") {
          list_item.qty = parseInt(val);
        } else {
          list_item.price = parseFloat(val);
        }
      }
    });
    setList(temp_arr);
    calucalateTotal();
  };

  const handleBarcodeScan = (e) => {
    let textInput = e.key || String.fromCharCode(e.keyCode);
    let targetName = e.target.localName;
    let temp_items = [...list];
    let exsit = false;

    if (textInput && textInput.length === 1 && targetName !== "input") {
      UPC = UPC !== null ? UPC + textInput : textInput;
      if (UPC.length > 5) {
        try {
          let item_details = productsList.filter(
            (val) => val.barcode && val.barcode === UPC
          );
          if (item_details && item_details.length > 0) {
            temp_items.push(item_details[0]);

            setList(temp_items);
            calucalateTotal();
            UPC = "";
          }
        } catch (err) {
          UPC = "";
        }
      }
    }
    //setUPC(newUPC)
  };

  const selectBranch = (doc) => {
    try {
      let temp_b = JSON.parse(doc);
      setBranch(temp_b);
    } catch (error) {
      //
    }
  };

  document.addEventListener("keydown", handleBarcodeScan);

  return (
    <div className="row">
      <div className="col-12 stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">{language["new"]}</h4>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="date">{language["date"]}</label>
                <input
                  id="date"
                  name="date"
                  type="date"
                  defaultValue={today}
                  placeholder={language["invoice"] + " " + language["date"]}
                  className="form-control"
                  onChange={(val) => setInvoiceDate(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="date">LPO {language["date"]}</label>
                <input
                  id="date"
                  name="date"
                  type="date"
                  placeholder={language["date"]}
                  className="form-control"
                  onChange={(val) => setDate(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">LPO No.</label>
                <input
                  id="lpo"
                  name="lpo"
                  placeholder="LPO No."
                  className="form-control"
                  onChange={(val) => setLPO(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
              <label htmlFor="date">{language["branch"]}</label>
                <select
                  name="merchant"
                  className="form-control"
                  onChange={(val) => selectBranch(val.target.value)}
                >
                  <option value="0">{language["branches"]}</option>
                  {branches &&
                    branches.map((item) => (
                      <option
                        key={"branch-" + item.id}
                        value={JSON.stringify(item)}
                      >
                        {item.area}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <input
                  type="checkbox"
                  id="vat"
                  name="vat"
                  className="p-5 m-3 mt-5"
                  defaultChecked={true}
                  onChange={() => setVatVal(!vatVal)}
                />
                <label htmlFor="vat" className="p-2">
                  {" "}
                  {language["apply-vat"]} ({vat}%)
                </label>
              </div>
            </div>
            <hr />
            <h4>{language["items"]}</h4>
            <form className="from row mb-3 mt-3" ref={formData}>
              <div className="col-5">
                {/* <label htmlFor="item-title">{language['title']}</label> */}
                <input
                  type="text"
                  list="products-list"
                  id="item-title"
                  placeholder={language["title"]}
                  className="form-control"
                  onChange={handleSearchProduct}
                />
                <datalist
                  name="products-list"
                  id="products-list"
                  onClick={(e) => console.log(e)}
                >
                  {optionsList &&
                    optionsList.length > 0 &&
                    optionsList.map((item) => (
                      <option key={item.title} value={item.title}>
                        {language["dir"] === "rtl" ? item.title_ar : item.title}
                      </option>
                    ))}
                </datalist>
              </div>
              <div className="col-3">
                {/* <label htmlFor="item-qty">{language['qty']}</label> */}
                <input
                  value={qty}
                  type="number"
                  id="item-qty"
                  min="1"
                  placeholder={language["qty"]}
                  className="form-control"
                  onChange={(val) => setQty(val.target.value)}
                />
              </div>
              <div className="col-2 align-top">
                <button
                  className="btn btn-sm btn-success align-top"
                  onClick={handleAddProduct}
                >
                  <i className="fa fa-plus"></i> {language["add"]}
                </button>
              </div>
            </form>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th>{language["title"]}</th>
                  <th>{language["qty"]}</th>
                  {/* <th>{language["unit"]}</th> */}
                  <th>VAT ({vat}%)</th>
                  <th>{language["price"]}</th>
                  <th>{language["total"]}</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((item) => (
                    <tr key={"order-item-" + item.id}>
                      <td>
                        {language["language"] === "english"
                          ? item.title
                          : item.title_ar}
                      </td>
                      <td>
                        <input
                          type="number"
                          min="1"
                          placeholder={item.qty}
                          onKeyUp={(val) =>
                            handleItemChange(item.id, "qty", val.target.value)
                          }
                          className="form-control p-1"
                        />
                      </td>
                      <td>
                        {!vatVal
                          ? 0
                          : (
                              item.price *
                              (!vatVal ? 0 : (vat / 100) * item.qty)
                            ).toFixed(3)}
                      </td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          placeholder={item.price}
                          onKeyUp={(val) =>
                            handleItemChange(item.id, "price", val.target.value)
                          }
                          className="form-control p-1"
                        />
                      </td>
                      <td>
                        <b>
                          {(
                            parseFloat(item.price) * parseInt(item.qty) +
                            (!vatVal
                              ? 0
                              : item.price *
                                (!vatVal ? 0 : vat / 100) *
                                item.qty)
                          ).toFixed(3)}
                        </b>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                {!list ||
                  (list.length === 0 && (
                    <tr className="text-center text-muted p-5 m-5">
                      <td>{language["empty-cart"]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <hr />
            <div className="row">
              <div className="col-6 p-2 fs-3">
                {language["total"]}: OMR {total}
              </div>
            </div>
            <p>{dMsg}</p>
            <div className="text-center mt-5">
              <button className="btn btn-success m-2" onClick={handleOrder}>
                {language["save"]}
              </button>
              <button
                className="btn btn-success m-2"
                onClick={() => handleOrder("new")}
              >
                {language["save-new"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInvoice;
