import {
  collection,
  query,
  getDocs,
  limit,
  doc,
  deleteDoc,
  startAfter,
  orderBy,
} from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const Tasks = ({ database, setLoading, user, language, usersData }) => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [pages, setPAges] = useState(0);
  const { list } = useParams();
  let lastVisible = null;
  const getNewsletter = () => {
    let temp_arr = [];
    let q = null;
    setLoading(true);
    q = getDocs(query(collection(database, "tasks"), orderBy('timestamp', 'desc'), limit(10)));
    q.then((querySnapshot) => {
      lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      querySnapshot.forEach(doc => {
        let prog = doc.data()?.list.filter(item => item.stage === 'done')

        temp_arr.push({
          id: doc.id,
          timestamp: doc.data().timestamp,
          title: doc.data().title,
          list: doc.data().list,
          progress: doc.data().list?(parseFloat(prog.length) / doc.data()?.list.length) * 100:0
        });

      });
      setData(temp_arr);
      setLoading(false);
    }).catch((err) => setLoading(false));
  };

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      deleteDoc(doc(database, "tasks", item)).then(() => {
        setUpdate(Date.now());
      });
    }
  };

  useEffect(() => {
    getNewsletter();
    window.onscroll = function (ev) {
      var pageHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      if (window.innerHeight + window.scrollY >= pageHeight) {
        let temp_arr = [];
        let next = null;
        let branch = window.localStorage.getItem("branch");
        if (list === "list" && lastVisible) {
          next = getDocs(
            query(
              collection(database, "tasks"),
              startAfter(lastVisible),
              orderBy('timestamp', 'desc'),
              limit(10)
            )
          );
          setLoading(true);
          next
            .then((querySnapshot) => {
              lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
              if (querySnapshot.docs) {
                setLoading(false);
                querySnapshot.forEach((doc) => {
                  let prog = doc.data()?.list.filter(item => item.stage === 'done')

                  //temp_arr.push({
                  setData((ol) => [
                    ...ol,
                    {
                      id: doc.id,
                      timestamp: doc.data().timestamp,
                      title: doc.data().title,
                      list: doc.data().list,
                      progress: doc.data().list?(parseFloat(prog.length) / doc.data()?.list.length) * 100:0
                    },
                  ]);
                });
              }
            })
            .catch((err) => setLoading(false));
        }
      }
    };
  }, [update]);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            {language["tasks"]}
            <Link to="/tasks/new" className="btn btn-sm btn-success m-2">
              <i className="fa fa-plus"></i> {language["add"]}
            </Link>
          </h4>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["date"]}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col-6"><Link to={`/tasks/${item.id}`} className="text-decoration-none text-dark"><i className="fa fa-list"></i> {item.title}</Link></td>
                    <td className="col-4">
                      {item.timestamp.toDate().toLocaleDateString() + " - " + item.timestamp.toDate().toLocaleTimeString()}
                    </td>
                    <td>
                    <progress class="progress-bar" role="progressbar" id="progressbar" value={item.progress} max="100"> {item.progress + '%'} </progress>
                    </td>
                    <td className="col">
                      <button className="btn btn-sm btn-danger m-2" onClick={() => handleDelete(item.id)}>
                        <i className="mdi mdi-delete"></i>{" "}
                        {language["delete"]}
                      </button>
                    </td>

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
