import { useEffect, useState } from "react";
import { getDoc, query, updateDoc, doc } from "firebase/firestore/lite";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";

const Editcategory = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [image, setImage] = useState("");
  const [list, setList] = useState(null);
  const { category } = useParams();

  const getData = () => {
    setLoading(true);
    getDoc(query(doc(database, "categories", category)))
      .then((querySnapshot) => {
        setList(querySnapshot.data());
        setTitle(querySnapshot.data().title);
        setTitleAr(querySnapshot.data().title_ar);
        setImage(
          querySnapshot.data().image ? querySnapshot.data().image : null
        );
      })
      .catch((err) => console.log(err));
  };

  const editCategory = () => {
    updateDoc(doc(database, "categories", category), {
      title: title,
      title_ar: title_ar,
      image: image,
    }).then((res) => {
      navigate("/categories");
    });
  };

  const handleUpload = (file) => {
    const imageRef = ref(storage, "images/" + image);
    uploadBytes(imageRef, file.target.files[0]) //, metadata)
      .then((snapshot) => {
        // console.log('Uploaded', snapshot.totalBytes, 'bytes.');
        //console.log('File metadata:', snapshot.metadata);
        // Let's get a download URL for the file.
        getDownloadURL(snapshot.ref).then((url) => {
          //console.log('File available at', url);
          // ...
        });
      })
      .catch((error) => {
        //console.error('Upload failed', error);
        // ...
      });
  };

  useEffect(() => {
    setImage(Date.now() + ".jpg");
    getData();
  }, []);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["edit"]}</h4>
          <p className="card-description">{language["categories"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                value={title_ar}
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                value={title}
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            {image !== "" && (
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/images%2F" +
                  image +
                  "?alt=media"
                }
                className="col-7 m-4 p-3"
              />
            )}
            <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={handleUpload}
                className="form-control"
                placeholder="Upload Picture"
              />
            </div>
          </form>

          <button className="btn m-3 btn-success" onClick={editCategory}>
            {language["update"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Editcategory;
