import { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
  where,
} from "firebase/firestore/lite";
import { Link, useParams } from "react-router-dom";

export default function MerchantBranches({
  database,
  setLoading,
  language,
  usersData,
}) {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    let temp_arr = [];
    setLoading(true);
    getDocs(
      query(
        collection(database, "merchents-branches"),
        where("merchant", "==", id),
        //orderBy("timestamp", "desc")
      )
    )
      .then((querySnapshot) => {
        setLoading(false);
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            merchant: doc.data().merchant,
            name: doc.data().name,
            enable: doc.data().enable,
            mobile: doc.data().mobile,
            email: doc.data().email,
            area: doc.data().area,
            lat: doc.data().lat,
            lon: doc.data().lon,
            timestamp: doc.data().timestamp,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [update]);

  const handleDelete = (item) => {
    setLoading(true);
    let r = window.confirm("Are you sure?");
    if (r) {
      deleteDoc(doc(database, "merchents-branches", item)).then(() => {
        setLoading(false);
        setUpdate(Date.now());
      });
    }
  };

  const handleAvailable = (item, val) => {
    updateDoc(doc(database, "merchents-branches", item), {
      enable: !val,
    })
      .then((res) => {
        setUpdate(Date.now());
      })
      .catch((res) => console.log(res));
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["branches"]}</h4>
          <Link
            to={`/merchants/branches/new/${id}`}
            type="button"
            className="btn btn-sm btn-success btn-rounded btn-fw ml-1 mr-1 mb-2"
          >
            <i className="mdi mdi-plus"></i> {language["new"]}
          </Link>

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["area"]}</th>
                <th>{language["full-name"]}</th>
                <th>{language["mobile"]}</th>
                <th>{language["email"]}</th>
                <th>{language["date"]}</th>
                {/* 
                <th>{language["title"]}</th>
                */}
                {/* <th>{language["available"]}</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">{item.area}</td>
                    <td className="col">{item.name}</td>
                    <td className="col">{item.mobile}</td>
                    <td className="col">{item.email}</td>
                    <td className="col">
                      {item.timestamp.toDate().toLocaleDateString()}
                    </td>
                    <td className="col">
                      <button
                        className="btn btn-sm btn-success m-2"
                        onClick={() =>
                          window.open(`https://wa.me/968${item.mobile}`)
                        }
                      >
                        <i className="fa fab fa-whatsapp fs-5"></i>
                      </button>
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-sm btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
