import { useEffect, useState } from "react";
import { getDocs, collection, query, addDoc } from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Navigate } from "react-router-dom";

const Newproduct = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [price, setPrice] = useState("");
  const [price_old, setPriceOld] = useState("");
  const [limit, setLimit] = useState(1000);
  const [describtion, setDescribtion] = useState("");
  const [describtion_ar, setDescribtionAr] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(Date.now() + ".jpg");
  const [list, setList] = useState(null);
  const [branches, setBranches] = useState([]);
  const [msg, setMesg] = useState("");
  const [barcode, setBarcode] = useState('');
  const [bulk, setBulk] = useState(0);
  const [unit, setUnit] = useState('');
  const [bulkType, setBulkType] = useState('');

  const getCategories = () => {
    let temp_arr = [];
    let branch_arr = {};

    setLoading(true);
    getDocs(query(collection(database, "categories")))
      .then((querySnapshot) => {
        querySnapshot.forEach((product) => {
          temp_arr.push({
            id: product.id,
            title: product.data().title,
            title_ar: product.data().title_ar,
            slug: product.data().slug,
          });
        });
        setList(temp_arr);
        setCategory(temp_arr[0].slug);
      })
      .catch((err) => console.log(err));

    getDocs(query(collection(database, "branches")))
      .then((querySnapshot) => {
        querySnapshot.forEach((product) => {
          branch_arr[product.id] = { enable: true };
        });
        setBranches(branch_arr);
      })
      .catch((err) => console.log(err));
  };

  const addProduct = () => {
    if (title === "" || title_ar === "" || price === "") {
      setMesg(language["required-error-msg"]);
    } else {
      addDoc(collection(database, "products"), {
        title: title,
        title_ar: title_ar,
        unit: unit,
        price: price,
        price_old: price_old,
        image: image,
        enable: true,
        describtion: describtion,
        describtion_ar: describtion_ar,
        category: category,
        sort: 0,
        limit: parseInt(limit),
        availability: branches,
        qty: 0,
        barcode: barcode,
        bulk: bulk,
        bulkType: bulkType,
      }).then((res) => {
        navigate("/products/list");
      });
    }
  };

  const handleUpload = (file) => {
    const imageRef = ref(storage, "images/" + image);
    uploadBytes(imageRef, file.target.files[0]) //, metadata)
      .then((snapshot) => {
        // console.log('Uploaded', snapshot.totalBytes, 'bytes.');
        //console.log('File metadata:', snapshot.metadata);
        // Let's get a download URL for the file.
        getDownloadURL(snapshot.ref).then((url) => {
          //console.log('File available at', url);
          // ...
        });
      })
      .catch((error) => {
        console.error("Upload failed", error);
        // ...
      });
  };

  useEffect(() => {
    setImage(Date.now() + ".jpg");
    getCategories();
  }, []);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["add"]}</h4>
          <p className="card-description">{language["products"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            <label className="d-block mb-2" htmlFor="">{language['unit']}</label> <br />
            <div className="input-group m-3">
                <input type="text" value={unit} onChange={val => setUnit(val.target.value)} className="form-control " placeholder={language["unit"]} />
            </div>
            <label className="d-block mb-2" htmlFor="">{language['price']}</label> <br />
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setPrice(val.target.value)}
                className="form-control"
                placeholder={language["price"]}
              />
            </div>
            <label className="d-block mb-2" htmlFor="">{language['price_old']}</label> <br />
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setPriceOld(val.target.value)}
                className="form-control"
                placeholder={language["price_old"]}
              />
            </div>
            <label className="d-block mb-2" htmlFor="">Bulk {language['price']}</label> <br />
            <div className="input-group m-3">
                <input type="text" id="BulkPrice" value={bulk} onChange={val => setBulk(val.target.value)} className="form-control" placeholder="Bulk Price" />
            </div>
            <label className="d-block mb-2" htmlFor="bulkType">Bulk Type</label> <br />
            <div className="input-group m-3">
                <input type="text" id="bulkType" value={bulkType} onChange={val => setBulkType(val.target.value)} className="form-control" placeholder="Bulk Type" />
            </div>
            <label className="d-block mb-2" htmlFor="">Barcode / SKU</label> <br />
            <div className="input-group m-3">
                <input type="text" onChange={val => setBarcode(val.target.value)} className="form-control" placeholder="Barcode" />
            </div>
            <label className="d-block mb-2" htmlFor="">{language['qty-limit']}</label> <br />
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setLimit(val.target.value)}
                className="form-control"
                placeholder={language["qty-limit"]}
              />
            </div>
            <label className="d-block mb-2" htmlFor="desc">{language['describtion']}</label> <br />
            <div className="input-group m-3">
              <textarea
                id="desc"
                placeholder={language["describtion"] + " " + language["arabic"]}
                className="form-control"
                onChange={(val) => setDescribtionAr(val.target.value)}
              ></textarea>
            </div>
            <label className="d-block mb-2" htmlFor="">{language['describtion']} {language["english"]}</label> <br />
            <div className="input-group m-3">
              <textarea
                placeholder={
                  language["describtion"] + " " + language["english"]
                }
                className="form-control"
                onChange={(val) => setDescribtion(val.target.value)}
              ></textarea>
            </div>
            <label className="d-block mb-2" htmlFor="">{language['categories']}</label> <br />
            <div className="input-group m-3">
              <select
                name="category"
                className="form-control"
                onChange={(val) => setCategory(val.target.value)}
              >
                {list &&
                  list.map((item) => (
                    <option key={item.id} value={item.slug}>
                      {language["language"] === "english"
                        ? item.title
                        : item.title_ar}
                    </option>
                  ))}
              </select>
            </div>

            <div className="p-3 text-danger">{msg}</div>

            <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={handleUpload}
                className="form-control"
                placeholder="Upload Picture"
              />
            </div>
          </form>

          <button className="btn m-3 btn-success" onClick={addProduct}>
            {language["add"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newproduct;
