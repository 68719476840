import { useState } from "react";
import { collection, addDoc } from "firebase/firestore/lite";

export default function NewBranch({
  database,
  user,
  setLoading,
  navigate,
  language,
}) {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [msg, setMsg] = useState("");

  const addBranch = () => {
    let d = new Date();
    let today = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();

    if (title === "" || title_ar === "") {
      setMsg("Please fill all the required fields...");
    } else {
      addDoc(collection(database, "branches"), {
        title: title,
        title_ar: title_ar,
        enable: true,
        date: today,
        sort: 0,
      }).then((res) => {
        navigate("/branches");
      });
    }
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["new"]}</h4>
          <p className="card-description">{language["categories"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            <div className="p-3 text-danger">{msg}</div>
          </form>

          <button className="btn m-3 btn-success" onClick={addBranch}>
            {language["add"]}
          </button>
        </div>
      </div>
    </div>
  );
}
