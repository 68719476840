import { useState, useEffect } from "react";
import {
  getDoc,
  query,
  updateDoc,
  doc,
  getDocs,
  collection,
} from "firebase/firestore/lite";
import { useParams } from "react-router-dom";

const EditCity = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [branch, setBranch] = useState("");
  const [msg, setMesg] = useState("");
  const [fees, setFees] = useState(0);
  const [data, setData] = useState(null);
  const [enable, setEnable] = useState(true);
  const { city } = useParams();

  const updateCity = () => {
    setLoading(true);
    getDoc(query(doc(database, "delivery", city)))
      .then((querySnapshot) => {
        setTitle(querySnapshot.data().title);
        setTitleAr(querySnapshot.data().title_ar);
        setBranch(querySnapshot.data().branch);
        setFees(querySnapshot.data().fees);
        setEnable(querySnapshot.data().enable);
        //setSort(querySnapshot.data().sort);
        let temp_arr = [];
        setLoading(true);
        getDocs(query(collection(database, "branches")))
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              temp_arr.push({
                id: doc.id,
                title: doc.data().title,
                title_ar: doc.data().title_ar,
                enable: doc.data().enable,
              });
            });
            setData(temp_arr);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const addCity = () => {
    console.log({
      title: title,
      title_ar: title_ar,
      fees: parseInt(fees),
      enable: enable,
    });
    if (title === "" || title_ar === "") {
      setMesg(language["required-error-msg"]);
    } else {
      updateDoc(doc(database, "delivery", city), {
        title: title,
        title_ar: title_ar,
        fees: fees,
        branch: branch,
        enable: enable,
      })
        .then((res) => {
          console.log(res);
          navigate("/cites");
        })
        .catch((err) => {
          console.log(err);
          setMesg(err.message);
        });
    }
  };

  useEffect(() => {
    updateCity();
  }, []);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["add"]}</h4>
          <p className="card-description">{language["categories"]}</p>
          <form className="col-8 row">
            <div className="m-3">
              <label htmlFor="">
                {language["title"] + " " + language["arabic"]}
              </label>{" "}
              <br />
              <input
                value={title}
                type="text"
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="m-3">
              <label htmlFor="">
                {language["title"] + " " + language["english"]}
              </label>{" "}
              <br />
              <input
                value={title_ar}
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            <div className="m-3">
              <label htmlFor="">{language["delivery-fees"]}</label> <br />
              <input
                value={fees}
                type="number"
                onChange={(val) => setFees(val.target.value)}
                className="form-control "
                placeholder={language["delivery-fees"]}
              />
            </div>
            <div className="m-3">
              <label htmlFor="">{language["branches"]}</label> <br />
              <select
                name="branch"
                className="form-control"
                onChange={(val) => setBranch(val.target.value)}
                defaultValue={branch}
              >
                {data &&
                  data.map((item) => (
                    <option
                      key={item.id}
                      value={item.id}
                      selected={item.id === branch}
                    >
                      {language["language"] === "english"
                        ? item.title
                        : item.title_ar}
                    </option>
                  ))}
              </select>
            </div>
            <div className=" m-3">
              <label htmlFor="">{language["available"]}</label> <br />
              <select
                name="category"
                value={enable}
                className="form-control"
                onChange={(val) => setEnable(val.target.value)}
                defaultValue={enable}
              >
                <option key={"enable1"} value={true}>
                  {language["available"]}
                </option>
                <option key={"enable2"} value={false}>
                  {language["unavailable"]}
                </option>
              </select>
            </div>
            <div className="p-3 text-danger">{msg}</div>
          </form>

          <button className="btn m-3 btn-success" onClick={addCity}>
            {language["update"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCity;
