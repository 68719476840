import {
  collection,
  query,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const Slideshow = ({ database, setLoading, user, language }) => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);

  const getOrders = () => {
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "slideshow")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            image: doc.data().image,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"]);
    deleteDoc(doc(database, "slideshow", item)).then(() => {
      setUpdate(Date.now());
    });
  };
  useEffect(() => {
    getOrders();
  }, [update]);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["slideshow"]}</h4>
          <Link
            to="/newslideshow"
            type="button"
            className="btn btn-success btn-rounded btn-fw"
          >
            <i className="mdi mdi-plus"></i> {language["new"]}
          </Link>
          {data &&
            data.map((item) => (
              <div className="row" key={item.id}>
                <div className="col-6">
                  {
                    <img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/images%2F" +
                        item.image +
                        "?alt=media"
                      }
                      className="col-12 m-4 p-3"
                    />
                  }
                </div>
                <div className="col">
                  <button
                    className="btn btn-success"
                    onClick={() => handleDelete(item.id)}
                  >
                    <i className="mdi mdi-delete"></i> {language["delete"]}
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
