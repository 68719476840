import {
  collection,
  query,
  getDocs,
  orderBy,
  where,
  doc,
  deleteDoc,
} from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// TODO: add tabs for today subscriptions, list all other subs...

const Subscriptions = ({ database, setLoading, language, usersData }) => {
  const [tabs, setTabs] = useState("today");
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [subData, setSubsData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [pages, setPAges] = useState(0);

  const getOrders = () => {
    let temp_arr = [];
    let temp_subs_arr = [];
    setLoading(true);
    getDocs(
      query(
        collection(database, "orders"),
        where("subscriptions", "!=", "false")
      )
    ) //, orderBy("timestamp", "desc")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            timestamp: doc.data().timestamp,
            type: doc.data().type,
            delivered: doc.data().delivered,
            total: doc.data().total,
            items: doc.data().items.length,
            orderId: doc.data().orderId,
          });

          if (doc.data().details.delivery.subscription) {
            if (handleTodayOrders(doc.data())) {
              temp_subs_arr.push({
                id: doc.id,
                timestamp: doc.data().timestamp,
                type: doc.data().type,
                delivered: doc.data().delivered,
                total: doc.data().total,
                items: doc.data().items.length,
                orderId: doc.data().orderId,
              });
            }
          }
        });

        setList(temp_arr);
        setSubsData(temp_subs_arr);
        setData(temp_subs_arr);
      })
      .catch((err) => console.log(err));
  };

  const handleTodayOrders = (orderDetails) => {
    let today = new Date();
    let days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    let thisMonth =
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;
    let thisYear = today.getFullYear();
    let monthLength = new Date(thisYear, thisMonth, 0).getDate();
    let todayFullDate = today.getDate() + "/" + thisMonth + "/" + thisYear;
    let daysList = [];
    let ordersThisDay = [];
    let status = false;

    for (let i = 0; i < monthLength; i++) {
      let index = i + 1;
      index = index < 10 ? "0" + index : index;
      let day = new Date(thisYear + "-" + thisMonth + "-" + index).getDay();

      daysList.push({
        id: i,
        day: index,
        name: days[day],
        date: index + "/" + thisMonth + "/" + thisYear,
        timestamp: thisYear + "-" + thisMonth + "-" + index,
        sort: day,
      });
    }

    let orderDate = orderDetails.timestamp.toDate();

    console.log(parseInt(orderDetails.details.delivery.subscription));
    switch (parseInt(orderDetails.details.delivery.subscription)) {
      case 2:
        let init = 1;

        for (let index = 0; index < daysList.length; index++) {
          if (orderDate.getDay() === daysList[index].sort) {
            if (init <= index && ordersThisDay.length < 2) {
              ordersThisDay.push({
                id: daysList[index].id,
                day: daysList[index].name,
                date: daysList[index].date,
                timestamp: daysList[index].timestamp,
                delivered: false,
              });

              if (new Date(daysList[index].timestamp) === today) {
                status = true;
              }

              init = init != 14 ? init + 14 : 23;
            }
          }
        }

        break;

      case 4:
        daysList.forEach((element) => {
          if (orderDate.getDay() === element.sort) {
            ordersThisDay.push({
              id: element.id,
              day: element.name,
              date: element.date,
              timestamp: element.timestamp,
              delivered: false,
            });

            if (new Date(element.timestamp) === today) {
              status = true;
            }
          }
        });
        break;

      case 8:
        let init8 = 1;

        for (let index = 0; index < daysList.length; index++) {
          if (
            orderDate.getDay() === daysList[index].sort ||
            orderDate.getDay() + 3 === daysList[index].sort
          ) {
            if (init8 <= index) {
              let d = new Date(daysList[index].timestamp);

              ordersThisDay.push({
                id: daysList[index].id,
                day: daysList[index].name,
                timestamp: daysList[index].timestamp,
                date: daysList[index].date,
                delivered: false,
              });

              if (
                d.getDate() + d.getMonth() + d.getFullYear() ===
                today.getDate() + today.getMonth() + today.getFullYear()
              ) {
                status = true;
              }

              init8 = init8 + 2;
            }
          }
        }

        break;

      default:
        status = false;
        break;
    }

    return status;
  };

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      deleteDoc(doc(database, "orders", item)).then(() => {
        setUpdate(Date.now());
      });
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleTabs = (tabName) => {
    if (tabName === "today") {
      setData(subData);
    } else {
      setData(list);
    }
    setTabs(tabName);
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["orders"]}</h4>
          <p className="card-description"> {language["subscription"]}</p>

          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                onClick={() => handleTabs("today")}
                className={
                  tabs === "today"
                    ? "nav-link text-muted active"
                    : "nav-link text-muted"
                }
                role="button"
              >
                {language["today"]}
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => handleTabs("all")}
                className={
                  tabs === "all"
                    ? "nav-link text-muted active"
                    : "nav-link text-muted"
                }
                role="button"
              >
                {language["all"]}
              </a>
            </li>
          </ul>

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["orderid"]}</th>
                <th>{language["total"]}</th>
                <th>{language["items"]}</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="col">{item.orderId}</td>
                    <td className="col">{item.total}</td>
                    <td className="col">{item.items}</td>
                    <td className="col">
                      <label
                        className={
                          item.delivered
                            ? "badge badge-success"
                            : "badge badge-danger"
                        }
                      >
                        {item.delivered
                          ? language["delivered"]
                          : language["undelivered"]}
                      </label>
                    </td>
                    <td className="col">
                      {usersData && usersData.userType === "admin" && (
                        <button
                          className="btn btn-danger m-2"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="mdi mdi-delete"></i>{" "}
                          {language["delete"]}
                        </button>
                      )}
                    </td>
                    <td className="col">
                      <Link
                        className="btn btn-success"
                        to={"/order/" + item.id}
                      >
                        <i className="mdi mdi-eye"></i> {language["view"]}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
