import {
  collection,
  query,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Cites = ({ database, setLoading, user, language }) => {
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);

  const getOrders = () => {
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "delivery")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
            enable: doc.data().enable,
          });
        });
        setData(temp_arr);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (item) => {
    let r = window.confirm("Are you sure?");
    deleteDoc(doc(database, "delivery", item)).then(() => {
      setUpdate(Date.now());
    });
  };
  useEffect(() => {
    getOrders();
  }, [update]);

  const handleAvailable = (item, val) => {
    updateDoc(doc(database, "delivery", item), {
      enable: !val,
    })
      .then((res) => {
        setUpdate(Date.now());
      })
      .catch((res) => console.log(res));
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["cites"]}</h4>
          <Link
            to="/newcity"
            type="button"
            className="btn btn-success btn-rounded btn-fw"
          >
            <i className="mdi mdi-plus"></i> {language["new"]}
          </Link>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr>
                    <td>
                      {language["language"] === "arabic"
                        ? item.title_ar
                        : item.title}
                    </td>
                    <td>{item.fees}</td>
                    <td className="text-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          defaultChecked={item.enable}
                          onClick={(e) => handleAvailable(item.id, item.enable)}
                        />
                      </div>
                    </td>
                    <td>
                      <Link
                        className="btn btn-success m-2"
                        to={"/cites/" + item.id}
                      >
                        <i className="mdi mdi-pen"></i> Edit
                      </Link>
                      <button
                        className="btn btn-danger m-2"
                        onClick={() => handleDelete(item.id)}
                      >
                        <i className="mdi mdi-delete"></i> {language["delete"]}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Cites;
