import { useEffect, useState } from "react";
import { getDocs, collection, query, addDoc } from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Navigate } from "react-router-dom";

const Newappslideshow = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [image, setImage] = useState(Date.now() + ".jpg");

  const addSlide = () => {
    addDoc(collection(database, "appslideshow"), {
      image: image,
    }).then((res) => {
      navigate("/appslideshow");
    });
  };

  const handleUpload = (file) => {
    const imageRef = ref(storage, "images/" + image);
    uploadBytes(imageRef, file.target.files[0]) //, metadata)
      .then((snapshot) => {
        // console.log('Uploaded', snapshot.totalBytes, 'bytes.');
        //console.log('File metadata:', snapshot.metadata);
        // Let's get a download URL for the file.
        getDownloadURL(snapshot.ref).then((url) => {
          //console.log('File available at', url);
          // ...
        });
      })
      .catch((error) => {
        console.error("Upload failed", error);
        // ...
      });
  };

  useEffect(() => {
    setImage(Date.now() + ".jpg");
  }, []);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["new"]}</h4>
          <p className="card-description">{language["slideshow"]}</p>
          <form className="col-8 row">
            {/* <div className="input-group m-3">
                <input type="text"onChange={val => setTitleAr(val.target.value)} className="form-control " placeholder="Title Arabic" />
            </div>
            <div className="input-group m-3">
                <input type="text"onChange={val => setTitle(val.target.value)} className="form-control " placeholder="Title English" />
            </div> */}

            <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={handleUpload}
                className="form-control"
                placeholder="Upload Picture"
              />
            </div>
          </form>

          <button className="btn m-3 btn-success" onClick={addSlide}>
            {language["add"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newappslideshow;
