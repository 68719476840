import { useEffect, useState } from "react";
import { getDoc, query, updateDoc, doc } from "firebase/firestore/lite";

const Information = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [title_ar, setTitleAr] = useState("");
  const [slogo, setSlogo] = useState("");
  const [slogoAr, setSlogoAr] = useState("");
  const [moto, setMoto] = useState("");
  const [motoAr, setMotoAr] = useState("");
  const [openining, setOpeninings] = useState("");
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [minimum, setMinimum] = useState(0);
  const [delivery_fees, setDeliveryFees] = useState(0);
  const [vat, setVat] = useState(0);

  const getData = () => {
    setLoading(true);
    getDoc(query(doc(database, "main", "tJ682Usp1DrntvF3A20P")))
      .then((querySnapshot) => {
        setTitle(querySnapshot.data().title);
        setTitleAr(querySnapshot.data().title_ar);
        setSlogo(querySnapshot.data().slogo);
        setSlogoAr(querySnapshot.data().slogo_ar);
        setMoto(querySnapshot.data().moto);
        setMotoAr(querySnapshot.data().moto_ar);
        setOpeninings(querySnapshot.data().opening_hours);
        setMinimum(querySnapshot.data().minimum);
        setDeliveryFees(querySnapshot.data().delivery_fees);
        setMonthlyDiscount(querySnapshot.data().monthly_discount);
        setVat(querySnapshot.data().vat);
      })
      .catch((err) => console.log(err));
  };

  const editCategory = () => {
    updateDoc(doc(database, "main", "tJ682Usp1DrntvF3A20P"), {
      title: title,
      title_ar: title_ar,
      opening_hours: openining,
      moto: moto,
      moto_ar: motoAr,
      slogo: slogo,
      slogo_ar: slogoAr,
      minimum: minimum,
      delivery_fees: delivery_fees,
      monthly_discount: monthlyDiscount,
      vat: vat
    }).then((res) => {
      navigate("/information");
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["shop-information"]}</h4>
          <p className="card-description">{language["edit-shop-main-info"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["title"] + " " + language["arabic"]}
              </label>{" "}
              <br />
              <input
                type="text"
                value={title_ar}
                onChange={(val) => setTitleAr(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["title"] + " " + language["english"]}
              </label>{" "}
              <br />
              <input
                type="text"
                value={title}
                onChange={(val) => setTitle(val.target.value)}
                className="form-control "
                placeholder={language["title"] + " " + language["english"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["app-slogo"] + " " + language["arabic"]}
              </label>{" "}
              <br />
              <input
                type="text"
                value={slogoAr}
                onChange={(val) => setSlogoAr(val.target.value)}
                className="form-control "
                placeholder={language["slogo"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["app-slogo"] + " " + language["english"]}
              </label>{" "}
              <br />
              <input
                type="text"
                value={slogo}
                onChange={(val) => setSlogo(val.target.value)}
                className="form-control "
                placeholder={language["slogo"] + " " + language["english"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["moto"] + " " + language["arabic"]}
              </label>{" "}
              <br />
              <input
                type="text"
                value={motoAr}
                onChange={(val) => setMotoAr(val.target.value)}
                className="form-control "
                placeholder={language["moto"] + " " + language["arabic"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["moto"] + " " + language["english"]}
              </label>{" "}
              <br />
              <input
                type="text"
                value={moto}
                onChange={(val) => setMoto(val.target.value)}
                className="form-control "
                placeholder={language["moto"] + " " + language["english"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["opening-times"]}
              </label>{" "}
              <br />
              <input
                type="text"
                value={openining}
                onChange={(val) => setOpeninings(val.target.value)}
                className="form-control "
                placeholder={language["opening-hours"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["minimum-order"]}
              </label>{" "}
              <br />
              <input
                type="number"
                value={minimum}
                onChange={(val) => setMinimum(val.target.value)}
                className="form-control "
                placeholder={language["minimum-order"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                VAT (%)
              </label>{" "}
              <br />
              <input
                type="number"
                value={vat}
                onChange={(val) => setVat(val.target.value)}
                className="form-control "
                placeholder="Vat (%)"
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="" className="p-2 m-2">
                {language["delivery-fees"]}
              </label>{" "}
              <br />
              <input
                type="number"
                value={delivery_fees}
                onChange={(val) => setDeliveryFees(val.target.value)}
                className="form-control "
                placeholder={language["delivery-fees"]}
              />
            </div>
            <div className="input-group m-3">
              <label htmlFor="monthly-discount" className="p-2 m-2">
                {language["monthly-discount"]}
              </label>{" "}
              <br />
              <input
                id="monthly-discount"
                type="number"
                value={monthlyDiscount}
                onChange={(val) => setMonthlyDiscount(val.target.value)}
                className="form-control "
                placeholder={language["monthly-discount"]}
              />
            </div>
          </form>

          <button className="btn m-3 btn-success" onClick={editCategory}>
            {language["update"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Information;
