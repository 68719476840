import { createRef, useEffect, useState } from "react";
import {
  query,
  addDoc,
  collection,
  getDocs,
  getDoc,
  doc,
  serverTimestamp,
  where,
  updateDoc,
} from "firebase/firestore/lite";
import { useParams } from "react-router-dom";

const EditMerchant = ({ language, database, setLoading }) => {
  const [title, setTitle] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [area, setArea] = useState("");
  const [vatin, setVatin] = useState("");
  const [dMsg, setDMsg] = useState(null);
  const [position, setPosition] = useState(null);
  const [monthlyTarget, setMonthlyTarget] = useState(0);
  const { id } = useParams()
  
  useEffect(() => {
    setLoading(true);
    getDoc(query(doc(database, "merchents", id)))
      .then(doc => {
        setLoading(false);
        setTitle(doc.data().title)
        setMobile(doc.data().mobile)
        setName(doc.data().name)
        setArea(doc.data().area)
        setEmail(doc.data().email)
        setVatin(doc.data().vatin)
        setMonthlyTarget(doc.data().monthlyTarget?doc.data().monthlyTarget:0)
      })
      .catch(err => {
        //
        setLoading(false);
      })
  }, [])

  const handleUpdate = () => {
    if (title !== '' && mobile !== '' && name !== '') {
      updateDoc(doc(database, "merchents", id), {
        name: name,
        title: title,
        mobile: mobile,
        area: area,
        email: email,
        vatin: vatin,
        monthlyTarget: monthlyTarget,
      })
      .then((res) => {
        window.location.href = "/merchants";
      })
      .catch((err) => {
        setDMsg(language['error-msg'])
      });
    } else {
      setDMsg(language['required-error-msg'])
    }
  };

  const handleLocation = (url) => {
    let regex = new RegExp("@(.*),(.*),");
    let lat_long_match = url.target.value.match(regex);
    let temp_lat_lon = {
      lat: lat_long_match[1],
      lon: lat_long_match[2],
    };

    setPosition(temp_lat_lon);
  };

  return (
    <div className="row">
      <div className="col-12 stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">{language["edit"]} - {title}</h4>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["title"]}</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  defaultValue={title}
                  placeholder={language["title"]}
                  className="form-control"
                  onChange={(val) => setTitle(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["agent"]}</label>
                <input
                  id="name"
                  name="name"
                  defaultValue={name}
                  placeholder={language["full-name"]}
                  className="form-control"
                  onChange={(val) => setName(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["mobile"]}</label>
                <input
                  type="tel"
                  id="name"
                  name="name"
                  defaultValue={mobile}
                  placeholder={language["mobile"]}
                  className="form-control"
                  onChange={(val) => setMobile(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="email">{language["email"]}</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  defaultValue={email}
                  placeholder={language["email"]}
                  className="form-control"
                  onChange={(val) => setEmail(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="area">{language["area"]}</label>
                <input
                  id="area"
                  name="email"
                  defaultValue={area}
                  placeholder={language["area"]}
                  className="form-control"
                  onChange={(val) => setArea(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="location-on-map">
                  {language["location-on-map"]}
                </label>
                <input
                  type="text"
                  id="location-on-map"
                  name="location-on-map"
                  placeholder={
                    language["location-on-map"] + " (Google Maps Url)"
                  }
                  onChange={handleLocation}
                  className="form-control"
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="vatin">VATIN</label>
                <input
                  id="vatin"
                  name="vatin"
                  placeholder="VATIN"
                  defaultValue={vatin}
                  className="form-control"
                  onChange={(val) => setVatin(val.target.value)}
                />
              </div>
              
              
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="monthly-target">{language["monthly-target"]}</label>
                <input
                  id="monthly-target"
                  name="monthly-target"
                  defaultValue={monthlyTarget?monthlyTarget:0}
                  placeholder={language["monthly-target"]}
                  type="number"
                  className="form-control"
                  onChange={(val) => setMonthlyTarget(val.target.value)}
                />
              </div>

            </div>
            <hr />
            <p>{dMsg}</p>
            <div className="text-center mt-5">
              <button className="btn btn-success m-2" onClick={handleUpdate}>
                {language["save"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMerchant;