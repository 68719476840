import { createRef, useEffect, useState } from "react";
import {
  query,
  addDoc,
  collection,
  getDocs,
  getDoc,
  doc,
  serverTimestamp,
  where,
} from "firebase/firestore/lite";

const NewOrder = ({ language, database }) => {
  const [title, setTitle] = useState("");
  const [mobile, setMobile] = useState("");
  const [way, setWay] = useState("");
  const [house, setHouse] = useState("");
  const [email, setEmail] = useState("");
  const [area, setArea] = useState("");
  const [areas, setAreas] = useState(null);
  const [total, setTotal] = useState(0);
  const [delivery_time, setDeliveryTime] = useState("");
  const [delivery_day, setDeliveryDay] = useState("");
  const [delivery_times, setDeliveryTimeList] = useState(null);
  const [delivery, setDelivery] = useState("");
  const [dMsg, setDMsg] = useState(null);
  const [list, setList] = useState([]);
  const [optionsList, setOptionsList] = useState(null);
  const [qty, setQty] = useState(1);
  const [itemId, setItemId] = useState("");
  const [productsList, setProductsList] = useState(null);
  const [product, setProduct] = useState("");
  const [position, setPosition] = useState("");
  const [payment, setPayment] = useState("cash");
  const [orderType, setOrderType] = useState("delivery");
  const [delivery_fees, setDelivery_fees] = useState(0.5);
  const formData = createRef();

  useEffect(() => {
    let temp_arr = [];
    let branch = window.localStorage.getItem("branch");
    getDocs(query(collection(database, "products")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().availability[branch].enable === true) {
            temp_arr.push({
              id: doc.id,
              title: doc.data().title,
              title_ar: doc.data().title_ar,
              price: doc.data().price,
            });
          }
        });

        setProductsList(temp_arr);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    calucalateTotal();
  }, [list]);

  useEffect(() => {
    let temp_delivery_arr = [];
    let branch = window.localStorage.getItem("branch");
    getDocs(
      query(collection(database, "delivery"), where("branch", "==", branch))
    ).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        temp_delivery_arr.push({
          id: doc.id,
          title: doc.data().title,
          title_ar: doc.data().title_ar,
        });
      });
    });
    setAreas(temp_delivery_arr);
    //setArea(temp_delivery_arr[0]['title'])
  }, []);

  useEffect(() => {
    getDoc(query(doc(database, "main", "tJ682Usp1DrntvF3A20P"))).then((doc) => {
      setDeliveryTimeList(doc.data().delivery_times);
      setDelivery_fees(doc.data().delivery_fees);
      setDeliveryTime(doc.data().delivery_times[0]);
    });
  }, []);
  const handleAddProduct = (e) => {
    e.preventDefault();

    //let product_item = new FormData(formData)
    //let product_item = productsList.find(item => item.title.includes(product) || item.title_ar.includes(product))

    let sumTotal = 0;
    //let r = window.confirm(language["confirm"]);

    let temp_items = [...list];

    let item_details = productsList.filter(
      (val) =>
        val.title.toLowerCase() == formData.current[0].value.toLowerCase()
    );
    item_details[0].qty = qty;

    temp_items.push(item_details[0]);

    sumTotal = temp_items.reduce(
      (accumulator, currentValue) =>
        accumulator +
        parseFloat(currentValue.price) * parseInt(currentValue.qty),
      0
    );

    setList(temp_items);
    setQty(1);
    formData.current[0].value = "";
  };

  const handleRemoveItem = (item) => {
    let temp_arr = null;
    let r = window.confirm(language["confirm"]);

    if (r) {
      temp_arr = list.filter((val) => val.id !== item);
      setList(temp_arr);
    }
  };

  const calucalateTotal = () => {
    let temp_total = 0;
    const initialValue = 0;
    temp_total = list.reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) +
        parseFloat(currentValue.price) * parseInt(currentValue.qty),
      initialValue
    );
    //temp_total = list.reduce(((accumulator, currentValue) => accumulator + currentValue.price, 0))
    //temp_total += parseFloat(delivery_fees)
    setTotal(temp_total.toFixed(3));
  };

  const handleSearchProduct = (e) => {
    let term = e.target.value;
    let temp_options = [];

    if (term.length > 3) {
      setProduct(term);

      productsList.forEach((item) => {
        try {
          if (
            item.title.toLowerCase().includes(term) ||
            item.title_ar.includes(term)
          ) {
            temp_options.push(item);
          }
        } catch {
          //console.log(doc.data());
        }
      });

      setOptionsList(temp_options);
    }
  };

  const handleOrder = (op) => {
    let branch = window.localStorage.getItem("branch");
    let enable = false;
    let details = {
      delivery: {
        name: title ? title : "",
        mobile: mobile ? mobile : "",
        email: email ? email : "",
        way: way ? way : "",
        houseNo: house ? house : "",
        area: area,
        delivery: delivery_time,
        delivery_fees: [parseFloat(delivery_fees)],
        lat: position.lat?position.lat:0,
        lon: position.lon?position.lon:0,
        payment: payment,
        subscription: "0",
        payNow: false,
      },
      pickup: {
        make: "",
        color: "",
        plateNo: "",
        mobile: mobile ? mobile : "",
      },
      branch: branch ? branch : "",
    };

    if (list && list.length > 0) {
      enable = true;
    } else {
      setDMsg(language["required-error-msg"]);
    }
    if (enable === true) {
      console.log({
        orderId: Date.now(),
        type: orderType,
        userId: 8965656,
        details: details,
        items: list,
        total: parseFloat(total),
        canceled: false,
        delivered: false,
        newsletter: true,
        subscriptions: false,
        branch: branch,
        timestamp: serverTimestamp(),
      });
      addDoc(collection(database, "orders"), {
        orderId: Date.now(),
        type: orderType,
        userId: 8965656,
        details: details,
        items: list,
        total: parseFloat(total),
        canceled: false,
        delivered: false,
        newsletter: true,
        subscriptions: false,
        branch: branch,
        timestamp: serverTimestamp(),
      })
        .then((res) => {
          //
          if (op === "new") {
            window.location.reload();
          } else {
            window.location.href = "/orders/list";
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleLocation = (url) => {
    let regex = new RegExp("@(.*),(.*),");
    let lat_long_match = url.target.value.match(regex);
    let temp_lat_lon = {
      lat: lat_long_match[1],
      lon: lat_long_match[2],
    };

    setPosition(temp_lat_lon);
  };

  return (
    <div className="row">
      <div className="col-12 stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">{language["new"]}</h4>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["full-name"]}</label>
                <input
                  id="name"
                  name="name"
                  placeholder={language["full-name"]}
                  className="form-control"
                  onChange={(val) => setTitle(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="name">{language["mobile"]}</label>
                <input
                  type="number"
                  id="name"
                  name="name"
                  placeholder={language["mobile"]}
                  className="form-control"
                  onChange={(val) => setMobile(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="email">{language["email"]}</label>
                <input
                  id="email"
                  name="email"
                  placeholder={language["email"]}
                  className="form-control"
                  onChange={(val) => setEmail(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="area">{language["area"]}</label>
                <select
                  name="areas"
                  className="form-control"
                  onChange={(val) => setArea(val.target.value)}
                >
                  {areas &&
                    areas.map((item) => (
                      <option key={item.id} value={item.title}>
                        {language["language"] === "english"
                          ? item.title
                          : item.title_ar}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="way">{language["way"]}</label>
                <input
                  id="way"
                  name="way"
                  placeholder={language["way"]}
                  className="form-control"
                  onChange={(val) => setWay(val.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="house-no">{language["house-no"]}</label>
                <input
                  type="number"
                  id="house-no"
                  name="house-no"
                  placeholder={language["house-no"]}
                  className="form-control"
                  onChange={(val) => setHouse(val.target.value)}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="delivery-time">
                  {language["delivery-time"]}
                </label>
                <select
                  name="delivery-time"
                  className="form-control"
                  onChange={(val) => setDeliveryTime(val.target.value)}
                >
                  {delivery_times &&
                    delivery_times.map((item, index) => (
                      <option key={"dt-" + index} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="delivery-time">
                  {language["date"]}
                </label>
                <input type="date" onChange={val => setDeliveryDay(val.target.value)} className="form-control" />
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <label htmlFor="location-on-map">
                  {language["location-on-map"]}
                </label>
                <input
                  type="text"
                  id="location-on-map"
                  name="location-on-map"
                  placeholder={
                    language["location-on-map"] + " (Google Maps Url)"
                  }
                  onChange={handleLocation}
                  className="form-control"
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="payment-method">
                  {language["payment-method"]}
                </label>
                <select
                  name="delivery-time"
                  className="form-control"
                  onChange={(val) => setPayment(val.target.value)}
                >
                  <option value="cash">{language["cash"]}</option>
                  <option value="cod">{language["cod"]}</option>
                  <option value="online">Online</option>
                </select>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="order-type">{language["type"]}</label>
                <select
                  name="order-type"
                  className="form-control"
                  onChange={(val) => setOrderType(val.target.value)}
                >
                  <option value="delivery">{language["delivery"]}</option>
                  <option value="pickup">{language["pickup"]}</option>
                </select>
              </div>
            </div>
            <hr />
            <h4>{language["items"]}</h4>
            <form className="from row mb-3 mt-3" ref={formData}>
              <div className="col-5">
                {/* <label htmlFor="item-title">{language['title']}</label> */}
                <input
                  type="text"
                  list="products-list"
                  id="item-title"
                  placeholder={language["title"]}
                  className="form-control"
                  onChange={handleSearchProduct}
                />
                <datalist
                  name="products-list"
                  id="products-list"
                  onClick={(e) => console.log(e)}
                >
                  {optionsList &&
                    optionsList.length > 0 &&
                    optionsList.map((item) => (
                      <option key={item.title} value={item.title}>
                        {language["dir"] === "rtl" ? item.title_ar : item.title}
                      </option>
                    ))}
                </datalist>
              </div>
              <div className="col-3">
                {/* <label htmlFor="item-qty">{language['qty']}</label> */}
                <input
                  value={qty}
                  type="number"
                  id="item-qty"
                  min="1"
                  placeholder={language["qty"]}
                  className="form-control"
                  onChange={(val) => setQty(val.target.value)}
                />
              </div>
              <div className="col-2 align-top">
                <button
                  className="btn btn-sm btn-success align-top"
                  onClick={handleAddProduct}
                >
                  <i className="fa fa-plus"></i> {language["add"]}
                </button>
              </div>
            </form>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th>{language["title"]}</th>
                  <th>{language["qty"]}</th>
                  <th>{language["price"]}</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((item) => (
                    <tr key={"order-item-" + item.id}>
                      <td>
                        {language["language"] === "english"
                          ? item.title
                          : item.title_ar}
                      </td>
                      <td>{item.qty}</td>
                      <td>{item.price}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                {!list ||
                  (list.length === 0 && (
                    <tr className="text-center text-muted p-5 m-5">
                      <td>{language["empty-cart"]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <hr />
            <div className="row">
              <div className="col-3 fs-4">{language["delivery-fees"]}:</div>
              <div className="col-3 fs-4">
                <input
                  type="number"
                  step="2"
                  value={delivery_fees}
                  className="form-control"
                  onChange={(e) => {
                    setDelivery_fees(e.target.value);
                    calucalateTotal()
                  }}
                />
              </div>
              <div className="col-3 fs-3">{language["total"]}:</div>
              <div className="col-3 fs-3">
                OMR {total}
                {/* <input type="number" value={total} className="form-control" onChange={e => setTotal(e.target.value)} /> */}
              </div>
            </div>
            <p>{dMsg}</p>
            <div className="text-center mt-5">
              <button className="btn btn-success m-2" onClick={handleOrder}>
                {language["save"]}
              </button>
              <button
                className="btn btn-success m-2"
                onClick={() => handleOrder("new")}
              >
                {language["save-new"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrder;