import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore/lite";
import { Link } from "react-router-dom";

const Nav = ({
  logout,
  database,
  navigate,
  usersData,
  language,
  handleLanguage,
}) => {
  let goTo = useNavigate();
  const [data, setData] = useState(null);
  const [btanchTitle, setBranchTitle] = useState(null);

  const searchTyping = (val) => {
    let term = val.target.value;
    if (term.length > 3) {
      //document.location = document.location+'/'+term;
      goTo("/search/" + term);
    }
  };

  useEffect(() => {
    let temp_arr = [];
    let branch_id = window.localStorage.getItem("branch");
    let currentBranch = null;

    getDocs(query(collection(database, "branches")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
            enable: doc.data().enable,
            sort: doc.data().sort,
            date: doc.data().date,
          });

          if (branch_id === doc.id) {
            currentBranch = {
              title: doc.data().title,
              title_ar: doc.data().title_ar,
            };
          }
        });

        setData(temp_arr);
        setBranchTitle(currentBranch);
      })
      .catch((err) => {
        //setLoading(false)
      });

      /*fetch('https://buthoormart.com/api/s8024', {
        method: "POST"
      })
      .then(res => {
        //
      })
      .catch(res => {
        //
      })*/
  }, []);

  const handleBranch = (val) => {
    window.localStorage.setItem("branch", val);
    window.location.reload();
  };
  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/">
          <img
            src="https://portal.buthoormart.com/assets/images/logo.png"
            alt="logo"
          />
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img
            src="https://portal.buthoormart.com/assets/images/logo-mini.png"
            alt="logo"
          />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-toggle="minimize"
        >
          <span className="mdi mdi-menu"></span>
        </button>
        <div className="search-field d-none d-xl-block">
          <form className="d-flex align-items-center h-100" action="#">
            <div className="input-group">
              <div className="input-group-prepend bg-transparent">
                <i className="input-group-text border-0 mdi mdi-magnify"></i>
              </div>
              <input
                type="text"
                onClick={() => navigate("/search")}
                onKeyUp={searchTyping}
                className="form-control bg-transparent border-0"
                placeholder={language["search-text"]}
              />
            </div>
          </form>
        </div>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-language dropdown d-none d-md-block">
            <a
              className="nav-link dropdown-toggle"
              id="languageDropdown"
              href="#"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="nav-language-icon">
                <i className="mdi mdi-world" title="us" id="us"></i>
              </div>
              <div className="nav-language-text">
                <p className="mb-1 text-black">{language["language"]}</p>
              </div>
            </a>
            <div
              className="dropdown-menu navbar-dropdown"
              aria-labelledby="languageDropdown"
            >
              <a
                className="dropdown-item"
                href="#"
                onClick={() => handleLanguage("arabic")}
              >
                <div className="nav-language-icon mr-2">
                  <i className="flag-icon flag-icon-om" title="ae" id="ae"></i>
                </div>
                <div className="nav-language-text">
                  <p className="mb-1 text-black">Arabic</p>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => handleLanguage("english")}
              >
                <div className="nav-language-icon mr-2">
                  <i className="flag-icon flag-icon-gb" title="GB" id="gb"></i>
                </div>
                <div className="nav-language-text">
                  <p className="mb-1 text-black">English</p>
                </div>
              </a>
            </div>
          </li>

          {usersData && usersData.userType === "admin" && (
            <li className="nav-item nav-language dropdown d-none d-md-block">
              <a
                className="nav-link dropdown-toggle"
                id="languageDropdown"
                href="#"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nav-language-icon">
                  <i className="fa fa-share"></i>
                </div>
                <div className="nav-language-text">
                  <p className="mb-1 text-black">
                    {btanchTitle
                      ? language["dir"] === "rtl"
                        ? btanchTitle.title_ar
                        : btanchTitle.title
                      : language["branches"]}
                  </p>
                </div>
              </a>
              <div
                className="dropdown-menu navbar-dropdown"
                aria-labelledby="languageDropdown"
              >
                {data &&
                  data.map((item) => (
                    <div key={item.id}>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleBranch(item.id)}
                      >
                        <div className="nav-language-icon mr-2">
                          <i className="fa fa-shop"></i>
                        </div>
                        <div className="nav-language-text">
                          <p className="mb-1 text-black">
                            {language["dir"] === "rtl"
                              ? item.title_ar
                              : item.title}
                          </p>
                        </div>
                      </a>
                      <div className="dropdown-divider"></div>
                    </div>
                  ))}
              </div>
            </li>
          )}
          <li className="nav-item nav-profile dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="profileDropdown"
              href="#"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="nav-profile-img">
                <img
                  src="https://portal.buthoormart.com/assets/images/face28.png"
                  alt="image"
                />
              </div>
              <div className="nav-profile-text">
                <p className="mb-1 text-black">{usersData && usersData.name}</p>
              </div>
            </a>
            <div
              className="dropdown-menu navbar-dropdown dropdown-menu-right p-0 border-0 font-size-sm"
              aria-labelledby="profileDropdown"
              data-x-placement="bottom-end"
            >
              <div className="p-3 text-center bg-primary">
                <img
                  className="img-avatar img-avatar48 img-avatar-thumb"
                  src="https://portal.buthoormart.com/assets/images/face28.png"
                  alt=""
                />
              </div>
              <div className="p-2">
                {/* <h5 className="dropdown-header text-uppercase pl-2 text-dark">User Options</h5> */}
                {/* <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" href="#">
                <span>Profile</span>
                <span className="p-0">
                  <i className="mdi mdi-account-outline ml-1"></i>
                </span>
              </a> */}
                {/* <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" href="javascript:void(0)">
                <span>Settings</span>
                <i className="mdi mdi-settings"></i>
              </a> */}
                <div role="separator" className="dropdown-divider"></div>
                {/* <h5 className="dropdown-header text-uppercase  pl-2 text-dark mt-2">Actions</h5> */}
                <a
                  className="dropdown-item py-1 d-flex align-items-center justify-content-between"
                  onClick={logout}
                >
                  <span>{language["logout"]}</span>
                  <i className="mdi mdi-logout ml-1"></i>
                </a>
              </div>
            </div>
          </li>

          {/*<li className="nav-item dropdown">
          <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
            <i className="mdi mdi-bell-outline"></i>
            <span className="count-symbol bg-danger"></span>
          </a>
          <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
            <h6 className="p-3 mb-0 bg-primary text-white py-4">Notifications</h6>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item preview-item">
              <div className="preview-thumbnail">
                <div className="preview-icon bg-success">
                  <i className="mdi mdi-calendar"></i>
                </div>
              </div>
              <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                <h6 className="preview-subject font-weight-normal mb-1">Event today</h6>
                <p className="text-gray ellipsis mb-0"> Just a reminder that you have an event today </p>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item preview-item">
              <div className="preview-thumbnail">
                <div className="preview-icon bg-warning">
                  <i className="mdi mdi-settings"></i>
                </div>
              </div>
              <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                <h6 className="preview-subject font-weight-normal mb-1">Settings</h6>
                <p className="text-gray ellipsis mb-0"> Update dashboard </p>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item preview-item">
              <div className="preview-thumbnail">
                <div className="preview-icon bg-info">
                  <i className="mdi mdi-link-variant"></i>
                </div>
              </div>
              <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                <h6 className="preview-subject font-weight-normal mb-1">Launch Admin</h6>
                <p className="text-gray ellipsis mb-0"> New admin wow! </p>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <h6 className="p-3 mb-0 text-center">See all notifications</h6>
          </div>
        </li>*/}
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
        <button
          className="btn align-self-center"
          type="button"
          onClick={toggleFullScreen}
          title="Fullscreen"
        >
          <span className="mdi mdi-fullscreen"></span>
        </button>
      </div>
    </nav>
  );
};

export default Nav;
