import {
  query,
  getDoc,
  doc,
  updateDoc,
  addDoc,
  collection,
  getDocs,
  orderBy,
  setDoc,
} from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const Order = ({ database, setLoading, user, language, usersData }) => {
  const [tabs, setTabs] = useState("this-month");
  const [times, setTimes] = useState(null);
  const [todayName, setTodayName] = useState(language["today"]);
  const [todayDate, setTodayDate] = useState("");
  const [data, setData] = useState([]);
  const [ordersHistory, setOrdersHistory] = useState(null);
  const [orderPrintDate, setOrderPrintDate] = useState(null);

  const [show, setShow] = useState(false);
  const [productsList, setProductsList] = useState(null);
  const [optionsList, setOptionsList] = useState(null);
  const [qty, setQty] = useState(1);
  const [product, setProduct] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("other")

  const [list, setList] = useState(null);
  const [updated, setUpdated] = useState(Date.now());
  const { order_item } = useParams();

  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'omr',
  })

  const getOrders = () => {
    let temp_arr = [];
    setLoading(true);
    getDoc(query(doc(database, "orders", order_item)))
      .then((querySnapshot) => {
        setList(querySnapshot.data());
        setList((prevCart) => {
          return { ...prevCart, id: querySnapshot.id };
        });
      })
      .catch((err) => {
        /* */
      });
  };

  const handleCancel = () => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      setShowModal(false)
      updateDoc(doc(database, "orders", order_item), {
        canceled: true,
        cancelReason: cancelReason
      })
        .then((res) => {
          setUpdated(Date.now());
        })
        .catch((err) => {
          /* */
          console.log(err);
        });
    }
  };

  const handleDelevered = () => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      updateDoc(doc(database, "orders", order_item), {
        delivered: true,
      })
        .then((res) => {
          setUpdated(Date.now());
        })
        .catch((err) => {
          /* */
        });
    }
  };

  const whatsappOrder = () => {
    let msg = "";
    if (list && list.details.delivery) {
      msg += "Name: " + list.details.delivery.name + "\n";
      msg += "Mobile: " + list.details.delivery.mobile + "\n";
      msg +=
        "Location: https://www.google.com/maps/search/?api=1&query=" +
        list.details.delivery.lat +
        "," +
        list.details.delivery.lon +
        "\n";
      msg += "Area: " + list.details.delivery.area + "\n";
      msg += "Time: " + list.details.delivery.delivery + "\n";

      msg = encodeURIComponent(msg);
      window.open("https://wa.me/?text=" + msg, "system");
    }
  };

  const printOrder = (printDate) => {
    setOrderPrintDate(printDate);
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  const handleRefund = (payment_id, title, orderId) => {
    let p = window.prompt("Are you sure?", "Reason: User cancel the order.");
    if (p) {
      //let r = window.confirm("Are you sure?",
      //() => {
      const options = {
        key: "ULJDP2LDMTwd55V-MKw2TYf",
        payment_id: payment_id,
        reason: "User cancel the order",
        title: title,
      };

      fetch("https://www.buthoormart.com/api/refund_payment", {
        method: "POST",
        header: "application/json",
        body: JSON.stringify(options),
      })
      .then(json => json.json())
      .then((res) => {
        console.log(res);
        if (res.data.success === true) {
          updateDoc(doc(database, "orders", orderId), {
            status: "canceled",
            canceled: true,
          })
            .then((res) => {
              alert(
                `Order refunded successfully, Order (${orderId}) moved to canceled section.`
              );
              //window.location.reload();
            })
            .catch((err) => {
              //console.log(err);
            });
        } else {
          alert("Error: "+res.data.description+", Erorr Code: "+res.data.code);
        }
      })
      .catch((err) => {
        console.log(err);
        /* */
      });
    }
    //)
  };

  const getSubs = async () => {
    let q = await getDoc(doc(database, "subscriptions", order_item));

    if (q.exists()) {
      setOrdersHistory(q.data().items);
      console.log(q.data().items);
    }
  };

  useEffect(() => {
    getOrders();
    getSubs();
  }, [updated]);

  useEffect(() => {
    if (list) {
      calculateDays();
    }
  }, [list, updated]);

  useEffect(() => {
    //let mywindow = window.open('', '', 'height=500,width=768');
    let mywindow = document.getElementById("ipd").contentWindow.document;
    let data = list;

    if (data && data.details) {
      let print_total =
        data.type === "delivery"
          ? (
              parseFloat(data.total) +
              parseFloat(data.details.delivery.delivery_fees)
            ).toFixed(3)
          : parseFloat(data.total);
      let print_delivery_fees =
        data.type === "delivery"
          ? data.details.delivery.delivery_fees
          : "Pick up";

      mywindow.open();
      mywindow.write(
        "<html><head><title>" +
          data.orderId +
          '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 5px">'
      );
      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );
      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" />'
      );
      mywindow.write(
        '<div style="clear: both;"></div><br/><br/><div style="display: inline-block; width: 50%"><b>Date: ' +
          (orderPrintDate
            ? orderPrintDate
            : data.timestamp.toDate().toLocaleDateString()) +
          " <br/>Location: " +
          data.details.delivery.area +
          "<br/>Invoice NO: " +
          data.orderId +
          '</b></div><div style="display: inline-block; width: 50%; text-align: right"><b>Costumer Contact: ' +
          data.details.delivery.mobile +
          "</b></div>"
      );
      mywindow.write(
        '<br/><br/><div style="display: inline-block; width: 100%"><b><div  style="display: inline-block; width: 5%; border: 1px solid #000; text-align: center;">No.</div><div  style="display: inline-block; width: 40%; border: 1px solid #000;">Description</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Unit Price</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Qty</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Total Price</div></b></div>'
      );
      data.items.map((item, i) => {
        mywindow.write(
          '<div style="display: inline-block; width: 100%"><div  style="display: inline-block; width: 5%; border: 1px solid #000; text-align: center;">' +
            (i + 1) +
            '</div><div  style="display: inline-block; width: 40%; border: 1px solid #000;">' +
            item.title +
            '</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
            item.qty +
            '</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
            formatter.format(item.price) +
            '</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
            formatter.format(item.price * item.qty) +
            "</div></div>"
        );
      });

      mywindow.write(
        '<div style="display: inline-block; width: 100%"><b><div  style="display: inline-block; width: 5%; text-align: center;"></div><div  style="display: inline-block; width: 40%;"></div><div  style="display: inline-block; width: 8%; text-align: center;"></div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Subtotal</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
        formatter.format(data.total) +
          "</div></b></div>"
      );

      mywindow.write(
        '<div style="display: inline-block; width: 100%"><b><div  style="display: inline-block; width: 5%; text-align: center;"></div><div  style="display: inline-block; width: 40%;"></div><div  style="display: inline-block; width: 8%; text-align: center;"></div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Delivery</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
          print_delivery_fees +
          "</div></b></div>"
      );

      mywindow.write(
        '<div style="display: inline-block; width: 100%"><b><div  style="display: inline-block; width: 5%; text-align: center;"></div><div  style="display: inline-block; width: 40%;"></div><div  style="display: inline-block; width: 8%; text-align: center;"></div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Discount</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">0.000</div></b></div>'
      );

      mywindow.write(
        '<div style="display: inline-block; width: 100%"><b><div  style="display: inline-block; width: 5%; text-align: center;"></div><div  style="display: inline-block; width: 40%;"></div><div  style="display: inline-block; width: 8%; text-align: center;"></div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Total</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
        formatter.format(print_total) +
          "</div></b></div>"
      );

      let pytxt = data.payment === "cash" ? "Cash" : data.payment;
      let dtime =
        data.type === "delivery" ? data.details.delivery.delivery : "Pickup";

      mywindow.write(
        '<div style="clear: both"><br/><br/><br/><h5>Payment Method: ' +
          pytxt +
          "</h5></div>"
      );
      mywindow.write(
        "<br/><h5>Delivery Period: " +
          dtime +
          '</h5></p><br/><div style="display: block; float: right; ">'
      );
      let maplocation =
        "https://www.google.com/maps/search/?api=1&query=" +
        data.details.delivery.lat +
        "," +
        data.details.delivery.lon;
      //let maplocation =  "http://maps.google.com/maps?z=12&t=m&q=loc:"+list.details.delivery.lat + '+' + list.details.delivery.lon

      mywindow.write(
        '<div id="qrcode" style="width: 30%"></div><script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script><script type="text/javascript">new QRCode(document.getElementById("qrcode"), {text: "' +
          maplocation +
          '", width: 128, height: 128});</script>'
      );

      //mywindow.write('<script type="text/javascript">print()</script>')

      mywindow.write("</div></p>");

      mywindow.write(
        '<div style="padding: 10%; "></div><div style="clear: both; display: block; text-align: center;margin: 0 auto; width: 100%;"><h5>Thank you for choosing Buthoor</h5></div>'
      );

      mywindow.write(document.getElementById("pid").innerHTML);

      mywindow.close();
    }
  }, [list, updated]);

  const calculateDays = () => {
    let d = new Date();
    let days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    let today = d.getDay();
    let thisMonth =
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    let thisYear = d.getFullYear();
    let monthLength = new Date(thisYear, thisMonth, 0).getDate();
    let todayFullDate = d.getDate() + "/" + thisMonth + "/" + thisYear;
    let daysList = [];
    let ordersThisDay = [];

    for (let i = 0; i < monthLength; i++) {
      let index = i + 1;
      index = index < 10 ? "0" + index : index;
      let day = new Date(thisYear + "-" + thisMonth + "-" + index).getDay();

      daysList.push({
        id: i,
        day: index,
        name: days[day],
        date: index + "/" + thisMonth + "/" + thisYear,
        timestamp: thisYear + "-" + thisMonth + "-" + index,
        sort: day,
      });
    }

    let orderDate = list ? new Date(list.timestamp.toDate()) : new Date();
    if(list  && list.details){
    switch (parseInt(list.details.delivery.subscription)) {
      case 2:
        let init = 1;

        for (let index = 0; index < daysList.length; index++) {
          if (orderDate.getDay() === daysList[index].sort) {
            if (init <= index && ordersThisDay.length < 2) {
              ordersThisDay.push({
                id: daysList[index].id,
                day: daysList[index].name,
                date: daysList[index].date,
                timestamp: daysList[index].timestamp,
                delivered: false,
              });
              init = init != 14 ? init + 14 : 23;
            }
          }
        }

        break;

      case 4:
        daysList.forEach((element) => {
          if (orderDate.getDay() === element.sort) {
            ordersThisDay.push({
              id: element.id,
              day: element.name,
              date: element.date,
              timestamp: element.timestamp,
              delivered: false,
            });
          }
        });
        break;

      case 8:
        let init8 = 1;

        for (let index = 0; index < daysList.length; index++) {
          if (
            orderDate.getDay() === daysList[index].sort ||
            (orderDate.getDay() < 5
              ? orderDate.getDay() + 3
              : orderDate.getDay() - 3) === daysList[index].sort
          ) {
            if (init8 <= index) {
              ordersThisDay.push({
                id: daysList[index].id,
                day: daysList[index].name,
                timestamp: daysList[index].timestamp,
                date: daysList[index].date,
                delivered: false,
              });
              init8 = init8 + 2;
            }
          }
        }

        break;
    }
  }

    setData(ordersThisDay);
  };

  const handleDelivered = (items) => {
    setLoading(true);
    let r = window.confirm(language["confirm"]);
    let ok = true;
    if (r) {
      for (let index = 0; index < ordersHistory.length; index++) {
        if (items.date === ordersHistory[index].date) {
          ok = false;
        }
      }
      let tempArr = [];
      if (ok) {
        items.delivered = true;

        tempArr = [...ordersHistory, items];

        setOrdersHistory(tempArr);

        //return
        setDoc(doc(database, "subscriptions", order_item), { items: tempArr })
          .then((res) => {
            setLoading(false);
          })

          .catch(() => setLoading(false));
      } else {
        alert(language["delivered"] + "!");
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    let temp_arr = [];
    getDocs(query(collection(database, "products")))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp_arr.push({
            id: doc.id,
            title: doc.data().title,
            title_ar: doc.data().title_ar,
            describtion: doc.data().describtion,
            describtion_ar: doc.data().describtion_ar,
            price: doc.data().price,
            image: doc.data().image,
            category: doc.data().category,
          });
        });
        setProductsList(temp_arr);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleRemoveItem = (item_id) => {
    let r = window.confirm(language["confirm"]);
    let new_items = null;
    let sumTotal = 0;

    if (list && r) {
      new_items = list.items.filter((item) => item.id !== item_id);
      sumTotal = new_items.reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.price) * parseInt(currentValue.qty),
        0
      );

      updateDoc(doc(database, "orders", order_item), {
        items: new_items,
        total: sumTotal.toFixed(3),
      })
        .then((res) => {
          setUpdated(Date.now());
        })
        .catch((err) => {
          //console.log(error)
        });
    }
  };

  const handleAddProduct = (e) => {
    e.preventDefault();
    let product_item = productsList.find(
      (item) => item.title.includes(product) || item.title_ar.includes(product)
    );

    let sumTotal = 0;
    let r = window.confirm(language["confirm"]);

    if (product_item && list && product !== "" && r) {
      let temp_items = [...list.items];
      temp_items.push({
        id: product_item.id,
        title: product_item.title,
        title_ar: product_item.title_ar,
        qty: qty,
        price: product_item.price,
      });

      sumTotal = temp_items.reduce(
        (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.price) * parseInt(currentValue.qty),
        0
      );

      updateDoc(doc(database, "orders", order_item), {
        items: temp_items,
        total: sumTotal.toFixed(3),
      })
        .then((res) => {
          setUpdated(Date.now());
        })
        .catch((err) => {
          //console.log(error)
        });
    }
  };

  const handleSearchProduct = (e) => {
    let term = e.target.value;
    let temp_options = [];

    if (term.length > 3) {
      setProduct(term);

      productsList.forEach((item) => {
        try {
          if (
            item.title.toLowerCase().includes(term) ||
            item.title_ar.includes(term)
          ) {
            temp_options.push(item);
          }
        } catch {
          //console.log(doc.data());
        }
      });
      setUpdated(Date.now());
      setOptionsList(temp_options);
    }
  };

  return (
    <>
    <div className={`modal ${showModal?'d-block':''}`} style={{backgroundColor: "#000000c7"}} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog mt-5" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{language['cancel']}</h5>
            <button onClick={() => setShowModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-lg-4 col-md-4 col-sm-12 p-2">
              <label htmlFor="cancel-reason">{language["cancel-reason"]}</label>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 row">
              <select
                name="cancel-reason"
                id="cancel-reason"
                className="form-control col-12"
                onChange={(val) => setCancelReason(val.target.value)}
              >
                <option value="other">{language['other']}</option>
                <option value="customer-cancel">{language['customer-cancel']}</option>
                <option value="items-damage">{language['items-damage']}</option>
                <option value="item-unavailable">{language['item-unavailable']}</option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={() => setShowModal(false)} type="button" className="btn btn-secondary" data-dismiss="modal">{language['close']}</button>
            <button onClick={handleCancel} type="button" className="btn btn-danger">{language['cancel']}</button>
          </div>
        </div>
      </div>
    </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{list && list.orderId}</h4>
              <p className="card-description text-dark">
                {language["date"]}{" "}
                <code>
                  {list &&
                    list.timestamp.toDate().toDateString() +
                      " - " +
                      list.timestamp.toDate().toLocaleTimeString()}
                </code>
              </p>
              <p>
                {list && list.details && list.type === "delivery" && (
                  <>
                    <strong className="fs-4">
                      {list.details.delivery.name}
                    </strong>{" "}
                    <br />
                    <b>{language["mobile"]}: </b> {list.details.delivery.mobile}{" "}
                    <br />
                    <b>{language["email"]}: </b> {list.details.delivery.email}{" "}
                    <br />
                    <b>{language["way"]}: </b> {list.details.delivery.way}{" "}
                    <br />
                    <b>{language["house-no"]}: </b>{" "}
                    {list.details.delivery.houseNo} <br />
                    <b>{language["area"]}: </b> {list.details.delivery.area}{" "}
                    <br />
                    <b>{language["delivery-time"]}: </b>{" "}
                    {list.details.delivery.delivery} <br />
                    <b>{language['date']}:</b> {" "}
                    {list.details.delivery.deliveryDate} <br/>
                    <b>{language["payment-method"]}: </b>{" "}
                    {list.details.delivery.payment === "cod"
                      ? "Bank Transfer"
                      : list.details.delivery.payment}{" "}
                    <br />
                    <br />
                    {list.details.delivery.payment === "online" && (
                      <>
                        <b>{language["payment-status"]}:</b>{" "}
                        {list.paid ? language[list.paid] : language["unpaid"]}
                      </>
                    )}
                    <br />
                    {list.details.delivery.payment === "online" && (
                      <>
                        <b>{language["payment-fee"]}:</b>{" "}
                        {formatter.format(parseFloat(list.fee) / 1000)}
                      </>
                    )}
                    <br />
                    {list.details.delivery.payment === "online" && (
                      <>
                        <b>Payment Invoice:</b> {list.invoice}
                      </>
                    )}
                  </>
                )}

              {list && list.details && list.type === "Merchant" && (
                  <>
                    <strong className="fs-4">
                      {list.details.delivery.name}
                    </strong>{" "}
                    <br />
                    <b>{language["mobile"]}: </b> {list.details.delivery.mobile}{" "}
                    <br />
                    <b>{language["email"]}: </b> {list.details.delivery.email}{" "}
                    <br />
                    <b>{language["area"]}: </b> {list.details.delivery.area}
                    <br />
                    {list.details.payment === "cash"
                      ? language['cash']
                      : list.details.delivery.payment}
                    <br />
                    <br />
                    {list.details.payment === "online" && (
                      <>
                        <b>{language["payment-status"]}:</b>{" "}
                        {list.paid ? language[list.paid] : language["unpaid"]}
                      </>
                    )}
                    <br />
                    {list.details.payment === "online" && (
                      <>
                        <b>{language["payment-fee"]}: </b>
                        {formatter.format(parseFloat(list.fee) / 1000)}
                      </>
                    )}
                    <br />
                    {list.details.delivery.payment === "online" && (
                      <>
                        <b>Payment Invoice:</b> {list.invoice}
                      </>
                    )}
                  </>
                )}

                {list && list.details && list.type === "pickup" && (
                  <>
                    <b>{language["make"]}: </b> {list.details.pickup.make}{" "}
                    <br />
                    <b>{language["color"]}: </b> {list.details.pickup.color}{" "}
                    <br />
                    <b>{language["plate-no"]}: </b>{" "}
                    {list.details.pickup.plateNo} <br />
                  </>
                )}
              </p>
              <hr />
              {/* {list && (list.type === 'pickup'?list.total:(parseFloat(list.total) + parseFloat(list.details.delivery.delivery_fees)).toFixed(3))} */}
              <strong>
                {list && list.details && list.type === "delivery" && (
                  <div className="row">
                    <div className="col-2">{language["delivery"]}:</div>
                    <div className="col-2">
                      {list &&
                        formatter.format(list.details.delivery.delivery_fees)}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-2">{language["type"]}:</div>
                  <div className="col-2">
                    {list && list.type}
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">{language["subtotal"]}:</div>
                  <div className="col-2">
                    {list && formatter.format(list.total)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">{language["total"]}:</div>
                  <div className="col-2">
                    {list &&
                      (list.type === "delivery"
                        ? formatter.format(
                            parseFloat(list.total) +
                            parseFloat(list.details.delivery.delivery_fees)
                          )
                        : formatter.format(list.total))}
                  </div>
                </div>
              </strong>

              {list && list.canceled === true && (
                <div className="p-3">
                  <b>{language["status"]}:</b> {language["cancel"]}
                  <br/>
                  <b>{language["cancel-reason"]}:</b> {list.cancelReason?language[list.cancelReason]:''}
                </div>
              )}
              {list && list.details && list.delivered && (
                <p>
                  <b>
                    {language["status"]}: {language["delivered"]}
                  </b>
                </p>
              )}
              {list && list.details && !list.canceled && !list.delivered && (
                <p>
                  <b>
                    {language["status"]}: {language["pending"]}
                  </b>
                </p>
              )}
              <div className="template-demo">
                <button
                  type="button"
                  onClick={printOrder}
                  className="btn btn-sm btn-info btn-rounded btn-fw"
                >
                  <i className="mdi mdi-printer"></i> {language["print"]}
                </button>
                {list && list.details && list.type === "delivery" && (
                  <button
                    type="button"
                    onClick={whatsappOrder}
                    className="btn btn-sm btn-success btn-rounded btn-fw"
                  >
                    <i className="mdi mdi-whatsapp"></i>{" "}
                    {language["send-to-whatsapp"]}
                  </button>
                )}

                {list && list.details && list.type === "delivery" && (
                  <button
                    type="button"
                    onClick={() =>
                      window.open(
                        "https://wa.me/968" + list.details.delivery.mobile,
                        "system"
                      )
                    }
                    className="btn btn-sm btn-success btn-rounded btn-fw"
                  >
                    <i className="mdi mdi-whatsapp"></i>{" "}
                    {list.details.delivery.mobile}
                  </button>
                )}

                {list && list.details && list.type === "delivery" && (
                  <button
                    type="button"
                    onClick={() =>
                      window.open(
                        "https://www.google.com/maps/search/?api=1&query=" +
                          list.details.delivery.lat +
                          "," +
                          list.details.delivery.lon,
                        "system"
                      )
                    }
                    className="btn btn-sm btn-info btn-rounded btn-fw"
                  >
                    <i className="mdi mdi-marker"></i> Google Maps
                  </button>
                )}

                <hr />
                {list && list.details && !list.delivered && !list.canceled && (
                  <button
                    type="button"
                    onClick={handleDelevered}
                    className="btn btn-sm btn-primary btn-rounded btn-fw"
                  >
                    <i className="mdi mdi-truck"></i> {language["delivered"]}
                  </button>
                )}

                {list && !list.canceled && (
                  <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    className="btn btn-sm btn-danger btn-rounded btn-fw"
                  >
                    <i className="mdi mdi-close-circle"></i>{" "}
                    {language["cancel"]}
                  </button>
                )}

                {/* {list && list.details && list.details.delivery.payment === "online" && (
                  <button
                    type="button"
                    onClick={() =>
                      handleRefund(
                        list.payment_id,
                        list.details.delivery.name,
                        list.id
                      )
                    }
                    className="btn btn-sm btn-danger btn-rounded btn-fw"
                  >
                    <i className="mdi mdi-close-circle"></i>{" "}
                    {language["refund"]}
                  </button>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            {/* <h4 className="card-title">Order Item</h4> */}
            <p className="card-description">
              {" "}
              {language["items"]}{" "}
              {usersData &&
                list &&
                usersData.userType === "admin" &&
                list.delivered === false && (
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => setShow(!show)}
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                )}
            </p>
            {show && (
              <form className="from row mb-3 mt-3">
                <div className="col-5">
                  <label htmlFor="item-title">{language["title"]}</label>
                  <input
                    type="text"
                    list="products-list"
                    id="item-title"
                    placeholder={language["title"]}
                    className="form-control"
                    onChange={handleSearchProduct}
                  />
                  <datalist
                    name="products-list"
                    id="products-list"
                    onClick={(e) => console.log(e)}
                  >
                    {optionsList &&
                      optionsList.length > 0 &&
                      optionsList.map((item) => (
                        <option key={item.title}>
                          {language["dir"] === "rtl"
                            ? item.title_ar
                            : item.title}
                        </option>
                      ))}
                  </datalist>
                </div>
                <div className="col-3">
                  <label htmlFor="item-qty">{language["qty"]}</label>
                  <input
                    type="number"
                    id="item-qty"
                    min="1"
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                    placeholder={language["qty"]}
                    className="form-control"
                  />
                </div>
                <div className="col-2 align-middle">
                  <br />
                  <button
                    className="btn btn-success form-control"
                    onClick={handleAddProduct}
                  >
                    <i className="fa fa-plus"></i> {language["add"]}
                  </button>
                </div>
              </form>
            )}

            <table className="table table-hover">
              <thead>
                <tr>
                  <th>{language["title"]}</th>
                  <th>{language["qty"]}</th>
                  <th>{language["price"]}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.items &&
                  list.items.map((item) => (
                    <tr key={"order-item-" + item.id}>
                      <td>
                        {language["language"] === "english"
                          ? item.title
                          : item.title_ar}
                      </td>
                      <td>{item.qty}</td>
                      <td>
                        {list.type === 'Talabat' || list.type === 'TmDone'?formatter.format(item.talabat):formatter.format(item.price)}
                      </td>
                      <td>{item.bulkType?item.bulkType:''}</td>
                      {usersData && usersData.userType === "admin" && show && (
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleRemoveItem(item.id)}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Order Subscription  */}

      {list && list.details && list.subscriptions && (
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{language["subscription"]}</h4>

              <p className="card-description">
                {language[list.details.delivery.subscription + "-a-month"]}
                <br />
                {language["delivery-time"]}: {list.details.delivery.delivery}
              </p>

              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    onClick={() => setTabs("this-month")}
                    className={
                      tabs === "this-month"
                        ? "nav-link text-muted active"
                        : "nav-link text-muted"
                    }
                    role="button"
                  >
                    {language["this-month"]}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => setTabs("history")}
                    className={
                      tabs === "history"
                        ? "nav-link text-muted active"
                        : "nav-link text-muted"
                    }
                    role="button"
                  >
                    {language["delivered"]}
                  </a>
                </li>
              </ul>

              {tabs === "this-month" && (
                <ul className="list-group">
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <li
                        key={"lg-" + item.id}
                        className="list-group-item list-group-flush d-flex justify-content-between align-items-center"
                      >
                        <div>
                          {item.date} ({item.day})
                        </div>
                        {new Date() >= new Date(item.timestamp) && (
                          <div
                            className={`badge badge-${
                              item.delivered ? "success" : "danger"
                            } `}
                          >
                            {
                              language[
                                item.delivered ? "delivered" : "undelivered"
                              ]
                            }
                          </div>
                        )}
                        {new Date() >= new Date(item.timestamp) &&
                          !item.delivered && (
                            <div>
                              <button
                                onClick={function () {
                                  handleDelivered(item);
                                }}
                                className="btn btn-success"
                              >
                                <i className="fa fa-truck"></i>{" "}
                                {language["confirm-delivery"]}
                              </button>
                            </div>
                          )}
                        {item.delivered && (
                          <div>
                            <button
                              onClick={() => printOrder(item.date)}
                              className="btn btn-secondary"
                            >
                              <i className="fa fa-print"></i>{" "}
                              {language["print"]}
                            </button>
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              )}

              {tabs === "history" && (
                <ul className="list-group">
                  {ordersHistory &&
                    ordersHistory.length > 0 &&
                    ordersHistory.map((order) => (
                      <li
                        key={"lg-" + order.id}
                        className="list-group-item list-group-flush d-flex justify-content-between align-items-center"
                      >
                        <div>
                          {order.date} ({order.day})
                        </div>
                        <div
                          className={`badge badge-${
                            order.delivered ? "success" : "danger"
                          } `}
                        >
                          {
                            language[
                              order.delivered ? "delivered" : "undelivered"
                            ]
                          }
                        </div>
                        {order.delivered && (
                          <div>
                            <button
                              onClick={() => printOrder(order.date)}
                              className="btn btn-secondary"
                            >
                              <i className="fa fa-print"></i>{" "}
                              {language["print"]}
                            </button>
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}

      <div id="pid" className="hidden"></div>
    </>
  );
};

export default Order;
