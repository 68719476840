import { collection, getDocs, orderBy, query, where } from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/green.css"

import locale from '../data/Locale'
import { Link } from "react-router-dom";

export default function ShopReport({ database, setLoading, user, language }) {
  const MyDate = new Date();
  const MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    "01";
  const lastDay = new Date(MyDate.getFullYear(), MyDate.getMonth() + 1, 0);
  const toDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
  lastDay.getDate();

  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'omr',
  })

  const [fromDate, setFromDate] = useState(MyDateString)
  const [toDate, setToDate] = useState(toDateString)
  const [type, setType] = useState("revenue")
  const [data, setData] = useState(null)
  const [updated, setUpdated] = useState(Date.now())
  const [shadowData, setShadowData] = useState(null)
  const [csv, setCsv] = useState(null)
  const [total, setTotal] = useState(0)
  const [retailTotal, setRetailTotal] = useState(0)
  const [onlineTotal, setOnlineTotal] = useState(0)
  const [appsTotal, setAppsTotal] = useState(0)
  const [otherIncome, setOtheIncomeTotal] = useState(0)
  const [canceledTotal, setCanceledTotal] = useState(0)
  const [expensesList, setExpensesList] = useState(null)
  const [expensesTotal, setExpensesTotal] = useState(null)
  const [orderTypeList, setOrderTypeList] = useState(null)
  const [expenses, setExpenses] = useState(null)
  const [opExTotal, setOpExTotal] = useState(0)
  const [rawTotal, setRawTotal] = useState(0)
  const [merchantsLists, setMerchantsLists] = useState(null)
  const [merchantsTotal, setMerchantsTotal] = useState(0)
  const [shadowExpenses, setShadowExpenses] = useState(null)
  const [printType, setPrintType] = useState('revenue')
  const [grossTotal, setGrossTotal] = useState(0)
  const [values, setValues] = useState(MyDateString + ' ~ ' + toDateString)
  const [opType, setOType] = useState('')

  const expenses_headers = [
    "rent",
    "salary",
    "gov-expenses",
    "bank-charges",
    "water-electricity",
    "repairs-maintenance",
    "printing-stationery",
    "mobile-phone",
    "cleaning-utenils",
    "car-instalments",
    "car-maintenance",
    "advertising-expenses",
    "fuel-expenses",
    "sadaqa",
    "app-commotions",
    "packaging-expenses",
    "assets-materials-equipments",
    "meeting-fees",
    "sandwiches",
    "drinking-water",
    "stickers",
    "fruits-vegetables",
    "furnitures",
    "entertainment-gist",
    "other"
  ]

  useEffect(() => {
    handleDisplay()

    let temp_arr = []
    getDocs(query(collection(database, "orderTypes"), where("enable", "==", true), orderBy("sort", "asc")))
    .then(res => {
      res.forEach(doc => {
        temp_arr.push({
          id: doc.id,
          name: doc.data().name,
          color: doc.data().color,
          enable: doc.data().enable
        })
      })
      setOrderTypeList(temp_arr)
    })
    .catch(err => {
      //console.log(err)
    })
  }, [])

  const handleDisplay = () => {
    setExpensesTotal(0)
    //setExpensesList(0)
    setTotal(0)
    setOtheIncomeTotal(0)
    setData(null)
    setCsv('')
    setTotal(0)
    setRetailTotal(0)
    setAppsTotal(0)
    setOnlineTotal(0)
    setCanceledTotal(0)
    setUpdated(Date.now())
    setLoading(true)
    let temp_arr = []
    let temp_expenses = []
    let temp_exp_arr = []
    expenses_headers.map(item => {
      temp_expenses.push({
        name: item,
        total: 0
      })
    })

    let temp_total = 0
    let temp_retail = 0
    let temp_online = 0
    let temp_apps = 0
    let temp_oincome = 0
    let temp_canceled_total = 0
    let temp_exp_total = 0
    let temp_csv = 0
    let temp_op_exp = 0
    let selectedNextDay = new Date(toDate)
    let nextDay = new Date(selectedNextDay)
    nextDay.setDate(selectedNextDay.getDate() + 1)

    getDocs(query(collection(database, "orders"), where("timestamp", ">=", new Date(fromDate + ' 00:00:00')), where("timestamp", "<=", nextDay)))
    .then(res => {
      res.forEach(doc => {
        if(doc.data().type === 'shop' && !doc.data().canceled){
          temp_retail += doc.data().total
          temp_total += doc.data().total
        } else if((doc.data().type === 'delivery' || doc.data().type === 'pickup') && !doc.data().canceled){
          if(doc.data().type === 'delivery'){
            temp_online += parseFloat(doc.data().total) + parseFloat(doc.data().details.delivery.delivery_fees)
            temp_total += parseFloat(doc.data().total) + parseFloat(doc.data().details.delivery.delivery_fees)
          } else {
            temp_apps += doc.data().total
            temp_total += doc.data().total
          }
        } else {
          if(!doc.data().canceled){
            temp_apps += doc.data().total
            temp_total += doc.data().total
          }
        }

        if(doc.data().canceled){
          if(doc.data().type === 'delivery'){
            temp_canceled_total += parseFloat(doc.data().total) + parseFloat(doc.data().details.delivery.delivery_fees)
          } else {
            temp_canceled_total += doc.data().total
          }
        }

        temp_arr.push({
          id: doc.id,
          timestamp: doc.data().timestamp,
          type: doc.data().type,
          delivered: doc.data().delivered,
          total: doc.data().type === "delivery"
              ? formatter.format(
                  parseFloat(doc.data().total) +
                  parseFloat(doc.data().details.delivery.delivery_fees)
                )
              : doc.data().total,
          items: doc.data().items.length,
          orderId: doc.data().orderId,
          canceled: doc.data().canceled,
        })

        temp_csv +=
        doc.data().orderId +
        "," +
        doc.data().timestamp.toDate().toLocaleDateString() +
        "," +
        doc.data().type +
        "," +
        (doc.data().type === "delivery"
            ? formatter.format(
                parseFloat(doc.data().total) +
                parseFloat(doc.data().details.delivery.delivery_fees)
              )
            : doc.data().total) +
        "," +
        doc.data().items.length +
        "\n";
      })
      setShadowData(temp_arr)
      setData(temp_arr)
      setCsv(temp_csv)
      setTotal(temp_total)
      setRetailTotal(temp_retail)
      setAppsTotal(temp_apps)
      setOnlineTotal(temp_online)
      setCanceledTotal(temp_canceled_total)
      setUpdated(Date.now())
      setLoading(false)
    })
    .catch(err => {
      //console.log(err);
    })

    //getDocs(query(collection(database, "expenses"), where("timestamp", ">=", new Date(fromDate)), where("timestamp", "<=", nextDay)))
    getDocs(query(collection(database, "expenses"), where("date", ">=", fromDate), where("date", "<=", nextDay.toDateString())))
    .then(res => {
      res.forEach(doc => {
        temp_exp_arr.push({
          id: doc.id,
          orderId: doc.data().orderId,
          type: doc.data().type,
          image: doc.data().image,
          canceled: doc.data().canceled,
          amount: doc.data().amount,//formatter.format(doc.data().amount),
          enable: doc.data().enable,
          desc: doc.data().desc,
          timestamp: doc.data().timestamp,
          branch: doc.data().branch,
        });

        if(doc.data().canceled){
          temp_oincome += doc.data().amount
        } else {
          temp_exp_total += doc.data().amount
        }

        if(doc.data().type === 'app-commotions' || doc.data().type === 'packaging-expenses'){
          temp_op_exp += doc.data().amount
        }

        temp_expenses.map(item => {
          if(doc.data().type === item.name && !doc.data().canceled){
            item.total += doc.data().amount
          }
        })
      })
      setExpenses(temp_exp_arr)
      setShadowExpenses(temp_exp_arr)
      setOpExTotal(temp_op_exp)
      setExpensesTotal(temp_exp_total)
      setExpensesList(temp_expenses)
      setTotal(ol => ol + temp_oincome)
      setOtheIncomeTotal(temp_oincome)
    })
    .catch(err => {
      //console.log(err);
    })

    let temp_raw_total = 0

    getDocs(query(collection(database, "inventory"), where('timestamp', ">=", new Date(fromDate).getTime()), where('timestamp', "<=", new Date(toDate).getTime())))
    //, where("timestamp", ">=", new Date(fromDate)), where("timestamp", "<=", new Date(toDate))))
    .then(res => {
      res.forEach(doc => {
        temp_raw_total += parseFloat(doc.data().bulk)
      })

      setRawTotal(temp_raw_total)
    })
    .catch(err => {
      //console.log(err);
    })


    //getDocs(query(collection(database, "merchant-orders"), where("timestamp", ">=", new Date(fromDate)), where("timestamp", "<=", nextDay)))
    getDocs(query(collection(database, "merchant-orders"), where("invoiceDate", ">=", fromDate), where("invoiceDate", "<=", nextDay.toLocaleDateString('en-CA'))))
    .then(querySnapshot => {
      let temp_arr = []
      let temp_total = 0
      
      querySnapshot.forEach((doc) => {
        temp_arr.push({
          id: doc.id,
          timestamp: doc.data().timestamp,
          total: doc.data().total,
          items: doc.data().items.length,
          merchantBranch: doc.data().merchantBranch,
          orderId: doc.data().orderId,
        });

        doc.data().items.map(item => {
          let cqty = item.cqty
          let qty = parseInt(item.qty)
          let price = parseFloat(item.price)
          let vat = doc.data().vat;

          temp_total += price * (qty - (cqty?cqty:0)) + ((qty - (item.cqty?cqty:0)) * (price * (vat / 100)))

          // if(!isNaN(cqty) && cqty !== '' && cqty !== 0){
          //   temp_total += (price * cqty) +((cqty * price) * (vat / 100))
          // } else {
          //   temp_total += (price * qty) +((qty * item.price) * ( vat / 100))
          // }
          
        })
        //console.log(temp_total);
      });

      temp_total = !isNaN(temp_total)?temp_total:0

      setMerchantsLists(temp_arr)
      setMerchantsTotal(temp_total)
    })

    
  };

  useEffect(() => {
      let mywindow = document.getElementById("ipd").contentWindow.document;
      let pkg_total = expensesList?expensesList[15].total:0
      let app_com_total = expensesList?expensesList[14].total:0
      let et = pkg_total + app_com_total + rawTotal
      let temp_op_exp_total = formatter.format(et)
      let gt = (merchantsTotal + parseFloat(total)) - parseFloat(et)
      let net_gross_total = formatter.format(gt)
      setGrossTotal(net_gross_total)

      mywindow.open();

      let gapTable = `<table  style="width: 100%; text-align: center;" border="1">
      <th>
      <td><h3>Profit and loss بيان الربح والخسارة</h3></td>
      </th>
      <tr>
        <td><b>Account Name</b></td>
        <td><b>اسم الحساب</b></td>
        <td><b>الاجمالي ر.ع. / Total OMR</b></td>
      </tr>
      <tr>
        <td><b>REVENUES</b></td>
        <td><b>إيرادات</b></td>
        <td></td>
      </tr>
      <tr>
        <td>Sales Retail</td>
        <td>مبيعات التجزئة</td>
        <td>${formatter.format(retailTotal)}</td>
      </tr>
      <tr>
        <td>Sales Delivery Applications</td>
        <td>المبيعات - تطبيقات التوصيل</td>
        <td>${formatter.format(appsTotal)}</td>
      </tr>
      <tr>
        <td>Sales Website - Application</td>
        <td>المبيعات - الموقع والتطبيق</td>
        <td>${formatter.format(onlineTotal)}</td>
      </tr>
      <tr>
        <td>Sales Website - Merchants</td>
        <td>المبيعات - التجار</td>
        <td>${formatter.format(merchantsTotal)}</td>
      </tr>
      <tr>
        <td>Other Income</td>
        <td>مصدر دخل آخر</td>
        <td>${formatter.format(otherIncome)}</td>
      </tr>
      <tr>
        <td>Sale Return</td>
        <td>مردودات مبيعات</td>
        <td>${formatter.format(canceledTotal)}</td>
      </tr>
      <tr>
        <td><b>Total Revenues</b></td>
        <td><b>اجمالي الايرادات</b></td>
        <td><b>${formatter.format((total + merchantsTotal))}</b></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td><b>Operating expenses</b></td>
        <td><b>مصاريف التشغيل</b></td>
        <td></td>
      </tr>
      <tr>
        <td>COGS Raw Materials</td>
        <td>تكلفة المواد الخام</td>
        <td>${formatter.format(rawTotal)}</td>
      </tr>
      <tr>
        <td>Packaging Expenses</td>
        <td>تكلفة مصاريف التغليف</td>
        <td>${formatter.format(pkg_total)}</td>
      </tr>
      <tr>
        <td>App. Commission</td>
        <td>عمولة التطبيقات</td>
        <td>${formatter.format(app_com_total)}</td>
      </tr>
      <tr>
        <td><b>Operating expenses Total</b></td>
        <td><b>اجمالي مصاريف التشغيل</b></td>
        <td><b>${temp_op_exp_total}</b></td>
      </tr>
      <tr>
        <td><b>Gross Profit</b></td>
        <td><b>مجمل الربح</b></td>
        <td><b>${net_gross_total}</b></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td><b>Gen. & Admin Expenses</b></td>
        <td><b>المصاريف الادارية والعمومية</b></td>
        <td></td>
      </tr>
      `

      let exp_total = 0
      if(expensesList){
        for (let index = 0; index < expensesList.length; index++){ 
          if(index !== 14 && index !== 15){
            exp_total += expensesList[index].total
              gapTable += `<tr>
              <td>${locale[0][expensesList[index].name]}</td>
              <td>${locale[1][expensesList[index].name]}</td>
              <td>${formatter.format(expensesList[index].total)}</td>
              </tr>`
          }
        }
      }
      let temp_total = formatter.format((total + merchantsTotal) - (expensesTotal + rawTotal))
      gapTable += `
      <tr>
        <td><b>Gen. & Admin Exp. Total</b></td>
        <td><b>اجمالي المصروفات الادارية والعمومية</b></td>
        <td><b>${formatter.format(exp_total)}</b></td>
      </tr>
      <tr>
        <td><b>Net Profit</b></td>
        <td><b>صافي الربح</b></td>
        <td><b>${temp_total}</b></td>
      </tr>
      </table>`;
      
      mywindow.write(
        '<html dir="' +
          language["dir"] +
          '"><head><title>' +
          language["print"] +
          '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 15px">'
      );

      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );
      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"></div><br/>'
      );

      let tmp_total = 0
      let total_canceled = 0
    
    if(printType === 'profit'){
      mywindow.write(
        `<h4>${language['reports']}: ${language['profit-loss']} - ${fromDate} ~ ${toDate}</h4><br/>`
      );

      mywindow.write(gapTable);

    } else if (data && printType === 'revenue') {
      mywindow.write(
        `<h4>${language['reports']}: ${language['revenue-list']} ${language[opType]?language[opType]:''} - ${fromDate} ~ ${toDate}</h4><br/>`
      );
      
      mywindow.write(
        `<br/><table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>${language['date']}</td><td>Order Id</td><td>Items</td><td>${language["total"]}</td><td>${language["type"]}</td></tr>`
      );
      data.map((item) => {
        let temp_total = 0
        if(item.details){
          if(item.canceled){
            total_canceled += item.type === "delivery"
            ? formatter.format(
                parseFloat(item.total) +
                parseFloat(item.details.delivery.delivery_fees)
              )
            : parseFloat(item.total)
          } else {
            tmp_total += item.type === "delivery"
            ? formatter.format(
                parseFloat(item.total) +
                parseFloat(item.details.delivery.delivery_fees)
              )
            : parseFloat(item.total)
          }
          temp_total = item.type === "delivery"
            ? formatter.format(
                parseFloat(item.total) +
                parseFloat(item.details.delivery.delivery_fees)
              )
            : item.total;
        } else {
          if(item.canceled){
            total_canceled = parseFloat(item.total);
          } else {
            temp_total = parseFloat(item.total);
          }
          tmp_total += parseFloat(item.total)
        }
        
        mywindow.write(
          `<tr><td>${item.timestamp.toDate().toLocaleDateString()}</td>`+
          `<td>${item.orderId}</td><td>${item.items}</td>`+
          //`<td>${item.type === 'delivery' && item.delivered === true ? "Delivered" : "Undelivered"}</td>`+
          `<td>${temp_total}</td>`+
          `<td>${item.type === 'delivery'?'Website - Application':item.type === 'shop'?'Retail sales':'Delivery Applications'}</td></tr>`
        )
      });

      mywindow.write(`<tr>
                <th></th>
                <th></th>
                <th><b>${data && language["orders"] + ": " + data.length}</b></th>
                <th><b>${language["total"]}:</b> ${formatter.format(tmp_total)}</th>
                <th><b>${language['cancel']}:</b> ${language["total"]}: ${formatter.format(total_canceled)?formatter.format(total_canceled):0}</th>
            </tr>`);
      mywindow.write("</table>");
      //mywindow.write(document.getElementById('pid').innerHTML);

    } else if(expenses && printType === 'expenses' ){
      let temp_total = 0
      mywindow.write(
        `<h4>${language['reports']}: ${language['expenses-list']} ${language[opType]?language[opType]:''} - ${fromDate} ~ ${toDate}</h4><br/>`
      );

      mywindow.write(
        `<br/><table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>${language["type"]}</td><td>${language["amount"]}</td><td>${language["describtion"]}</td><td>${language["date"]}</td></tr>`
      )

      expenses.map((item) => {
        let odate = item.timestamp.toDate().toLocaleDateString()
        temp_total += item.amount
        mywindow.write(
          `<tr><td>${language[item.type]}</td><td>${formatter.format(item.amount)}</td><td>${item.desc}</td><td>${odate}</td></tr>`
        )
      })
      mywindow.write(`<tr>
                <th>${language["total"]}</th>
                <th><b>${formatter.format(temp_total)}</b></th>
            </tr>`);

      mywindow.write('</table>')
      
    } else if(merchantsLists && printType === 'merchants' ){
      let temp_total = 0
      mywindow.write(
        `<h4>${language['reports']}: ${language['merchants-list']} ${language[opType]?language[opType]:''} - ${fromDate} ~ ${toDate}</h4><br/>`
      );

      mywindow.write(
        `<br/><table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>${language["type"]}</td><td>${language["amount"]}</td><td>${language["describtion"]}</td><td>${language["date"]}</td></tr>`
      )

      merchantsLists.map((item) => {
        let odate = item.timestamp.toDate().toLocaleDateString()
        temp_total += item.amount
        mywindow.write(
          `<tr><td>${language[item.type]}</td><td>${formatter.format(item.amount)}</td><td>${item.desc}</td><td>${odate}</td></tr>`
        )
      })
      mywindow.write(`<tr>
                <th>${language["total"]}</th>
                <th><b>${formatter.format(temp_total)}</b></th>
            </tr>`);

      mywindow.write('</table>')
      
    }
    mywindow.write("</body></html>")

    mywindow.close();

  }, [data, expenses, printType]);

  const handlePrint = (orderType, pt) => {
    setPrintType(pt)
    setOType(orderType)
    let temp_data = null
    switch (orderType) {
      /*case 'other':
        temp_data = shadowExpenses.filter(item => item.canceled)
        setExpenses(temp_data)
      break;*/

      case 'delivery_apps':
        temp_data = []
        orderTypeList.map(otype => {
          temp_data = [...temp_data, ...shadowData.filter(item => item.type === otype.name && item.type !== 'shop' && !item.canceled)]
        })
        setData(temp_data)
      break;

      case 'shop':
        temp_data = shadowData.filter(item => item.type === 'shop')
        
        setData(temp_data)
      break;

      case 'return':
        temp_data = shadowData.filter(item => item.canceled)
        
        setData(temp_data)
      break;

      case 'expenses_return':
        temp_data = shadowExpenses.filter(item => item.canceled)
        
        setExpenses(temp_data)
      break;
      
      case 'website_app':
        temp_data = shadowData.filter(item => item.type === 'delivery' || item.type === 'pickup')
        setData(temp_data)
      break;

      case 'expenses':
        setExpenses(shadowExpenses)
      break;
        
      default:
        temp_data = shadowExpenses.filter(item => item.type === orderType && !item.canceled)
        setExpenses(temp_data)
      break;
    }
    let frm = document.getElementById("ipd").contentWindow;
    setTimeout(() => {
      frm.focus();
      frm.print();
    }, 300);
      
  };

  const handleDownload = () => {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", fromDate + '-' + toDate  + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

  }

  return (<>
      <div className="d-block mb-2 pb-2 border-bottom">
        <h2 className="title mr-3 ml-3">{language["sales"]}</h2>
        <DatePicker 
          range
          defaultValue={fromDate + ' ~ ' + toDate} 
          value={fromDate + ' ~ ' + toDate} 
          onChange={val => {
            try{
              setFromDate(val[0].year +"-" + ("0" + (val[0].month)).slice(-2) + "-" + ("0" + (val[0].day)).slice(-2))

              setToDate(val[1].year +"-" + ("0" + (val[1].month)).slice(-2) + "-" + ("0" + (val[1].day)).slice(-2))
            } catch(err) {
              //console.log(err);
            }
          }}
          placeholder={language['select-day']}
          //minDate={new Date()}
          className="green"
          style={{padding: 18}}
        />
          <button onClick={handleDisplay} className="m-2 btn btn-success" type="button">{language['show']}</button>
          {/* <button className="m-2 btn btn-secondary" type="button" onClick={() => handlePrint("all", "profit")}>{language['print']}</button>
          <button onClick={handleDownload} className="m-2 btn btn-info" type="button">{language['download']}</button> */}
          <div className="d-inline ">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{language['reports']}</button>
            <div className="dropdown-menu">
              <div role="button" className="dropdown-item" onClick={() => handlePrint("all", "profit")}>
                <i className="fa fa-print"></i> {language['print']}
              </div>
              <div role="button" className="dropdown-item" onClick={handleDownload}>
                <i className="fa fa-file"></i> {language['download']}
              </div>
            </div>
          </div>
      </div>
      {/* <div className="d-block mb-3 pb-3">
        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">{language["from"]}</span>
          </div>
          <input type="date" defaultValue={fromDate} className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(val) => setFromDate(val.target.value)} />
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">{language["to"]}</span>
          </div>
          <input type="date" defaultValue={toDate} className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(val) => setToDate(val.target.value)} />
          <div className="input-group-append">
            <button onClick={handleDisplay} className="btn btn-success" type="button">{language['show']}</button>
          </div>
          <div className="input-group-append">
            <button className="btn btn-secondary" type="button" onClick={() => handlePrint("all", "profit")}>{language['print']}</button>
          </div>
          <div className="input-group-append">
            <button onClick={handleDownload} className="btn btn-info" type="button">{language['download']}</button>
          </div>
        </div>
      </div> */}

      <h3 className="title">{language['revenue']}</h3>

      <div className="row">
        <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
          <div className="card-body text-center">
            <b>{language['retail_sales']}</b>
            <p>{retailTotal && formatter.format(retailTotal)}</p>
            {retailTotal !== 0 && <button
                onClick={() => handlePrint('shop', 'revenue')}
                type="button"
                className="align-middle btn btn-sm btn-success btn-rounded m-2"
              >
                <i className="fa fa-print"></i> {language["print"]}
            </button>}
          </div>
        </div>
        
        <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
          <div className="card-body text-center">
            <b>{language['delivery_apps']}</b>
            <p>{formatter.format(appsTotal)}</p>
            {appsTotal !== 0 && <button
                onClick={() => handlePrint('delivery_apps', 'revenue')}
                type="button"
                className="align-middle btn btn-sm btn-success btn-rounded"
              >
                <i className="fa fa-print"></i> {language["print"]}
            </button>}
          </div>
        </div>

        <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
          <div className="card-body text-center">
            <b>{language['website_app']}</b>
            <p>{onlineTotal}</p>
            {onlineTotal !== 0 && <button
                onClick={() => handlePrint('website_app', 'revenue')}
                type="button"
                className="align-middle btn btn-sm btn-success btn-rounded"
              >
                <i className="fa fa-print"></i> {language["print"]}
            </button>}
          </div>
        </div>
        
        <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
          <div className="card-body text-center">
            <b>{language['merchants']}</b>
            <p>{merchantsTotal !== 0?formatter.format(merchantsTotal):0}</p>
            {/* {merchantsTotal !== 0 && <button
                onClick={() => handlePrint('merchants', 'revenue')}
                type="button"
                className="align-middle btn btn-sm btn-success btn-rounded"
              >
                <i className="fa fa-print"></i> {language["print"]}
            </button>} */}
            {merchantsTotal != 0 && <Link className="align-middle btn btn-sm btn-success btn-rounded" to="/merchants/summary">{language['summary']}</Link>}
          </div>
        </div>
        
        <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
          <div className="card-body text-center">
            <b>{language['sale-return']}</b>
            <p>{canceledTotal}</p>
            {canceledTotal !== 0 && <button
                onClick={() => handlePrint('return', 'revenue')}
                type="button"
                className="align-middle btn btn-sm btn-success btn-rounded"
              >
                <i className="fa fa-print"></i> {language["print"]}
            </button>}
          </div>
        </div>

        <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
          <div className="card-body text-center">
            <b>{language['other_income']}</b>
            <p>{otherIncome}</p>
            {otherIncome !== 0 && <button
                onClick={() => handlePrint('other', 'expenses')}
                type="button"
                className="align-middle btn btn-sm btn-success btn-rounded"
              >
                <i className="fa fa-print"></i> {language["print"]}
            </button>}
          </div>
        </div>

        <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
          <div className="card-body text-center">
            <b>{language['total_revenues']}</b>
            <p>{total && formatter.format(total)}</p>
            {total !== 0 && <button
                onClick={() => handlePrint('all', 'revenue')}
                type="button"
                className="align-middle btn btn-sm btn-success btn-rounded"
              >
                <i className="fa fa-print"></i> {language["print"]}
            </button>}
          </div>
        </div>

        <hr className="m-3" />

        <h3 className="title">{language['expenses']}</h3>
        
        <div className="m-1 p-0 row">
          {expensesList && expensesList.map((item, index) => <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12" key={"exp-"+index}>
            <div className="card-body text-center">
              <b>{language[item.name]}</b>
              <p>{formatter.format(item.total)}</p>
              {parseFloat(item.total)  !== 0 && <button
                  onClick={() => handlePrint(item.name, 'expenses')}
                  type="button"
                  className="align-middle btn btn-sm btn-success btn-rounded"
                >
                  <i className="fa fa-print"></i> {language["print"]}
              </button>}
            </div>
          </div>)}
          <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
            <div className="card-body text-center">
              <b>{language['purchase_returns']}</b>
              <p>{otherIncome}</p>
              {otherIncome !== 0 && <button
                  onClick={() => handlePrint('expenses_return', 'expenses')}
                  type="button"
                  className="align-middle btn btn-sm btn-success btn-rounded"
                >
                  <i className="fa fa-print"></i> {language["print"]}
              </button>}
            </div>
          </div>
          <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
            <div className="card-body text-center">
              <b>{language['raw-materials']}</b>
              <p>{formatter.format(rawTotal)}</p>
            </div>
          </div>
          <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
            <div className="card-body text-center">
              <b>{language['total']} {language['expenses']}</b>
              <p>{formatter.format(expensesTotal + rawTotal)}</p>
              {expensesTotal !== 0 && <button
                  onClick={() => handlePrint('expenses', 'expenses')}
                  type="button"
                  className="align-middle btn btn-sm btn-success btn-rounded"
                >
                  <i className="fa fa-print"></i> {language["print"]}
              </button>}
            </div>
          </div>
        </div>

        <hr className="m-3" />

        <h3 className="title">{language['profit-loss']}</h3>

        <div className="m-1 p-0 row">
          <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
            <div className="card-body text-center">
              <b>{language['gross-profit']}</b>
              <p>{grossTotal}</p>
            </div>
          </div>
          <div className="card m-3 col-lg-3 col-md-3 col-ms-12 col-xs-12">
            <div className="card-body text-center">
              <b>{language['net-profit']}</b>
              <p className={`${(total - (expensesTotal + rawTotal)) > 0?'text-success':'text-danger'}`}>{data && formatter.format(total - (expensesTotal + rawTotal))}</p>
            </div>
          </div>
        </div>
      </div>
  </>);
}
