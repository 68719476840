import { useEffect, useState } from "react";
import { getDoc, getDocs, collection, query, updateDoc, doc } from 'firebase/firestore/lite';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Navigate, useParams } from "react-router-dom";

const EditProduct = ({database, user, setLoading, storage, navigate, language}) => {
    const [title, setTitle] = useState('');
    const [title_ar, setTitleAr] = useState('');
    const [price, setPrice] = useState('');
    const [price_old, setPriceOld] = useState('');
    const [describtion, setDescribtion] = useState('');
    const [describtion_ar, setDescribtionAr] = useState('');
    const [limit, setLimit] = useState(1000);
    const [barcode, setBarcode] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState('');
    const [enable, setEnable] = useState(false);
    const [sortPosition, setSort] = useState(0)
    const [list, setList] = useState(null);
    const { product } = useParams();
    const [bulk, setBulk] = useState(0);
    const [unit, setUnit] = useState('');
    const [bulkType, setBulkType] = useState('');

    const getData = () => {
        setLoading(true);
        getDoc(query(doc(database, "products", product)))
        .then(querySnapshot => {
            setTitle(querySnapshot.data().title)
            setTitleAr(querySnapshot.data().title_ar)
            setUnit(querySnapshot.data().title_ar)
            setPrice(querySnapshot.data().price)
            setPriceOld(querySnapshot.data().price_old?querySnapshot.data().price_old:'')
            setLimit(querySnapshot.data().limit?querySnapshot.data().limit:1000)
            setCategory(querySnapshot.data().category)
            setDescribtion(querySnapshot.data().describtion)
            setDescribtionAr(querySnapshot.data().describtion_ar)
            setEnable(querySnapshot.data().enable)
            setImage(querySnapshot.data().image?querySnapshot.data().image:null);
            setSort(querySnapshot.data().sort);
            setBarcode(querySnapshot.data().barcode)
            setBulk(querySnapshot.data().bulk)
            setBulkType(querySnapshot.data().bulkType)
        }).catch(err => console.log(err))
     
    }

    const editCategory = () => {
        updateDoc(doc(database, "products", product), {
            title: title,
            title_ar: title_ar,
            price: price,
            price_old: price_old,
            image: image,
            describtion: describtion,
            describtion_ar: describtion_ar,
            category: category,
            enable: enable,
            sort: sortPosition,
            limit: parseInt(limit),
            barcode: barcode,
            bulk: bulk
        })
        .then(res => {
            navigate('/products/list');
        })
    }

    const handleUpload = file => {
        const imageRef = ref(storage, 'images/' + image);
        uploadBytes(imageRef, file.target.files[0])//, metadata)
        .then((snapshot) => {
            // console.log('Uploaded', snapshot.totalBytes, 'bytes.');
            //console.log('File metadata:', snapshot.metadata);
            // Let's get a download URL for the file.
            getDownloadURL(snapshot.ref).then((url) => {
            //console.log('File available at', url);
            // ...
            });
        }).catch((error) => {
            console.error('Upload failed', error);
            // ...
        });
    }

    const getCategories = () => {
        let temp_arr = [];
        setLoading(true);
        getDocs(query(collection(database, "categories")))
        .then(querySnapshot => {
            querySnapshot.forEach(product => {
                temp_arr.push({
                    id: product.id,
                    title: product.data().title,
                    title_ar: product.data().title_ar,
                    slug: product.data().slug,
                })
            });
            setList(temp_arr);
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        setImage(Date.now() + '.jpg')
        getData()
        getCategories()
    }, []);

    return(<div className="grid-margin stretch-card">
        <div className="card">
        <div className="card-body">
        <h4 className="card-title">{language["edit"]}</h4>
        <p className="card-description">{language["products"]}</p>
        <form className="col-8 row">
                <label className="d-block mb-2" htmlFor="">{language['title']}</label> <br />
            <div className="input-group m-3">
                <input type="text" value={title_ar} onChange={val => setTitleAr(val.target.value)} className="form-control " placeholder={language["title"] + ' ' + language["arabic"]} />
            </div>
                <label className="d-block mb-2" htmlFor="">{language['title']} {language['english']}</label> <br />
            <div className="input-group m-3">
                <input type="text" value={title} onChange={val => setTitle(val.target.value)} className="form-control " placeholder={language["title"] + ' ' + language["english"]} />
            </div>
            <label className="d-block mb-2" htmlFor="">{language['unit']}</label> <br />
            <div className="input-group m-3">
                <input type="text" value={unit} onChange={val => setUnit(val.target.value)} className="form-control " placeholder={language["unit"]} />
            </div>
                <label className="d-block mb-2" htmlFor="">{language['price']}</label> <br />
            <div className="input-group m-3">
                <input type="text" value={price} onChange={val => setPrice(val.target.value)} className="form-control" placeholder={language["price"]} />
            </div>
                <label className="d-block mb-2" htmlFor="">{language['price_old']}</label> <br />
            <div className="input-group m-3">
                <input type="text" value={price_old} onChange={val => setPriceOld(val.target.value)} className="form-control" placeholder={language["price_old"]} />
            </div>
                <label htmlFor="">Bulk {language['price']}</label> <br />
            <div className="input-group m-3">
                <input type="text" value={bulk} onChange={val => setBulk(val.target.value)} className="form-control" placeholder="Bulk Price" />
            </div>
                <label htmlFor="">Bulk Type</label> <br />
            <div className="input-group m-3">
                <input type="text" value={bulkType} onChange={val => setBulkType(val.target.value)} className="form-control" placeholder="Bulk Type" />
            </div>
                <label className="d-block mb-2" htmlFor="">Barcode / SKU</label> <br />
            <div className="input-group m-3">
                <input type="text" value={barcode} onChange={val => setBarcode(val.target.value)} className="form-control" placeholder="Barcode" />
            </div>
                <label className="d-block mb-2" htmlFor="">{language['qty-limit']}</label> <br />
            <div className="input-group m-3">
                <input type="text" value={limit} onChange={val => setLimit(val.target.value)} className="form-control" placeholder={language["qty-limit"]} />
            </div>
                <label className="d-block mb-2" htmlFor="">{language['describtion']}</label> <br />
            <div className="input-group m-3">
                <textarea placeholder={language["describtion"] + ' ' + language["arabic"]} className="form-control" onChange={val => setDescribtionAr(val.target.value)} value={describtion_ar}></textarea>
            </div>
                <label className="d-block mb-2" htmlFor="">{language['describtion']} {language["english"]}</label> <br />
            <div className="input-group m-3">
                <textarea placeholder={language["describtion"] + ' ' + language["english"]} className="form-control" onChange={val => setDescribtion(val.target.value)} value={describtion}></textarea>
            </div>
                <label className="d-block mb-2" htmlFor="">{language['categories']}</label> <br />
            <div className="input-group m-3">
                <select name="category" value={category} className="form-control" onChange={val => setCategory(val.target.value)}>
                    {list && list.map(item => <option key={'categories'+item.id} value={item.slug} selected={category == item.slug}>{item.title}</option>)}
                </select>
            </div>
                <label className="d-block mb-2" htmlFor="">{language['availability']}</label><br/>
            <div className="input-group m-3">
                <select name="category" value={enable} className="form-control" onChange={val => setEnable(val.target.value)} defaultValue={enable}>
                    <option key={'enable1'} value={true}>{language["available"]}</option>
                    <option key={'enable2'} value={false}>{language["unavailable"]}</option>
                </select>
            </div>
            {image !== '' && <img src={'https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/images%2F'+image+'?alt=media'} className="col-7 m-4 p-3" />}
            <div className="input-group">
                <input type="file" accept="image/*" onChange={handleUpload} className="form-control" placeholder="Upload Picture" />
            </div>
        </form>
        
        <button className="btn m-3 btn-success" onClick={editCategory}>{language["update"]}</button>
        </div>
    </div>
    </div>)
}

export default EditProduct;