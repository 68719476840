import { deleteDoc, doc, getDoc, query, updateDoc } from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Bill({
  database,
  setLoading,
  language,
  navigate,
}) {
  const [data, setData] = useState(null);
  const [updated, setUpdated] = useState(Date.now());
  const { order_item } = useParams();
  const [orderPrintDate, setOrderPrintDate] = useState(null);

  useEffect(() => {
    let temp_arr = [];
    setLoading(true);
    getDoc(query(doc(database, "revenue", order_item)))
      .then((querySnapshot) => {
        setData(querySnapshot.data());
        setData((prevCart) => {
          return { ...prevCart, id: querySnapshot.id };
        });
      })
      .catch((err) => {
        /* */
      });
  }, []);

    useEffect(() => {
        let mywindow = document.getElementById("ipd").contentWindow.document;
    
        if (data) {    
          mywindow.open();
          mywindow.write(
            "<html><head><title>" +
              data.orderId +
              '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 5px">'
          );
          mywindow.write(
            '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
          );
          mywindow.write(
            '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" />'
          );
          mywindow.write(
            '<div style="clear: both;"></div><br/><br/><div style="display: inline-block; width: 50%"><b>Date: ' +
              (orderPrintDate
                ? orderPrintDate
                : data.timestamp.toDate().toLocaleDateString()) +
              "<br/>Invoice NO: " +
              data.orderId +
              '</b></div>'
          );
          mywindow.write(
            '<br/><br/><div style="display: inline-block; width: 100%"><b><div  style="display: inline-block; width: 5%; border: 1px solid #000; text-align: center;">No.</div><div  style="display: inline-block; width: 40%; border: 1px solid #000;">Description</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Unit Price</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Qty</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Total Price</div></b></div>'
          );
          data.items.map((item, i) => {
            mywindow.write(
              '<div style="display: inline-block; width: 100%"><div  style="display: inline-block; width: 5%; border: 1px solid #000; text-align: center;">' +
                (i + 1) +
                '</div><div  style="display: inline-block; width: 40%; border: 1px solid #000;">' +
                item.title +
                '</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
                item.qty +
                '</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
                item.price +
                '</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
                (item.price * item.qty).toFixed(3) +
                "</div></div>"
            );
          });
    
          mywindow.write(
            '<div style="display: inline-block; width: 100%"><b><div  style="display: inline-block; width: 5%; text-align: center;"></div><div  style="display: inline-block; width: 40%;"></div><div  style="display: inline-block; width: 8%; text-align: center;"></div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Subtotal</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">' +
              data.total +
              "</div></b></div>"
          );
    
          mywindow.write(
            '<div style="display: inline-block; width: 100%"><b><div  style="display: inline-block; width: 5%; text-align: center;"></div><div  style="display: inline-block; width: 40%;"></div><div  style="display: inline-block; width: 8%; text-align: center;"></div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">Discount</div><div  style="display: inline-block; width: 8%; border: 1px solid #000; text-align: center;">0.000</div></b></div>'
          );
    
          mywindow.write(
            '<div style="clear: both"><br/><br/><br/><h5>Payment Method: ' +
              data.payment +
              "</h5></div>"
          );
        
          mywindow.write("</div></p>");
    
          mywindow.write(
            '<div style="padding: 10%; "></div><div style="clear: both; display: block; text-align: center;margin: 0 auto; width: 100%;"><h5>Thank you for choosing Buthoor</h5></div>'
          );
    
          mywindow.write(document.getElementById("pid").innerHTML);
    
          mywindow.close();
        }
    }, [data, updated]);

  const handleCancel = (orderId) => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      updateDoc(doc(database, "revenue", orderId), {
        canceled: true,
      })
        .then((res) => {
          setUpdated(Date.now());
        })
        .catch((err) => {
          /* */
        });
    }
  };

  const printOrder = (printDate) => {
    setOrderPrintDate(printDate);
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  const handleDelete = () => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      deleteDoc(doc(database, "revenue", order_item)).then(() => {
        navigate('/finance/revenue');
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{data && data.orderId}</h4>
              <p className="card-description text-dark">
                {language["date"]}{" "}
                <code>
                  {data &&
                    data.timestamp.toDate().toDateString() +
                      " - " +
                      data.timestamp.toDate().toLocaleTimeString()}
                </code>
              </p>

              {data && (
                <div>
                  <b>{language["type"]}: </b> {data.type}
                  <br />
                  <b>{language["payment-method"]}: </b> {data.payment}
                  <br />
                  <b>{language["total"]}: </b> OMR {data.total}
                  <br />
                </div>
              )}

              <hr />
              {data && (
                <button
                  type="button"
                  onClick={printOrder}
                  className="btn btn-sm btn-info btn-rounded btn-fw m-3"
                >
                  <i className="mdi mdi-printer"></i> {language["print"]}
                </button>
              )}
              {data && !data.canceled && (
                <button
                  type="button"
                  onClick={() => handleCancel(data.id)}
                  className="btn btn-sm btn-danger btn-rounded btn-fw m-3"
                >
                  <i className="mdi mdi-close-circle"></i> {language["cancel"]}
                </button>
              )}
              {data && (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-sm btn-danger btn-rounded btn-fw m-3"
                >
                  <i className="mdi mdi-close"></i> {language["delete"]}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
          <table className="table table-hover">
              <thead>
                <tr>
                  <th>{language["title"]}</th>
                  <th>{language["qty"]}</th>
                  <th>{language["price"]}</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.items &&
                  data.items.map((item) => (
                    <tr key={"order-item-" + item.id}>
                      <td>
                        {language["language"] === "english"
                          ? item.title
                          : item.title_ar}
                      </td>
                      <td>{item.qty}</td>
                      <td>{item.price}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div id="pid" className="hidden"></div>
    </>
  );
}
