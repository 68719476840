import { useState } from "react";
import {
  collection,
  addDoc,
  getDocs,
  query,
  Timestamp,
} from "firebase/firestore/lite";
import { useEffect } from "react";

const NewLetter = ({
  database,
  user,
  setLoading,
  storage,
  navigate,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [msg, setMesg] = useState("");

  const addCity = () => {
    if (title === "" || message === "") {
      setMesg(language["required-error-msg"]);
    } else {
      addDoc(collection(database, "newsletter"), {
        date: Date.now(),
        title: title,
        msg: message,
        enable: true,
      }).then((res) => {
        navigate("/newsletter");
      });
    }
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["add"]}</h4>
          <p className="card-description">{language["newsletter"]}</p>
          <form className="col-8 row">
            <div className="input-group m-3">
              <input
                type="text"
                onChange={(val) => setTitle(val.target.value)}
                className="form-control"
                placeholder={language["title"]}
              />
            </div>
            <div className="input-group m-3">
              <textarea
                className="form-control"
                name="message"
                id="message"
                onChange={(val) => setMessage(val.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div className="p-3 text-danger">{msg}</div>
          </form>
          <button className="btn m-3 btn-success" onClick={addCity}>
            {language["add"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLetter;
