import { Link, useParams } from "react-router-dom";
import { collection, query, getDocs, where } from "firebase/firestore/lite";
import { useState, useEffect } from "react";

const Reports = ({ database, setLoading, user, language }) => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [canceledTotal, setCanceledTotal] = useState(0);
  const [month, setMonth] = useState("01");
  const [product, setProduct] = useState("all");
  const [area, setArea] = useState("all");
  const [list, setList] = useState(null);
  const [areas, setAreas] = useState(null);
  const [msg, setMesg] = useState("");
  const [csv, setCsv] = useState("");

  const getCategories = () => {
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "products")))
      .then((querySnapshot) => {
        querySnapshot.forEach((product) => {
          temp_arr.push({
            id: product.id,
            title: product.data().title,
            title_ar: product.data().title_ar,
          });
        });
        setList(temp_arr);
      })
      .catch((err) => console.log(err));
  };

  const getAreas = () => {
    let temp_arr = [];
    setLoading(true);
    getDocs(query(collection(database, "delivery")))
      .then((querySnapshot) => {
        querySnapshot.forEach((product) => {
          temp_arr.push({
            id: product.id,
            title: product.data().title,
            title_ar: product.data().title_ar,
          });
        });
        setAreas(temp_arr);
      })
      .catch((err) => console.log(err));
  };

  const display = () => {
    setTotal(0);
    let date = new Date();
    let temp_arr = [];
    let temp_total = 0;
    let temp_canceled_total = 0;
    let thisMonthUnixTime, thisnextUnixTime;

    let thismonth = null, nextMonth = null;

    let addProduct = false;
    let branch = window.localStorage.getItem("branch");
    let temp_csv = "Order Id,Type,delivered,Items \n";
    getDocs(
      query(collection(database, "orders"), where("branch", "==", branch))
    ) //, where("orderId", ">=", thisMonthUnixTime), where("orderId", "=<", thisnextUnixTime)))
      .then((querySnapshot) => {
        let dbMonth = parseInt(month);

        if (date.getMonth() === 11) {
          thismonth = new Date(date.getFullYear(), 0, 1);
        } else {
          thismonth = new Date(date.getFullYear(), dbMonth - 1, 1);
        }

        if (date.getMonth() === 11) {
          nextMonth = new Date(date.getFullYear(), dbMonth, 0, 1);
        } else {
          nextMonth = new Date(date.getFullYear(), dbMonth, 1);
        }

        thisMonthUnixTime = thismonth.getTime();
        thisnextUnixTime = nextMonth.getTime();

        querySnapshot.forEach((doc) => {
          if (
            doc.data().orderId >= thisMonthUnixTime &&
            doc.data().orderId < thisnextUnixTime
          ) {
            if (area !== "all") {
              if (doc.data().details.delivery.area === area) {
                addProduct = true;
              }
            }

            if (product !== "all") {
              if (!addProduct) {
                doc.data().items.forEach((element) => {
                  if (element.id === product) {
                    addProduct = true;
                  }
                });
              }
            }

            if (product === "all" && area === "all") {
              addProduct = true;
            }

            if (addProduct) {
              let tot =
                doc.data().type === "delivery"
                  ? (
                      parseFloat(doc.data().total) +
                      parseFloat(doc.data().details.delivery.delivery_fees)
                    ).toFixed(3)
                  : doc.data().total;

              temp_arr.push({
                id: doc.id,
                timestamp: doc.data().timestamp,
                type: doc.data().type,
                delivered: doc.data().delivered,
                canceled: doc.data().canceled,
                total: tot,
                items: doc.data().items.length,
                orderId: doc.data().orderId,
              });
              if (doc.data().canceled === false) {
                temp_total += parseFloat(tot);
              }
              if (doc.data().canceled === true) {
                temp_canceled_total += parseFloat(tot);
              }
              temp_csv +=
                doc.data().orderId +
                "," +
                doc.data().type +
                "," +
                (doc.data().delivered === true ? "delivered" : "undelivered") +
                "," +
                doc.data().items.length +
                "\n";
            }

            addProduct = false;
          }
        });
        setData(temp_arr);
        setCsv(temp_csv);
        setTotal(temp_total.toFixed(3));
        setCanceledTotal(temp_canceled_total.toFixed(3));
      })
      .catch((err) => console.log(err));
  };

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  useEffect(() => {
    getCategories();
    getAreas();
  }, []);

  function download(text) {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", Date.now() + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  useEffect(() => {
    if (data) {
      let mywindow = document.getElementById("ipd").contentWindow.document;

      mywindow.open();

      mywindow.write(
        '<html dir="' +
          language["dir"] +
          '"><head><title>' +
          language["products"] +
          '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 15px">'
      );

      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );

      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"><h1>' +
          language["orders-list"] +
          "</h1></div>"
      );

      mywindow.write(
        `<br/><table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>Order Id</td><td>Items</td><td>${language["total"]}</td><td>${language["delivered"]}</td></tr>`
      );

      data.map((item) => {
        mywindow.write(
          `<tr><td>${item.orderId}</td><td>${item.items}</td><td>${
            item.total
          }</td><td>${
            item.delivered === true ? "Delivered" : "Undelivered"
          }</td></tr>`
        );
      });

      mywindow.write(`<tr>
                <th><b>${language["total"]}</b></th>
                <th>OMR ${total}</th>
                <th><b> ${
                  language["canceled-orders"]
                }</b> OMR ${canceledTotal}</th>
                <th><b>${
                  data && language["orders"] + ": " + data.length
                }</b></th>
            </tr>`);
      mywindow.write("</table>");
      //mywindow.write(document.getElementById('pid').innerHTML);

      mywindow.close();
    }
  }, [data]);

  const printReport = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{(new Date().getFullYear())} {language["reports"]} - {language['monthly-earnings']}</h4>
          <div className="col-12 row">
            <div className="input-group m-3">
              <div className="input-group m-3">
                <select
                  name="category"
                  className="form-control"
                  onChange={(val) => setMonth(val.target.value)}
                >
                  <option value="all">{language["select-month"]}</option>
                  {months.map((item, i) => (
                    <option key={"months" + i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <select
                  name="category"
                  className="form-control"
                  onChange={(val) => setProduct(val.target.value)}
                >
                  <option value="all">{language["all-product"]}</option>
                  {list &&
                    list.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                </select>
                <select
                  name="category"
                  className="form-control"
                  onChange={(val) => setArea(val.target.value)}
                >
                  <option value="all">{language["all-areas"]}</option>
                  {areas &&
                    areas.map((item) => (
                      <option key={item.id} value={item.title}>
                        {item.title}
                      </option>
                    ))}
                </select>

                <button className="btn btn-sm btn-success" onClick={display}>
                  {language["show"]}
                </button>

                {data && (
                  <button
                    onClick={download}
                    type="button"
                    className="btn btn-sm btn-success"
                  >
                    <i className="mdi mdi-file-excel"></i>{" "}
                    {language["download"]}
                  </button>
                )}

                {data && (
                  <button
                    onClick={printReport}
                    type="button"
                    className="btn btn-sm btn-success"
                  >
                    <i className="fa fa-print"></i> {language["print"]}
                  </button>
                )}
              </div>
            </div>
          </div>

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["orderid"]}</th>
                <th>{language["total"]}</th>
                <th>{language["type"]}</th>
                <th>{language["status"]}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr>
                    <td>{item.orderId}</td>
                    <td>{item.total}</td>
                    <td>{item.type}</td>
                    <td className="col">
                      {!item.canceled && (
                        <label
                          className={
                            item.delivered
                              ? "badge badge-success"
                              : "badge badge-danger"
                          }
                        >
                          {item.delivered ? "Delivered" : "Undelivered"}
                        </label>
                      )}{" "}
                      {item.canceled && (
                        <label className="badge badge-danger">
                          {language["cancel"]}
                        </label>
                      )}
                    </td>
                    <td className="col">
                      <Link
                        className="btn btn-success"
                        to={"/order/" + item.id}
                      >
                        <i className="mdi mdi-eye"></i> {language["view"]}
                      </Link>
                    </td>
                  </tr>
                ))}
              <tr>
                <th>
                  <b>{language["total"]}</b>
                </th>
                <th>OMR {total}</th>
                <th>
                  <b>{language["canceled-orders"]}</b>
                </th>
                <th>OMR {canceledTotal}</th>
                <th>
                  <b>{data && language["orders"] + ": " + data.length}</b>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Reports;
