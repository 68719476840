import {
  collection,
  query,
  getDocs,
  where,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
} from "firebase/firestore/lite";
import { useState, useEffect, forwardRef } from "react";
import { Link, useParams } from "react-router-dom";

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
  closestCorners,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove,
  rectSwappingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const ProductsList = ({ database, setLoading, user, language, usersData }) => {
  const [data, setData] = useState(null);
  const [shadowData, setShadowData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [csv, setCsv] = useState("");
  const [category, setCategory] = useState("");
  const [qtyCheck, setQtyCheck] = useState(0)
  const [categories, setCategories] = useState(null);
  const { list } = useParams();
  const [branch, setBranch] = useState();

  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    setBranch(window.localStorage.getItem("branch"));
  }, []);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragStart = (event) => {
    const { active } = event;

    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      /*setData(() => {
        const oldIndex = data.indexOf(active.id);
        const newIndex = data.indexOf(over.id);
        //handleSort(data[oldIndex].key, newIndex);
        //handleSort(data[newIndex].key, oldIndex);
        return arrayMove(data, oldIndex, newIndex);
      });*/

      let temp_arr = [];
      const oldIndex = data.indexOf(active.id);
      const newIndex = data.indexOf(over.id);
      temp_arr = arrayMove(data, oldIndex, newIndex);

      setData(temp_arr);

      let num = 0;
      temp_arr.forEach((doc) => {
        handleSort(doc.key, num);
        num++;
      });
    }

    setActiveId(null);
  };
  const getOrders = () => {
    let temp_arr = [];
    setLoading(true);
    let q = null;
    let cats = getDocs(
      query(collection(database, "categories"), orderBy("sort"))
    );

    if (list === "list") {
      q = getDocs(query(collection(database, "products"), orderBy("sort")));
    } else {
      q = getDocs(
        query(
          collection(database, "products"),
          where("category", "==", "offers")
        )
      );
    }
    let temp_csv = "";
    let avilable_status = 0

    q.then((querySnapshot) => {
      temp_csv = 'title, id, price, condition, availability, channel, feed_label description,	google_product_category, image_link, link \n'
      //temp_csv = "id, title, price, category, image, url, availability, Qty\n";
      querySnapshot.forEach((snap) => {
        temp_arr.push({
          key: snap.id,
          title: snap.data().title,
          title_ar: snap.data().title_ar,
          //enable: snap.data().enable,
          price: snap.data().price,
          sort: snap.data().sort,
          category: snap.data().category,
          availability: snap.data().availability,
          qty: snap.data().qty,
        });

        avilable_status = snap.data().availability[window.localStorage.getItem('branch')].enable === true?avilable_status + 1:avilable_status;

        temp_csv +=
          snap.data().title +
          "," +
          snap.id +
          ", OMR " +
          parseFloat(snap.data().price).toFixed(3) +
          "," +
          ', new, in_stock, online, OM, ' +
          snap.data().category +
          ", 430, " + 
          'https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/images%2F'+snap.data().image+'?alt=media' +
          ",https://www.buthoormart.com/products/" +
          snap.id +
          "\n";
        // + ',' + (snap.data().availability[window.localStorage.getItem('branch')].enable === false?'out_stock':'in_stock') + '\n'
      });
      setCsv(temp_csv);
      setData(temp_arr);
      setShadowData(temp_arr);
      avilable_status = (100 * avilable_status) / temp_arr.length
      setQtyCheck(avilable_status)

      cats.then((docs) => {
        let c_temp_arr = [];
        docs.forEach((snap) => {
          c_temp_arr.push({
            key: snap.id,
            title: snap.data().title,
            title_ar: snap.data().title_ar,
            enable: snap.data().enable,
            sort: snap.data().sort,
            slug: snap.data().slug,
          });
          setCategories(c_temp_arr);
        });
      });
    }).catch((err) => console.log(err));
  };

  const handleCategory = (cat) => {
    let temp_csv = "";
    let temp_arr = shadowData.filter((item) => item.category === cat);

    if (cat === "all" || cat === "") {
      temp_csv = 'title, id, price, condition, availability, channel, feed_label description,	google_product_category, image_link, link \n'
      shadowData.forEach((snap) => {
        temp_csv +=
          snap.title +
          "," +
          snap.id +
          ", OMR " +
          parseFloat(snap.price).toFixed(3) +
          "," +
          ', new, in_stock, online, OM, ' +
          snap.category +
          ", 430, " + 
          'https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/images%2F'+snap.image+'?alt=media' +
          ",https://www.buthoormart.com/products/" +
          snap.id +
          "\n";
      });
      setCsv(temp_csv);
      setData(shadowData);
    } else {
      setData(temp_arr);
      temp_csv = 'title, id, price, condition, availability, channel, feed_label description,	google_product_category, image_link, link \n'
      temp_arr.forEach((snap) => {
        temp_csv +=
          snap.title +
          "," +
          snap.id +
          ", OMR " +
          parseFloat(snap.price).toFixed(3) +
          "," +
          ', new, in_stock, online, OM, ' +
          snap.category +
          ", 430, " + 
          'https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/images%2F'+snap.image+'?alt=media' +
          ",https://www.buthoormart.com/products/" +
          snap.id +
          "\n";

      });
      setCsv(temp_csv);
    }
  };

  function download(filename, text) {
    let d = Date.now();
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute("download", language["products-list"] + ".csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const handleDelete = (item) => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      deleteDoc(doc(database, "products", item)).then(() => {
        setUpdate(Date.now());
      });
    }
  };

  const handleSort = (dockey, index) => {
    updateDoc(doc(database, "products", dockey), {
      sort: index,
    })
      .then((res) => console.log(res))
      .catch((res) => console.log(res));
  };

  const handleAvailable = (item, val) => {
    //val = val === 'false'?'true':'false'

    updateDoc(doc(database, "products", item), {
      [`availability.${branch}.enable`]: !val,
    })
      .then((res) => {
        console.log(res);
        setUpdate(Date.now());
      })
      .catch((res) => console.log(res));
  };

  const Widget = forwardRef(({ id, ...props }, ref) => {
    const { title, price, items, title_ar, availability, qty, key } = id;
    return (
      <tr {...props} ref={ref} key={key}>
        <td>{language["language"] === "arabic" ? title_ar : title}</td>
        <td>{price}</td>
        {/* <td>{items}</td> */}

        {/* <td>
            <label className={enable === "false"?"badge badge-danger":"badge badge-success"}>
              {enable === "false"?language['unavailable']:language['available']}
            </label>
          </td> */}

        <td className="text-center">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              defaultChecked={
                availability[window.localStorage.getItem("branch")]?.enable
              }
              onClick={(e) =>
                handleAvailable(
                  key,
                  availability[window.localStorage.getItem("branch")]?.enable
                )
              }
            />
          </div>
        </td>

        <td>{qty?qty:0}</td>

        {usersData && usersData.userType === "admin" && (
          <td>
            <Link className="btn btn-success m-2" to={"/editproducts/" + key}>
              <i className="mdi mdi-pen"></i> {language["edit"]}
            </Link>
            <Link className="btn btn-info m-2" to={"/inventory/" + key}>
              <i className="mdi mdi-package-check"></i> {language["inventory"]}
            </Link>
            <button
              className="btn btn-danger m-2"
              onClick={() => handleDelete(key)}
            >
              <i className="mdi mdi-delete"></i> {language["delete"]}
            </button>
          </td>
        )}
      </tr>
    );
  });

  const SortableWidget = (props) => {
    const { id, currencypairs, tradercurrencypair } = props;
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <Widget
        ref={setNodeRef}
        id={id}
        style={style}
        currencypairs={currencypairs}
        tradercurrencypair={tradercurrencypair}
        {...attributes}
        {...listeners}
      />
    );
  };

  useEffect(() => {
    getOrders();
  }, [update]);

  /* useEffect(() => {
        if(data){
            let num = 0;
            data.forEach(doc => {
                handleSort(doc.key, num)
                num++;
            })
        }  
    }, data)*/

  useEffect(() => {
    if (data) {
      let mywindow = document.getElementById("ipd").contentWindow.document;

      mywindow.open();

      mywindow.write(
        '<html dir="' +
          language["dir"] +
          '"><head><title>' +
          language["products"] +
          '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 15px">'
      );

      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );

      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" /><div style="clear: both; margin: 4px"><h1>' +
          language["products-list"] +
          "</h1></div>"
      );

      mywindow.write(
        `<br/><table style="width: 100%;" border="1"><tr style="font-weight: bold;"><td>${language["title"]}</td><td>${language["title"]}</td><td>${language["price"]}</td><td>${language["category"]}</td><td>${language["available"]}</td></tr>`
      );

      data.map((item) => {
        mywindow.write(
          `<tr><td>${item.title}</td><td>${item.title_ar}</td><td>${
            item.price
          }</td><td>${item.category.replace("-", " ")}</td><td>${
            item.enable === true ? "In of stock" : "Out stock"
          }</td></tr>`
        );
      });

      mywindow.write("</table>");
      //mywindow.write(document.getElementById('pid').innerHTML);

      mywindow.close();
    }
  }, [data]);

  const printProducts = () => {
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  return (
    <div className="grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{language["products"]}</h4>
          {usersData && usersData.userType === "admin" && (
            <Link
              to="/newproduct"
              type="button"
              className="btn btn-sm btn-success btn-rounded btn-fw"
            >
              <i className="mdi mdi-plus"></i> {language["new"]}
            </Link>
          )}

          <button
            onClick={printProducts}
            type="button"
            className="btn btn-success btn-rounded btn-sm m-2 btn-fw"
          >
            <i className="fa fa-print"></i> {language["print"]}
          </button>

          {usersData && usersData.userType === "admin" && (
            <button
              onClick={download}
              type="button"
              className="btn btn-success btn-sm btn-rounded m-2 btn-fw"
            >
              <i className="mdi mdi-file-excel"></i> {language["download"]}
            </button>
          )}

          {categories && categories.length > 0 && (
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-inline-block">
              <input
                list="cats"
                type="text"
                onChange={(val) => handleCategory(val.target.value)}
                className="form-control"
                placeholder={language["categories"]}
              />{" "}
              <datalist id="cats" name="cats">
                {categories.map((item) => (
                  <option key={item.id} value={item.slug}>
                    {language["dir"] === "ltr" ? item.title : item.title_ar}
                  </option>
                ))}
              </datalist>
            </div>
          )}

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{language["title"]}</th>
                <th>{language["price"]}</th>
                <th>{language["status"]}</th>
                <th>{language["qty"]} </th>
                <th>{language['available']}: {parseInt(qtyCheck)}%</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <DndContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragCancel={() => setActiveId(null)}
              >
                {data && (
                  <SortableContext items={data} strategy={rectSwappingStrategy}>
                    {data.map((value, index) => (
                      <SortableWidget
                        key={"product-" + index}
                        id={value}
                        index={index}
                      />
                    ))}
                  </SortableContext>
                )}
                <DragOverlay>
                  {activeId ? <Widget id={activeId} /> : null}
                </DragOverlay>
              </DndContext>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
