import {
  deleteDoc,
  doc,
  getDoc,
  query,
  updateDoc,
} from "firebase/firestore/lite";
import { ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Expense({ database, setLoading, language, storage }) {
  const [data, setData] = useState(null);
  const [updated, setUpdated] = useState(Date.now());
  const { expense_item } = useParams();
  const [orderPrintDate, setOrderPrintDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [dMsg, setDMsg] = useState(null);

  const [selectedImages, setSelectedImages] = useState(null);
  const [imagesNames, setImageNames] = useState([]);
  const [uploadImages, setUploadedImages] = useState(null);
  const [displayedImg, setDisplayedImags] = useState(null)
  const [lock, setLock] = useState(null)

  const navigate = useNavigate();

  useEffect(() => {
    let temp_arr = [];
    setLoading(true);
    getDoc(query(doc(database, "expenses", expense_item)))
      .then((querySnapshot) => {
        setData(querySnapshot.data());
        if(querySnapshot.data().images){
          setDisplayedImags(querySnapshot.data().images)
        } else {
          setDisplayedImags([])
        }
        setData((prevCart) => {
          return { ...prevCart, id: querySnapshot.id };
        });
      })
      .catch((err) => {
        /* */
      });
  }, [updated]);

  useEffect(() => {
    let mywindow = document.getElementById("ipd").contentWindow.document;

    if (data) {
      let img =
        '<img src="https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/data%2F' +
        data.image +
        '?alt=media" width="500px" />';

      if (data.images) {
        img = "";
        for (let index = 0; index < data.images.length; index++) {
          img +=
            '<img src="https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/data%2F' +
            data.images[index] +
            '?alt=media" width="500px" /><br/>';
        }
      }
      mywindow.open();
      mywindow.write(
        "<html><head><title>" +
          data.orderId +
          '</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><link rel="stylesheet" href="../../assets/css/style.css"><style>@media print { .footer,#non-printable {display: none !important;} #printable {display: block;}}</style></head><body tyle="color: #000; padding: 5px">'
      );
      mywindow.write(
        '<img src="../../assets/print/buthoor_print.jpg" style="float: left" />'
      );
      mywindow.write(
        '<img src="../../assets/images/logo-mini.png" style="width: 100px; float: right" />'
      );
      mywindow.write(
        '<div style="clear: both;"></div><br/><br/><div style="display: inline-block; width: 50%"><b>Date: ' +
          (orderPrintDate
            ? orderPrintDate
            : data.timestamp.toDate().toLocaleDateString()) +
          "<br/>Expense No: " +
          data.orderId +
          "<br/>" +
          language["title"] +
          ": " +
          language[data.type] +
          "<br/>" +
          language["amount"] +
          ": " +
          data.amount +
          "<br/> " +
          language["describtion"] +
          ": " +
          data.desc +
          "<br/> " +
          language["date"] +
          ": " +
          data.date +
          "<br/>" +
          img +
          "</b></div>"
      );

      mywindow.write("</div></p>");

      mywindow.write(
        '<div style="padding: 10%; "></div><div style="clear: both; display: block; text-align: center;margin: 0 auto; width: 100%;"><h5>Thank you for choosing Buthoor</h5></div>'
      );

      mywindow.write(document.getElementById("pid").innerHTML);

      mywindow.close();
    }
  }, [data, updated]);

  const handleCancel = () => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      setShowModal(false);
      updateDoc(doc(database, "expenses", expense_item), {
        canceled: true,
        cancelReason: cancelReason,
      })
        .then((res) => {
          setUpdated(Date.now());
        })
        .catch((err) => {
          /* */
        });
    }
  };

  const printOrder = (printDate) => {
    setOrderPrintDate(printDate);
    let frm = document.getElementById("ipd").contentWindow;
    frm.focus();
    frm.print();
  };

  const handleDelete = () => {
    let r = window.confirm(language["confirm"]);
    if (r) {
      deleteDoc(doc(database, "expenses", expense_item)).then(() => {
        navigate("/finance/expenses");
      });
    }
  };

  const handleUpload = (file) => {
    const imagesList = [];
    const imagesNamesList = [];

    for (let index = 0; index < file.target.files.length; index++) {
      let img = null;
      try {
        img = URL.createObjectURL(file.target.files[index]);
        let imgName =
          Date.now().toString(36) +
          Math.random().toString(36).substring(2) +
          ".jpg";
        imagesList.push(img);
        imagesNamesList.push(imgName);
      } catch (error) {
        img = null;
      }
    }
    setSelectedImages(imagesList)
    setImageNames(imagesNamesList)
    setUploadedImages(file.target.files)
  };

  const handleUploadImages = async () => {
    const tmp = displayedImg;
    const enable = true
    for (let index = 0; index < uploadImages.length; index++) {
      const img = uploadImages[index];        
      const imageRef = ref(storage, "data/" + imagesNames[index]);
      await uploadBytes(imageRef, img) //, metadata)
      .then((snapshot) => {
        tmp.push(imagesNames[index])
      })
      .catch((error) => {
        const enable = false
        setDMsg(language["error-msg"]);
        // ...
      });
    }

    if(enable){
      setLock(tmp)
    }
  }

  const deleteImg = (img) => {
    const r = window.confirm(language['confirm']);

    if(r && displayedImg){
      const tmp = displayedImg;
      tmp.splice(tmp.indexOf(img), 1)
      setLock(tmp)
    }
  }

  useEffect(() => {
    if(lock != null){
      updateDoc(doc(database, "expenses", expense_item), {
        images: lock
      })
      .then(res => {
        setDisplayedImags(lock)
        setLock(null)
      })
      .catch(err => {
        setLock(null)
        //
      })
    }
  }, [lock])
      

  return (
    <>
      <div
        className={`modal ${showModal ? "d-block" : ""}`}
        style={{ backgroundColor: "#000000c7" }}
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {language["return"]}
              </h5>
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body row">
              <div className="col-lg-4 col-md-4 col-sm-12 p-2">
                <label htmlFor="cancel-reason">
                  {language["cancel-reason"]}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 row">
                <select
                  name="cancel-reason"
                  id="cancel-reason"
                  className="form-control col-12"
                  onChange={(val) => setCancelReason(val.target.value)}
                >
                  <option value="other">{language["other"]}</option>
                  <option value="items-damage">
                    {language["items-damage"]}
                  </option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {language["close"]}
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                {language["return"]}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">{data && data.orderId}</h4>
              <p className="card-description text-dark">
                {language["date"]}{" "}
                <code>
                  {data &&
                    data.timestamp.toDate().toDateString() +
                      " - " +
                      data.timestamp.toDate().toLocaleTimeString()}
                </code>
              </p>

              {data && (
                <div>
                  <b>{language["title"]}: </b> {language[data.type]}
                  <br />
                  <b>{language["describtion"]}: </b> {data.desc}
                  <br />
                  <b>{language["amount"]}: </b> OMR {data.amount}
                  <br />
                  <b>{language["date"]}: </b> {data.date}
                  <br />
                  {data.canceled === true && (
                    <div className="p-3">
                      <b>{language["status"]}:</b> {language["return"]}
                      <br />
                      <b>{language["cancel-reason"]}:</b>{" "}
                      {data.cancelReason ? language[data.cancelReason] : ""}
                    </div>
                  )}
                  <div className="row">
                    {displayedImg &&
                      displayedImg.map(img => (
                        <div className="col-6" key={img}>
                          <button onClick={() => deleteImg(img)} className="close bg-danger p-1 rounded-circle"><i className="fa fa-times"></i></button>
                          <img
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/data%2F" +
                            img +
                            "?alt=media"
                          }
                          className="col-12"
                        /></div>
                      ))}
                    {data.image && data.image != "" && (
                      <img
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/bothoor-5f147.appspot.com/o/data%2F" +
                          data.image +
                          "?alt=media"
                        }
                        className="col-7 p-3"
                      />
                    )}
                  </div>
                </div>
              )}
              <hr />

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label htmlFor="receipt">{language['add']}: {language["receipt-photo"]}</label>
                  <input
                    type="file"
                    name="receipt"
                    id="receipt"
                    accept="image/*"
                    onChange={handleUpload}
                    className="form-control"
                    placeholder="Upload Picture"
                    multiple
                  />
                  <p>{dMsg}</p>
                  
                <button
                    type="button"
                    onClick={handleUploadImages}
                    className="btn btn-sm btn-success btn-rounded btn-fw m-3"
                  >
                    <i className="mdi mdi-upload"></i> {language["upload"]}
                  </button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                  {selectedImages &&
                    selectedImages.map((img, index) => (
                      <img
                        key={"img-891721-" + index}
                        src={img}
                        className="thumbnail col-2 m-1"
                      />
                    ))}
                </div>
              </div>

              <hr />
              {data && (
                <button
                  type="button"
                  onClick={printOrder}
                  className="btn btn-sm btn-info btn-rounded btn-fw m-3"
                >
                  <i className="mdi mdi-printer"></i> {language["print"]}
                </button>
              )}
              {data && !data.canceled && (
                <button
                  type="button"
                  onClick={() => setShowModal(true)}
                  className="btn btn-sm btn-danger btn-rounded btn-fw m-3"
                >
                  <i className="mdi mdi-close-circle"></i> {language["return"]}
                </button>
              )}
              {data && (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-sm btn-danger btn-rounded btn-fw m-3"
                >
                  <i className="mdi mdi-close"></i> {language["delete"]}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="pid" className="hidden"></div>
    </>
  );
}
